// 命名：模块名+功能
export const SET_APP_ID = 'SET_APP_ID'
export const APP_LIST = 'APP_LIST'
export const SET_VIEW = 'SET_VIEW'
export const GET_VIEW_BY_PAGE = 'GET_VIEW_BY_PAGE'

export const SET_PAGE = 'SET_PAGE'
export const LIST_VIEW = 'LIST_VIEW'
export const REMOVE_VIEW = 'REMOVE_VIEW'
export const REMOVE_COL = 'REMOVE_COL'
export const REMOVE_PARAM = 'REMOVE_PARAM'
export const ADD_PARAM = 'ADD_PARAM'

export const ADD_COL = 'ADD_COL'
export const UPD_COL = 'UPD_COL'
export const ADD_VIEW = 'ADD_VIEW'
export const UPD_VIEW = 'UPD_VIEW'


export const ADD_BUTTON = 'ADD_BUTTON'
export const UPD_BUTTON = 'UPD_BUTTON'
export const DEL_BUTTON = 'DEL_BUTTON'
export const SET_BUTTON = 'SET_BUTTON'
export const LIST_BUTTON = 'LIST_BUTTON'


export const LIST_ACTION = 'LIST_ACTION'
export const REMOVE_ACTION = 'REMOVE_ACTION'
export const ADD_ACTION = 'ADD_ACTION'
export const UPD_ACTION = 'UPD_ACTION'
export const SET_ACTION = 'SET_ACTION'
export const LIST_APP = 'LIST_APP'



