<template>
  <div id='app'>
    <router-view></router-view>
    <dialogAppLogin></dialogAppLogin>
  </div>
</template>

<script>
import dialogAppLogin from "@/pages/login/dialog.appLogin.vue";

export default {
  name: 'app',
  components: {dialogAppLogin}
};
</script>

<style lang="less">
@import '~@/assets/variable.less';

#app {
  min-width: 1050px;
}

body {
  overflow-y: auto !important;
}
</style>

