import * as _ from 'lodash'

export default {


  /**
   * 合并
   * @param {*} over 是否覆盖
   */
  merge(as, bs, key) {
     console.log("left:" + JSON.stringify(as))
     console.log("right:" + JSON.stringify(bs))

    let cs = _.filter(bs, (c) => {
      if (c.add_type) {
        return c.add_type == 2;
      }
      return false;
    });


    if (!as) {
      as = []
    }
    if (!bs) {
      bs = []
    }

    let r = [];
    let bm = {}
    for (let i = 0; i < bs.length; i++) {
      bm[bs[i][key]] = bs[i]
    }

    for (var i = 0; i < as.length; i++) {
      let item = as[i];
      let val = item[key];
      if (bm[val]) {
        console.log("bm:", val, bm[val]['sort_num']);
        r.push(bm[val]);
      } else {
        r.push(item)
      }
    }

    //自定义添加的列不丢
    for (let i = 0; i < cs.length; i++) {
      let item = cs[i];
      r.push(item)
    }


    console.log("r:" + JSON.stringify(r))
    return r;
  },

  /**
   * 合并
   * @param {*} over 是否覆盖
   */
  union(as, bs, key, over) {
    let am = _.sortBy(as, key);
    let bm = _.sortBy(bs, key);
    let r = [];
    for (let key in am) {
      if (bm[key] && over) {
        r.push(bm[key]);
      } else {
        r.push(am[key])
      }
      delete bm[key];
    }
    for (let key in bm) {
      r.push(bm[key])
    }
    return r;
  }
}
