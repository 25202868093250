import * as _ from "lodash";
import moment from "moment";


export default {
    setCookieToken() {
        var d = new Date();
        d.setTime(d.getTime() + (30 * 1000));
        var expires = "expires=" + d.toUTCString();
        let token = window.localStorage.getItem("token");
        document.cookie = "x-access-token=" + token + "; " + expires;
    },
    execCallback2(view, response, params, callback) {
        if (view.call_back_config) {
            console.log("call_back_config:", view.call_back_config);
            try {
                let cbFun = new Function("_self", "p", "v", "callback", view.call_back_config);
                response = cbFun(this.getRoot(), params, response.list, callback);
                callback(response)
            } catch (e) {
                console.error(e);
            }
        } else {
            callback({...response, ok: true})
        }

    },
    execCallBack(view, response, params) {
        console.log("cp response 1 :", response);
        if (view.call_back_config) {
            console.log("call_back_config:", view.call_back_config);
            try {
                let cbFun = new Function("_self", "p", "v", view.call_back_config);
                response = cbFun(this.getRoot(), params, response);
            } catch (e) {
                console.error(e);
            }
        }

        console.log("cp response 2 :", response);
        return response;
    },

    execBefore(view, params) {

        if (view.before_config) {
            console.log("before_config:", view.before_config);
            try {
                let cbFun = new Function("_self", "v", "moment", view.before_config);
                params = cbFun(this.getRoot(), params, moment);
            } catch (e) {
                console.error(e);
            }
        }

        return params;
    },


    showFail(msg) {
        this.exec('showFail', msg)
    },
    showOk(msg) {
        this.exec('showOk', msg)
    },


    loadCss(code) {
        //console.log("loadCss:", code);
        var style = document.createElement('style');
        style.type = 'text/css';
        style.rel = 'stylesheet';
        style.appendChild(document.createTextNode(code));
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(style);
    },


    condBtnFun(btn) {
        let condFun = new Function("v", "return true;");

        if (btn && btn.cond) {
            console.log("cond:", btn.cond);
            try {
                condFun = new Function("v", btn.cond);
            } catch (e) {
                console.log("condBtnFun err:", e);
            }
        }

        return condFun;
    },

    condBtn(btn, v) {
        if (btn && btn.cond) {

            console.log("btn.cond:", btn.cond, "  \n v:", JSON.stringify(v));


            try {
                let condFun = new Function("v", btn.cond);
                let r = condFun(v);

                console.log("condBtn return :", r);
                return r;
            } catch (e) {
                console.log("condBtn err:", e);
            }
        }
        return true;
    },

    getRoot() {
        if (!this.root) {
            this.root = {};
        }
        if (!this.root.is_root) {
            let root = this.$parent;
            // eslint-disable-next-line no-constant-condition
            while (true) {
                if (root.$parent == 'undefined') {
                    break;
                }
                root = root.$parent

                if (root.is_root) {
                    break;
                }
            }
            this.root = root;
        }
        return this.root;
    },

    //btn调用父组件的方法
    appButtonClick(opt) {

        console.log("appButtonClick:", JSON.stringify(opt.btn), JSON.stringify(this.params));

        if (!opt.btn || !opt.btn.id) {
            return false;
        }


        let v = opt.v ? opt.v : {};
        let p = this.params ? this.params : {};

        if (opt.btn.source_type == "col") {
            this.doColumnButton(opt.btn, v, p);
        } else if (opt.btn.source_type == "view") {
            this.doTableButton(opt.btn, v, p);
        } else if (opt.btn.source_type == "form") {
            this.doFormButton(opt.btn, v, p);
        } else if (opt.btn.source_type == "param") {

            //获取当前的参数
            let search = Object.assign({}, this.search, this.extSearch);
            search = Object.assign({}, search, this.hideSearch);
            this.doColumnButton(opt.btn, v, search);
        }
    },

    doFormButton(r, v) {
        if (this.btnLoading) {
            return
        }
        this.btnLoading = true
        setTimeout(() => {
            this.btnLoading = false
        }, 400)
        v = Object.assign({}, v, this.hideSearch);
        for (const key in v) {
            try {
                if (this.arr_map[key]) {
                    let tempDate = v[key];
                    if (tempDate instanceof Array) {
                        if (this.arr_map[key].stockpile === 'name') {
                            let cascader
                            if (Array.isArray(this.$refs[key + '-dict'])) {
                                cascader = this.$refs[key + '-dict'][0]
                            } else {
                                cascader = this.$refs[key + '-dict']
                            }
                            if (cascader) {
                                const node = cascader.getCheckedNodes()[0]
                                if (node) {
                                    v[key] = node.pathLabels.join(this.arr_map[key].delimiter)
                                }
                            }
                        } else {
                            v[key] = v[key].join(this.arr_map[key].delimiter);
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
        let param = {};
        console.log("doFormButton:", JSON.stringify(v));
        if (r.param_config) {
            param = eval("(" + r.param_config + ")");
        }


        if (r.config) {
            r.config = _.isString(r.config)
                ? JSON.parse(r.config || "{}")
                : r.config;
        }


        if (r.target_type == "action") {

            //action 参数包装,支持更多动态功能
            var opt = {param: param};

            if (r.config.reload) {
                opt.cb = {cb_id: r.call_back_id, cb_type: r.call_back_type, action: 'reLoad'};
            }

            console.log("doColumnButton3:", r.config, r.config.confirm);
            if (r.config.confirm) {
                this.confirm(r.config.confirm_msg ? r.config.confirm_msg : "是否确定要执行该操作", () => {
                    this.doAction(r.target_id, opt, r)
                }, () => {
                    console.log("您点击了取消");
                });
            } else if (r.config.validate) {
                this.$refs.form.validate((valid) => {
                    if (valid) this.doAction(r.target_id, opt, r)
                });
            } else {
                this.doAction(r.target_id, opt, r)
            }
        } else if (r.target_type == "page") {
            console.log('openPage');

            this.openPage(r.target_id, r.config.open_type, param, r)
        } else if (r.target_type == "view") {

            if (r.config.method) {
                console.log("method:", r.config.method);
                if (r.config.method == "data") {
                    this.setFormData(r.target_id, v)
                } else if (r.config.method == "refresh") {
                    //this.setFormData(r.target_id, param)
                } else if (r.config.method == "search") {
                    this.searchForm(r.target_id, param)
                }
            }

        } else if (r.target_type == "other") {

            if (r.config.method) {

                console.log("method:", r.config.method);

                if (r.config.method == "reset") {
                    this.resetForm(r.source_id, v)
                } else if (r.config.method == "refresh") {
                    //this.setFormData(r.target_id, param)
                } else if (r.config.method == "back") {
                    this.goBack(r.target_id, param)
                }
            }

        } else {
            this.onSuccess("缺少目标类型!");
        }
    },


    doColumnButton(r, v, p) {
        console.log("doColumnButton:", JSON.stringify(v));
        console.log("doColumnButton2:", JSON.stringify(r));
        console.log("doColumnButton param:", JSON.stringify(p));

        let param = {};
        if (r.param_config) {
            param = eval("(" + r.param_config + ")");
        }

        console.log("doColumnButton param2:", r.param_config, JSON.stringify(param));


        if (r.config) {
            r.config = _.isString(r.config)
                ? JSON.parse(r.config || "{}")
                : r.config;
        }

        const opt = {param: param};

        if (r.target_type == "action") {
            //action 参数包装,支持更多动态功能
            if (r.config.confirm) {
                this.confirm(r.config.confirm_msg ? r.config.confirm_msg : "是否确定要执行该操作", () => {
                    this.doAction(r.target_id, opt, r)
                }, () => {
                    console.log("您点击了取消");
                });
            } else {
                this.doAction(r.target_id, opt, r)
            }
        } else if (r.target_type == "page") {
            console.log('openPage');
            this.openPage(r.target_id, r.config.open_type, param, r)
        } else if (r.target_type == "view") {

            if (r.config.method) {

                console.log("method:", r.config.method);

                if (r.config.method == "data") {
                    this.setFormData(r.target_id, v)
                } else if (r.config.method == "refresh") {
                    //this.setFormData(r.target_id, param)
                } else if (r.config.method == "search") {
                    this.searchForm(r.target_id, param)
                }
            }

        } else {
            this.onSuccess("缺少目标类型!");
        }


    },
    //点击功能按钮
    doTableButton(r, p, sorts, data = []) {

        if (!r) {
            return;
        }
        console.log(r)
        if (r.target_type != 'download' && r.btn_type !== 2) {
            if (this.multipleSelection)
                console.log("check_type:" + r.config.check_type, this.multipleSelection.length);

            if (!r.config.check_type) {
                r.config.check_type == 0;
            }

            if (r.config.check_type > 0 && this.multipleSelection && this.multipleSelection.length == 0) {
                this.onSuccess("请至少选择一行数据!");
                return;
            } else if (r.config.check_type == 2 && this.multipleSelection && this.multipleSelection.length > 1) {
                this.onSuccess("只能选择一行数据!");
                return;
            }
        }
        let list = r.btn_type !== 2 ? this.multipleSelection : data
        var v = {};

        //有数据选中的
        if (r.config.check_type > 0) {
            var filter = {};
            for (var item of list) {

                console.log("item:", JSON.stringify(item));
                for (const k in item) {

                    if (!filter[item[k]]) {
                        if (v[k]) {
                            v[k].push(item[k]);
                        } else {
                            v[k] = [item[k]];
                        }

                        filter[item[k]] = 1;
                    }
                }
            }

            for (var k in v) {
                v[k] = v[k].join(",");
            }
        }

        console.log("v:", JSON.stringify(v));


        let param = {};
        if (r.param_config) {
            param = eval("(" + r.param_config + ")");
        }

        console.log("param_config:", r.param_config);
        console.log("param:", JSON.stringify(param));
        console.log("opt:", JSON.stringify(opt), "  source_type:", r.source_type);


        //if (opt) {
        if (r.target_type == "action") {

            //action 参数包装,支持更多动态功能
            var opt = {param: param};

            if (r.config.reload) {
                opt.cb = {cb_id: r.call_back_id, cb_type: r.call_back_type, action: 'reLoad'};
            }

            if (r.config.confirm) {
                this.confirm(r.config.confirm_msg ? r.config.confirm_msg : "是否确定要执行该操作", () => {
                    this.doAction(r.target_id, opt, r)
                }, () => {
                    console.log("您点击了取消");
                });
            } else {
                this.doAction(r.target_id, opt, r)
            }
        } else if (r.target_type == "page") {
            console.log('openPage');

            this.openPage(r.target_id, r.config.open_type, param, r)
        } else if (r.target_type == "view") {
            if (r.config.method) {

                console.log("method:", r.config.method);

                if (r.config.method == "data") {
                    this.setFormData(r.target_id, v)
                } else if (r.config.method == "refresh") {
                    //this.setFormData(r.target_id, param)
                } else if (r.config.method == "search") {
                    this.searchForm(r.target_id, param)
                }
            }

        } else if (r.target_type == "other") {
            if (r.config.method) {
                console.log("method:", r.config.method);
                if (r.config.method == "reset") {
                    this.resetForm(r.source_id, v)
                } else if (r.config.method == "refresh") {
                    //this.setFormData(r.target_id, param)
                } else if (r.config.method == "back") {
                    this.goBack(r.target_id, param)
                }
            }

        } else if (r.target_type == "download") {
            console.log('openDownload');

            this.openDownload(r.target_id, p, sorts, r)
        } else if (r.target_type == "callback") {
            //弹窗选择回调
            var action = 'setParam';
            if (r.config.cb_auto_query) {
                action = 'setParamAndQuery';
            }
            const opt = {param: param, id: r.call_back_id};

            this.windowCallBack(action, opt)
        } else {
            if (r.btn_type !== 2) {
                this.onSuccess("缺少目标类型!");
            }
        }
    }
    ,

//关闭弹窗
    closeWindow() {
        this.exec('closeWindow')
    }
    ,
//关闭弹窗
    closeSelfWindow() {
        this.exec('closeSelfWindow')
    }
    ,


//弹窗回调
    windowCallBack(act, opt) {
        this.exec('windowCallBack', act, opt)
    }
    ,

//执行功能
    doAction(id, opt, source = {}) {
        this.exec('doAction', id, opt, source)
    }
    ,
    openPage(id, mode, opt, source = {}) {
        this.exec('openPage', id, mode, opt, source)
    },

    openDownload(id, params, sorts, source = {}) {
        this.exec('openDownload', id, params, sorts, source)
    },


    goBack(opt) {
        this.exec('goBack', opt)
    }
    ,
    openUrl(url) {
        this.exec('openUrl', url)
    }
    ,
    confirm(msg, ok, fail) {
        this.exec('confirm', msg, ok, fail)
    }
    ,
    searchForm(id, opt) {
        this.exec('searchForm', id, opt)
    }
    ,
    setFormData(id, opt) {
        this.exec('setFormData', id, opt)
    }
    ,
    resetForm(id, opt) {
        console.log("resetForm:", id);
        this.exec('resetForm', id, opt)
    }
    ,
    exec(fun) {
        let root = this.getRoot();
        if (root.is_root) {
            if (root[fun]) {
                var args = [];
                console.log('arguments:', arguments);
                if (arguments.length > 1) {
                    args = Array.prototype.slice.call(arguments, 1);
                }
                root[fun].apply(null, args);
            } else {
                console.log("root fun " + fun + " not found!!!");
            }
        } else {
            console.log("root not found!!!");
        }
    }

}
