import {
    SET_VIEW,
    REMOVE_COL,
    UPD_COL,
    REMOVE_PARAM,
    ADD_PARAM,
    ADD_COL,
    ADD_BUTTON,
    DEL_BUTTON
} from '@/store/mutations-type'
import * as Api from "@/api";

let f = (d) => {
    //d.sort_num = i;
    d.config = JSON.stringify(d.config);


    if (d.ext_config) {
        d.ext_config = JSON.stringify(d.ext_config);
    }

    if (d.call_back_config) {
        d.call_back_config = JSON.stringify(d.call_back_config);
    }
    return d;
};


let subExec = (view) => {
    if (view.app_cols && typeof view.app_cols != "string") {
        view.app_cols = JSON.stringify(view.app_cols.map(f));
    }

    if (view.app_params && typeof view.app_params != "string") {
        view.app_params = JSON.stringify(view.app_params.map(f));
    }

    if (view.app_data && typeof view.app_data != "string") {
        if (view.app_data.ext_config && typeof view.app_data.ext_config != "string") {
            view.app_data.ext_config = JSON.stringify(view.app_data.ext_config);
        }

        view.before_config = view.app_data.before_config;
        view.call_back_config = view.app_data.call_back_config;
        view.app_data = JSON.stringify(view.app_data);
    }

    if (view.app_buttons && typeof view.app_buttons != "string") {
        view.app_buttons = JSON.stringify(view.app_buttons.map(f));
    }

    if (view.config && typeof view.config != "string") {
        view.config = JSON.stringify(view.config);
    }

    if (view.layout && typeof view.layout != "string") {
        view.layout = JSON.stringify(view.layout);
    }

    return view;
}


let loadExec = (r) => {
    if (!r.app_cols) {
        r.app_cols = [];
    }
    r.app_cols = r.app_cols.map(col => {
        col.config = JSON.parse(col.config || "{}");
        return col;
    });

    if (!r.app_params) {
        r.app_params = [];
    }
    r.app_params = r.app_params.map(param => {
        param.config = JSON.parse(param.config || "{}");
        return param;
    });

    if (!r.app_buttons) {
        r.app_buttons = [];
    }

    r.app_buttons = r.app_buttons.map(btn => {
        btn.config = JSON.parse(btn.config || "{}");
        btn.call_back_config = JSON.parse(btn.call_back_config || "{}");
        return btn;
    });

    if (r.app_data) {
        r.app_data.ext_config = JSON.parse(r.app_data.ext_config || "{}");
    }


    if (r.config) {
        r.config = JSON.parse(r.config);
    } else {
        r.config = {};
    }

    r.config = Object.assign(
        {
            tools: [],
            auto: false,
            page: true,
            card_border: false,
            card_shadow: false,
            download: false,
            once: false,
            timer: 0,
            page_size: 10,
        },
        r.config
    );


    if (r.layout) {
        r.layout = JSON.parse(r.layout);
    } else {
        r.layout = {};
    }

    r.layout = Object.assign(
        {"x": 0, "y": 0, "w": 24, "h": 3, "i": '' + r.id, n: r.id_name},
        r.layout
    );

    console.log("loadExec:", JSON.stringify(r.config));

    return r;
}

const state = {
    view: {}
}


const mutations = {
    [SET_VIEW](state, data) {
        state.view = data
    },
    [REMOVE_PARAM](state, id) {
        console.log("REMOVE_PARAM param count:" + state.view.app_params.length)
        if (state.view.app_params && state.view.app_params.length > 0) {
            for (let i = 0; i < state.view.app_params.length; i++) {
                let item = state.view.app_params[i];
                if (item.id === id) {
                    state.view.app_params.splice(i, 1);
                    break;
                }
            }
        }
        console.log("REMOVE_PARAM COL count:" + state.view.app_params.length)
    },

    [REMOVE_COL](state, id) {
        console.log("REMOVE_COL COL count:" + state.view.app_cols.length)
        if (state.view.app_cols && state.view.app_cols.length > 0) {
            for (let i = 0; i < state.view.app_cols.length; i++) {
                let item = state.view.app_cols[i];
                if (item.id === id) {
                    state.view.app_cols.splice(i, 1);
                    break;
                }
            }
        }
        console.log("REMOVE_COL COL count:" + state.view.app_cols.length)
    },

    [DEL_BUTTON](state, id) {
        console.log("DEL_BUTTON  count:" + state.view.app_buttons.length)
        if (state.view.app_buttons && state.view.app_buttons.length > 0) {
            for (let i = 0; i < state.view.app_buttons.length; i++) {
                let item = state.view.app_buttons[i];
                if (item.id === id) {
                    state.view.app_buttons.splice(i, 1);
                    break;
                }
            }
        }
        console.log("app_buttons  count:" + state.view.app_buttons.length)
    },

    [ADD_BUTTON](state, col) {

        if (!state.view.app_buttons) {
            state.view.app_buttons = [];
        }
        console.log("ADD_BUTTON  count:" + state.view.app_buttons.length)
        state.view.app_buttons.push(col)
        console.log("ADD_BUTTON  count:" + state.view.app_buttons.length)
    },
    [ADD_COL](state, col) {

        if (!state.view.app_cols) {
            state.view.app_cols = [];
        }
        console.log("ADD_COL COL count:" + state.view.app_cols.length)
        state.view.app_cols.push(col)
        console.log("ADD_COL COL count:" + state.view.app_cols.length)
    },

    [ADD_PARAM](state, item) {

        if (!state.view.app_params) {
            state.view.app_params = [];
        }
        console.log("ADD_PARAM  count:" + state.view.app_params.length)
        state.view.app_params.push(item)
        console.log("ADD_PARAM  count:" + state.view.app_params.length)
    },

    [UPD_COL](state, item) {
        console.log("UPD_COL COL count:" + state.view.app_cols.length)
        if (state.view.app_cols && state.view.app_cols.length > 0) {
            for (let i = 0; i < state.view.app_cols.length; i++) {
                let com = state.view.app_cols[i];
                if (com.id === item.id) {
                    state.view.app_cols[i] = item;
                    break;
                }
            }
        }
        console.log("UPD_COL COL count:" + state.view.app_cols.length)
    },
}


const actions = {


//随机新增一个新列,可以修改
    async addFormViewRel({rootState}, {id, query_ids}) {
        let r = await Api.post(`/manager/views/form/rel`, {
            id: id,
            app_id: rootState.app.id,
            query_ids: query_ids.join(",")
        });
        return r;
    },

    async delParam({commit, rootState}, id) {
        await Api.post(`/manager/params/del`, {app_id: rootState.app.id, id: id});
        commit(REMOVE_PARAM, id)
    },

    async delCol({commit, rootState}, id) {
        await Api.post(`/manager/cols/del`, {app_id: rootState.app.id, id: id});
        commit(REMOVE_COL, id)
    },

    //随机新增一个新列,可以修改
    async addParam({state, commit, rootState}) {
        var sort_num = 1;
        if (state.view.app_params) {
            for (var c of state.view.app_params) {
                if (c.sort_num > sort_num) {
                    sort_num = c.sort_num;
                }
            }
        }

        let name = 'param_' + new Date().getTime();
        let r = await Api.post(`/manager/params/add`, {
            sort_num: sort_num + 1,
            app_id: rootState.app.id,
            source_id: state.view.id,
            source_type: 'view',
            name: name,
            label: name,
            query_type: 1,
            enable: 1,
            config: "",
            form_type: 1,
            add_type: 2,//手动添加,merge不删除
        });

        r.config = {};
        commit(ADD_PARAM, r);

        return r;
    },


    //随机新增一个新列,可以修改
    async addCol({state, commit, rootState}) {
        let name = 'col_' + new Date().getTime();

        var sort_num = 1;
        if (state.view.app_cols) {
            for (var c of state.view.app_cols) {
                if (c.sort_num > sort_num) {
                    sort_num = c.sort_num;
                }
            }
        }

        let r = await Api.post(`/manager/cols/add`, {
            sort_num: sort_num + 1,
            app_id: rootState.app.id,
            source_id: state.view.id,
            source_type: 'view',
            name: name,
            label: name,
            enable: 1,
            config: JSON.stringify({col_large: 1}),
            form_type: 1,
            add_type: 2,//手动添加,merge不删除
        });

        r.config = {col_large: 1};
        commit(ADD_COL, r);

        return r;
    },

    async delBtn({commit, rootState}, id) {
        await Api.post(`/manager/buttons/del`, {app_id: rootState.app.id, id: id});
        commit(DEL_BUTTON, id)
    },

    //随机新增一个新列,可以修改
    async addBtn({state, commit, rootState}, payload = {}) {
        let name = 'btn_' + new Date().getTime();
        var sort_num = 1;
        if (state.view.app_buttons) {
            for (var c of state.view.app_buttons) {
                if (c.sort_num > sort_num) {
                    sort_num = c.sort_num;
                }
            }
        }

        let r = await Api.post(`/manager/buttons/add`, {
            sort_num: sort_num + 1,
            app_id: rootState.app.id,
            source_id: state.view.id,
            source_type: 'view',
            call_back_id: state.view.id,
            call_back_type: 'view',
            page_id: state.view.page_id,
            name: name,
            label: payload.label || name,
            enable: 1,
            display: 'button',
            type: 1,
            btn_type: payload.type || 1
        });

        r.call_back_config = {};

        commit(ADD_BUTTON, r);

        return r;
    },

    //批量更新列
    async updCols({rootState}, {view_id, cols}) {
        if (cols && typeof cols != "string") {
            cols = JSON.stringify(cols.map(f));
        }

        await Api.post(`/manager/cols/add/json`, {
            app_id: rootState.app.id,
            source_id: view_id,
            source_type: 'view',
            app_cols: cols
        })
        return "ok";
    },

    //批量更新列
    async updParams({rootState}, {view_id, params}) {
        if (params && typeof params != "string") {
            params = JSON.stringify(params.map(f));
        }

        await Api.post(`/manager/params/add/json`, {
            app_id: rootState.app.id,
            source_id: view_id,
            source_type: 'view',
            app_params: params
        })
        return "ok";
    },

    async getView({commit, rootState}, id) {
        let r = await Api.post(`/manager/views/get`, {app_id: rootState.app.id, id: id});
        r = loadExec(r);
        commit(SET_VIEW, r)
    },

    async delView({commit, rootState}, id) {
        await Api.post(`/manager/views/del`, {app_id: rootState.app.id, id: id});

        commit(SET_VIEW, {})
    },

    async addView({commit}, view) {
        view = subExec(view);
        let r = await Api.post(`/manager/views/add`, view);
        if (r.app_cols) {
            r.app_cols = [];
        }
        r.app_cols = r.app_cols.map(col => {
            col.config = JSON.parse(col.config || "{}");
            return col;
        });
        r.config = JSON.parse(r.config || "{}");

        commit(SET_VIEW, r)
        return "ok";
    },

    async updView({commit}, view) {
        view = subExec(view);
        let r = await Api.post(`/manager/views/upd`, view);
        r = loadExec(r);
        commit(SET_VIEW, r)
        return "ok";
    }
}

export default {
    state,
    mutations,
    actions
}
