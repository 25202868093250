import {SET_APP_ID, LIST_APP} from '@/store/mutations-type'


const state = {
    app_id: 0,
    app_list: [],
}


const mutations = {
    [SET_APP_ID](state, app_id) {
        console.log("SET_APP_ID:" + app_id);
        state.app_id = app_id
    },

    [LIST_APP](state, {data}) {
        state.app_list = data.list
    }
}


const actions = {
    async query_app_list({commit}) {
        commit(LIST_APP, {data: {list: []}, type: 1})
    }
}

export default {
    state,
    mutations,
    actions
}
