<template>
  <div class="view-widget view-form">
    <el-form :size="size" width="300px"
             label-position="right"
             :label-width="view.config.labelWidth&&view.config.labelWidth>0?view.config.labelWidth+'px':'100px'"
             :model="subForm"
             ref="form"
             :rules="rules"
             style="padding: 10px 10px 0 0"
             :style="{border:view.config.border?'1px solid #eee':'',
             width:view.config.width?view.config.width+'px':'',
             height:view.config.height?view.config.height+'px':'',
             overflow:view.config.width||view.config.height?'auto':''}"
    >
      <el-form-item v-if="view&&view.config&&view.config.title">
        <section>
          <p v-html="view.config.title" class="form-title"></p>
        </section>
      </el-form-item>
      <el-row>
        <template v-for="request in view.app_params">
          <el-col :span="request.config.span||24" v-if="request.form_type==20" :key="request.name"
                  style="display: flex;align-items: start;">
            <div
                :style="'width:'+(view.config.labelWidth&&view.config.labelWidth>0?view.config.labelWidth+'px':'100px')"
                style="line-height: 36px;font-size: 15px;color: #606266;text-align: right;padding-right:12px">
              {{ request.label }}
            </div>
            <el-row style="flex: 1">
              <el-col v-for=" rq in getMemberMap(request.config.member || [])" :key="rq.id" :span="rq.config.span||24">
                <el-form-item :size="size"
                              :key="rq.name"
                              :prop="rq.name"
                              v-if="rq.enable == 1&&rq.form_type!=11">
                  <div slot="label" style="word-break:break-all;">
                    {{ rq.label }}
                  </div>
                  <el-input v-if="rq.form_type == 1"
                            v-model="subForm[rq.name]" :size="size"></el-input>


                  <el-select v-else-if="rq.form_type == 2" :popper-append-to-body="false"
                             v-model="subForm[rq.name]" @change="changeHandler" :size="size">
                    <el-option v-for="opt in rq.data_option"
                               :key="opt.k"
                               :label="opt.v"
                               :value="opt.k"></el-option>
                  </el-select>
                  <el-radio-group v-else-if="rq.form_type == 4 " v-model="subForm[rq.name]"
                                  @change="changeHandler" :size="size">
                    <template v-for="opt in rq.data_option">
                      <el-radio v-if="rq.data_option" :key="opt.k"
                                :label="opt.k">{{ opt.v }}
                      </el-radio>
                    </template>
                  </el-radio-group>
                  <el-date-picker v-else-if="rq.form_type == 3" :size="size" :popper-append-to-body="false"
                                  v-model="subForm[rq.name]" type="datetime"
                                  :format="rq.config.timeFormat?handleDateFormat(rq.config.timeFormat):'yyyy-MM-dd HH:mm:ss'"
                                  :value-format="rq.config.timeFormat?handleDateFormat(rq.config.timeFormat):'yyyy-MM-dd HH:mm:ss'">
                  </el-date-picker>


                  <el-input-number v-else-if="rq.form_type == 5" v-model="subForm[rq.name]"></el-input-number>


                  <el-date-picker type="datetimerange" :size="size"
                                  :unlink-panels="true"
                                  v-model="date_range"
                                  :popper-append-to-body="false"
                                  v-else-if="rq.form_type == 6"
                                  :picker-options="pickerOptions"
                                  range-separator="至"
                                  start-placeholder="开始时间"
                                  end-placeholder="结束时间"
                                  :format="rq.config.timeFormat?handleDateFormat(rq.config.timeFormat):'yyyy-MM-dd HH:mm:ss'"
                                  :value-format="rq.config.timeFormat?handleDateFormat(rq.config.timeFormat):'yyyy-MM-dd HH:mm:ss'"
                                  @change="v => onDateRangePicker(v, rq)"
                                  align="right">
                  </el-date-picker>


                  <el-checkbox-group v-else-if="rq.form_type == 7"
                                     v-model="subForm[rq.name]">
                    <template v-for="opt in rq.data_option">
                      <el-checkbox :key="opt.k" v-if="rq.data_option"
                                   :label="opt.k">{{ opt.v }}
                      </el-checkbox>
                    </template>
                  </el-checkbox-group>

                  <el-input v-else-if="rq.form_type == 8" type="textarea" :rows="3"
                            v-model="subForm[rq.name]" :size="size"></el-input>

                  <template v-else-if="rq.form_type == 9">
                    <div v-html="subForm[rq.name]"></div>
                  </template>

                  <template v-else-if="rq.form_type == 99">
                    <dynamic :template="rq.html" :v="subForm" :p="view.app_params" :app_id="view.app_id"></dynamic>
                  </template>

                  <template v-else-if="rq.form_type == 15">
                    {{ subForm[rq.name] }}
                  </template>

                  <template v-else-if="rq.form_type == 16">
                    <baidu-map :v="subForm" :p="view.app_params" :app_id="view.app_id"></baidu-map>
                  </template>


                  <template v-else-if="rq.form_type == 17">
                    <el-image
                        style="width: 100px; height: 120px"
                        :src="subForm[rq.name]"
                        :preview-src-list="subForm[rq.name]">
                    </el-image>
                  </template>
                  <template v-else-if="rq.form_type == 18">
                    <el-cascader v-if="rq.dict_type===1"
                                 :key="rq.id"
                                 ref="cascader"
                                 clearable
                                 style="width: 100%"
                                 :props="{label:'v',value:'k',children:'subs'}"
                                 v-model="subForm[rq.name]" :popper-append-to-body="false"
                                 :options="rq.data_option"></el-cascader>
                    <el-cascader v-if="rq.dict_type===2"
                                 :key="rq.name+'-dict'"
                                 :ref="rq.name+'-dict'"
                                 clearable
                                 style="width: 100%"
                                 :props="{label:'name',value:'code',lazy:true,lazyLoad:getDictNodes}"
                                 v-model="subForm[rq.name]" :popper-append-to-body="false"
                                 :options="rq.data_option"></el-cascader>
                  </template>

                  <template v-else-if="rq.form_type == 19">
                    <!--<div>{{cp_html(col.html)}}</div>-->
                    <div v-if="rq.app_buttons">
                      <template v-for="btn in rq.app_buttons">
                        <el-button :type="btn.display==='button'?'':btn.display" :key="btn.id"
                                   @click="doFormButton({...btn,source_id:btn.call_back_id},subForm)"
                                   :size="size" v-if="btn.enable&&condBtn(btn,subForm)"
                        >{{ btn.label }}
                        </el-button>
                      </template>
                    </div>
                  </template>
                  <div class="editor-container" v-else-if="rq.form_type == 10">
                    <Tinymce :height=400 ref="editor" v-model="subForm[rq.name]"/>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col v-else-if="request.form_type!=20 && memberIds.indexOf(request.id)===-1"
                  :span="request.config.span||24" :key="request.name">
            <el-form-item :size="size"
                          :prop="request.name"
                          v-if="request.enable == 1&&request.form_type!=11">
              <div slot="label" style="word-break:break-all;">
                {{ request.label }}{{ view.config.suffix || '' }}
              </div>
              <el-input v-if="request.form_type == 1"
                        v-model="subForm[request.name]" :size="size"></el-input>
              <el-select v-else-if="request.form_type == 2" :popper-append-to-body="false"
                         v-model="subForm[request.name]" @change="changeHandler" :size="size">
                <el-option v-for="opt in request.data_option"
                           :key="opt.k"
                           :label="opt.v"
                           :value="opt.k"></el-option>
              </el-select>
              <el-radio-group v-else-if="request.form_type == 4 " v-model="subForm[request.name]"
                              @change="changeHandler" :size="size">
                <template v-for="opt in request.data_option">
                  <el-radio v-if="request.data_option" :key="opt.k"
                            :label="opt.k">{{ opt.v }}
                  </el-radio>
                </template>
              </el-radio-group>
              <el-date-picker v-else-if="request.form_type == 3" :size="size" :popper-append-to-body="false"
                              v-model="subForm[request.name]" type="datetime"
                              :format="request.config.timeFormat?handleDateFormat(request.config.timeFormat):'yyyy-MM-dd HH:mm:ss'"
                              :value-format="request.config.timeFormat?handleDateFormat(request.config.timeFormat):'yyyy-MM-dd HH:mm:ss'">
              </el-date-picker>


              <el-input-number v-else-if="request.form_type == 5" v-model="subForm[request.name]"></el-input-number>


              <el-date-picker type="datetimerange" :size="size"
                              :unlink-panels="true"
                              v-model="date_range"
                              :popper-append-to-body="false"
                              v-else-if="request.form_type == 6"
                              :picker-options="pickerOptions"
                              range-separator="至"
                              start-placeholder="开始时间"
                              end-placeholder="结束时间"
                              :format="request.config.timeFormat?handleDateFormat(request.config.timeFormat):'yyyy-MM-dd HH:mm:ss'"
                              :value-format="request.config.timeFormat?handleDateFormat(request.config.timeFormat):'yyyy-MM-dd HH:mm:ss'"
                              @change="v => onDateRangePicker(v, request)"
                              align="right">
              </el-date-picker>


              <el-checkbox-group v-else-if="request.form_type == 7"
                                 v-model="subForm[request.name]">
                <template v-for="opt in request.data_option">
                  <el-checkbox :key="opt.k" v-if="request.data_option"
                               :label="opt.k">{{ opt.v }}
                  </el-checkbox>
                </template>
              </el-checkbox-group>

              <el-input v-else-if="request.form_type == 8" type="textarea" :rows="3"
                        v-model="subForm[request.name]" :size="size"></el-input>

              <template v-else-if="request.form_type == 9">
                <div v-html="subForm[request.name]"></div>
              </template>

              <template v-else-if="request.form_type == 99">
                <dynamic :template="request.html" :v="subForm" :p="view.app_params" :app_id="view.app_id"></dynamic>
              </template>

              <template v-else-if="request.form_type == 15">
                {{ subForm[request.name] }}
              </template>

              <template v-else-if="request.form_type == 16">
                <baidu-map :v="subForm" :p="view.app_params" :app_id="view.app_id"></baidu-map>
              </template>


              <template v-else-if="request.form_type == 17">
                <el-image
                    style="max-width: 150px; max-height: 150px;vertical-align: top;border: 1px solid #ccc"
                    :src="subForm[request.name]"
                    :preview-src-list="subForm[request.name]">
                </el-image>
              </template>
              <template v-else-if="request.form_type == 18">
                <el-cascader v-if="request.dict_type===1"
                             :key="request.id"
                             ref="cascader"
                             clearable
                             style="width: 100%"
                             :props="{label:'v',value:'k',children:'subs'}"
                             v-model="subForm[request.name]" :popper-append-to-body="false"
                             :options="request.data_option"></el-cascader>
                <el-cascader v-if="request.dict_type===2"
                             :key="request.name+'-dict'"
                             :ref="request.name+'-dict'"
                             clearable
                             style="width: 100%"
                             :props="{label:'name',value:'code',lazy:true,lazyLoad:getDictNodes}"
                             v-model="subForm[request.name]" :popper-append-to-body="false"
                             :options="request.data_option"></el-cascader>
              </template>

              <template v-else-if="request.form_type == 19">
                <!--<div>{{cp_html(col.html)}}</div>-->
                <div v-if="request.app_buttons">
                  <template v-for="btn in request.app_buttons">
                    <el-button :type="btn.display==='button'?'':btn.display" :key="btn.id"
                               @click="doFormButton({...btn,source_id:btn.call_back_id},subForm)"
                               :size="size" v-if="btn.enable&&condBtn(btn,subForm)" :loading="btnLoading"
                    >{{ btn.label }}
                    </el-button>
                  </template>
                </div>
              </template>
              <div class="editor-container" v-else-if="request.form_type == 10">
                <Tinymce :height=400 ref="editor" v-model="subForm[request.name]"/>
              </div>
              <template v-else-if="request.form_type == 21">
                <el-upload accept="image/*" :action="apiHost+'/manager/file/static/upload'" class="avatar-uploader"
                           :http-request="(f)=>{upload(f,request)}" :show-file-list="false">

                  <div v-if="subForm[request.name]" class="avatar-uploader-box">
                    <img class="el-upload-list__item-thumbnail" :src="subForm[request.name]" alt="">
                    <span class="el-upload-list__item-actions">
                      <span
                          @click.stop="handlePictureCardPreview(subForm[request.name])">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span @click.stop="handleRemove(request.name)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                  <i v-else class="el-icon-plus"></i>
                </el-upload>
              </template>
            </el-form-item>
          </el-col>
        </template>
      </el-row>


      <el-form-item :size="size" v-if="view.app_buttons&&view.app_buttons.length>0">
        <template v-for="btn in view.app_buttons">
          <el-button type="success" :key="btn.id" @click="doFormButton(btn,subForm)" :show-file-list="false"
                     :size="size" v-if="btn.enable&&condBtn(btn,subForm)" :loading="btnLoading"
          >{{ btn.label }}
          </el-button>
        </template>
      </el-form-item>

    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import * as Api from "@/api";
import dateTime from "@/api/date_time";
import reg from "@/api/register";
import Tinymce from '@/components/Tinymce'
import BaiduMap from '../common/map'
import Dynamic from "../common/dynamic";
import * as _ from "lodash";
import {dataConfigMap, listToTree, setNullForEmptyChildren, transformTree} from "@/utils/tool";
import {API_HOST} from '@/api'

const shortcuts = dateTime.shortcuts;


export default {
  data() {
    return {
      apiHost: API_HOST,
      btnLoading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      init: false,
      loading: false,
      pickerOptions: {
        shortcuts: shortcuts
      },
      warning: false,
      date_range: [],
      date_val: {},
      data: {},
      rules: {},
      params: {},
      type: {},
      hideSearch: {},
      paramsMap: {},
      arr_map: {},
      subForm: {
        has_del: "2"
      },
      extSearch: {},
      searchRules: {},
      prod: process.env.NODE_ENV === 'production',
    };
  },
  computed: {
    memberIds() {
      const list = []
      this?.view?.app_params?.forEach(e => {
        const l = e?.config?.member || []
        list.push(...l)
      })
      return list
    }
  },
  props: {
    size: {
      default: 'medium'
    }
    ,
    pro: {
      default: false
    }
    ,
    view: {}

  },
  watch: {
    params: (o, n) => {
      console.log("search config params change ....", o, n);
    },
    subForm: {
      immediate: true,
      deep: true,
      handler(v) {
        this.$store.commit('updateAllViewData', {
          list: [],
          form: {...v},
          total: 0,
          page_no: 0,
          page_size: 0,
          view_name: this.view.name
        })
      }
    }
  },
  methods: {
    ...reg,
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    handleRemove(name) {
      this.subForm[name] = ''
    },
    upload(file, paramData) {
      console.log(file, 'file')
      console.log(paramData, 'data')
      return new Promise((resolve) => {
        if (String(paramData.config.upload_type) === '1') {
          this.$api.upload('/app/param/upload', {
            app_id: paramData.app_id,
            f: file.file,
            id: paramData.id
          }).then(({data}) => {
            this.subForm[paramData.name] = (API_HOST || window.location.origin) + data.data.url
            resolve({
              url: (API_HOST || window.location.origin) + data.data.url,
              name: data.data.name
            })
          })
        } else if (String(paramData.config.upload_type) === '2') {
          // file对象转base64
          let reader = new FileReader();
          reader.readAsDataURL(file.file);
          reader.onload = (e) => {
            let base64 = e.target.result;
            this.subForm[paramData.name] = base64;
            resolve({
              url: base64,
              name: file.file.name
            })
          }
        }


      })
    },
    getMemberMap(member) {
      const list = []
      member?.forEach(e => {
        const d = this.view.app_params.find(r => r.id === e)
        if (d) {
          list.push(d)
        }
      })
      return list
    },
    handleDateFormat(timeFormat) {
      return timeFormat.replace('YYYY', 'yyyy').replace('DD', 'dd');
    },
    changeHandler(v, r) {
      console.log("v:", v, " r:", r);
    },
    // 最终调用加载
    load(data) {
      this.subForm = {};
      if (data) {
        this.setData(data);
      }
    },
    search(params) {
      this.query(params);
    },
    setParams(new_params) {
      console.log("new_params:", JSON.stringify(new_params));
      //let search = Object.assign({}, this.search, params);
      if (new_params) {
        for (let name in new_params) {
          console.log("n:" + name);
          if (new_params[name]) {
            this.subForm[name] = new_params[name];
          }
        }
      }
    },
    query(params) {
      this.warning = false;
      console.log("form load:", this.init, JSON.stringify(params));
      params = params || {};
      if (params) {
        this.setParams(params);
      }

      if (this.view && this.view.data_id && this.view.app_data && this.view.app_data.data_type != 0) {
        this.loading = true;
        params = this.execBefore(this.view, params);
        Api.post(`/app/data/run`, {
          app_id: this.view.app_id,
          data_id: this.view.data_id,
          form_id: this.view.id,
          has_total: 0,
          data_params: JSON.stringify(params),
          page_no: 0,
          page_size: 2,
          order_params: ""
        })
            .then(r => {
              if (r.success) {
                var response = r.response;
                if (response && response.ok && response.data) {
                  try {
                    this.execCallback2(this.view, r.response.data, params, (response) => {
                      let map = {}
                      if (response.list && response.list.length > 0) {
                        map = response.list[0]
                      }
                      this.setData(this.wrapData(map));
                    })
                  } catch (e) {
                    this.showFail(e)
                  }
                } else {
                  this.showFail(response ? response.msg : "")
                }

              } else {
                this.showFail(r.msg)
              }
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    wrapData(data) {
      for (let key in data) {

        let item = this.paramsMap[key];
        if (!item) {
          continue;
        }
        //console.log("wrapData  key :", key);
        if (item.transFun) {
          var ret = item.transFun(this.getRoot(), data);
          if (ret.then) {
            ret.then((d) => {
              data[key] = d;
            });
          } else {
            data[key] = ret;
          }
        }
      }
      return data;
    },
    getDictNodes(node, resolve) {
      if (node.level === 0) {
        return
      }
      if (node.data.child_dict && node.data.child_dict.length >= 1) {
        let data = node.data.child_dict[0]
        data.app_id = node.data.app_id
        data.param_id = node.data.param_id
        this.getApiSubs(data, resolve, node)
      } else {
        return resolve([])
      }
    },
    getApiSubs(data, resolve, node) {
      data = dataConfigMap(_.cloneDeep(data), 'config', {name: '', code: '', p_col: ''})
      data = dataConfigMap(_.cloneDeep(data), 'app_data', {})
      let params = null
      if (data.app_data.db_type === 'http') {
        if (node) {
          params = {...node.data}
          delete params.child_dict
          delete params.children
        }
      } else {
        if (node && data.config.p_col) {
          params = {}
          params[data.config.p_col] = node.data.code
        }
      }
      if (data.app_id) {
        this.$api.post('/app/param/data/run', {
          app_id: data.app_id,
          param_id: data.param_id,
          dict_id: data.id,
          page_no: -1,
          page_size: -1,
          data_params: params ? JSON.stringify(params) : null
        }).then(res => {
          if (data.app_data.db_type === 'http') {
            if (data.app_data.call_back_config) {
              if (res && res.success && res.response && res.response.ok && res.response.data) {
                try {
                  let response = res.response.data.list;
                  let cbFun = new Function('v', data.app_data.call_back_config);
                  const {ok, msg, list} = cbFun(response);
                  if (ok) {
                    list.forEach(e => {
                      e.child_dict = data.child_dict
                      e.app_id = data.app_id
                      e.param_id = data.param_id
                      e.children = []
                      if ((!data.child_dict) || data.child_dict.length === 0) {
                        e.leaf = true
                      }
                    })
                    return resolve(list)
                  } else {
                    this.$message.warning(msg)
                    return resolve([])
                  }
                } catch (e) {
                  return resolve([])
                }
              } else {
                return resolve([])
              }
            } else {
              return resolve(res?.response?.data?.list || [])
            }
          } else {
            let list = _.get(res, 'response.data.list', [])
            list = list.map(e => {
              e.name = e[data.config.name || 'name']
              e.code = e[data.config.code || 'id']
              e.child_dict = data.child_dict
              e.app_id = data.app_id
              e.param_id = data.param_id
              e.children = []
              if ((!data.child_dict) || data.child_dict.length === 0) {
                e.leaf = true
              }
              return e
            })
            return resolve(list)
          }
        })
      }
    },
    onParamConfig(request) {
      if (typeof request.config === 'string') {
        request.config = JSON.parse(request.config || "{}");
      }
      let oldData = _.cloneDeep(request)
      oldData = dataConfigMap(oldData, 'data_config')
      oldData = dataConfigMap(oldData, 'input_config', {framework: 2, delimiter: '/'})
      if (oldData.form_type == 2 || oldData.form_type == 4 || oldData.form_type == 18 || oldData.form_type == 7) {
        if (oldData.form_type == 18) {
          this.arr_map[request.name] = {
            delimiter: oldData.input_config.delimiter || '/',
            stockpile: oldData.input_config.stockpile || 'code'
          };
        }
        if (oldData.data_type === 2) {
          this.$api.post('/app/param/dict', {
            id: oldData.id,
            app_id: oldData.app_id
          }).then((res) => {
            // k是值 v是label
            const {sub_dict = [], dict_type = 1} = res
            this.$set(request, 'dict_type', dict_type)
            if (dict_type === 1) {
              const tree = transformTree(_.cloneDeep(sub_dict || []), [
                {oldKey: 'name', newKey: 'v'},
                {oldKey: 'code', newKey: 'k'}
              ], 'subs')
              this.$set(request, 'data_option', tree)
            } else if (dict_type === 2) {
              this.getApiSubs({...res, app_id: oldData.app_id, param_id: oldData.id}, (list) => {
                list.forEach(e => {
                  if (oldData.input_config.framework === 2) e.leaf = true
                  e.v = e.name
                  e.k = e.code
                })
                if (list.length >= 1 && oldData.form_type == 18) {
                  for (let i = list.length - 1; i > list.length - 3; i--) {
                    const node = list[i]
                    if (node.child_dict && node.child_dict.length >= 1) {
                      let data = node.child_dict[0]
                      data.app_id = node.app_id
                      data.param_id = node.param_id
                      console.log(data, node)
                      this.getApiSubs(data, (l = []) => {
                        node.children = [...l]
                      }, {data: node})
                    }
                  }
                }
                this.$set(request, 'data_option', list)
              })
            }
          })
        } else {
          let jsonVal = oldData.data_config;
          //兼容以前的数据
          if (_.isArray(jsonVal)) {
            request.data_option = jsonVal;
          } else {
            let list = [];
            for (let k in jsonVal) {
              list.push({p: 0, k: k, v: jsonVal[k]});
            }
            request.data_option = [...list]
          }
          if (oldData.input_config.framework === 1) {
            request.data_option = listToTree(_.cloneDeep(request.data_option), 'k', 'p', 'subs')
            request.data_option.forEach(e => {
              setNullForEmptyChildren(e, 'subs')
            })
          }
        }
      }
    },

    warpCol(item) {
      try {
        if (item.trans) {
          console.log("trans:" + item.trans);
          item.transFun = new Function("_self", "v", item.trans);
        }

      } catch (e) {
        console.error(`报错了 => `, item, e);
      }
      return item;
    },
    getDateTimePickerOption() {
      return this.pickerOptions
    },
    refresh() {
      this.init = false;
      this.subForm = {};
      this.arr_map = {}
      let r = _.cloneDeep(this.view);

      if (r.app_data) {
        this.data = r.app_data;
      }

      if (r.style) {
        this.loadCss(r.style);
      }


      this.dataList = [];
      let params = [];
      let btns = [];
      //console.log("widget table r:", JSON.stringify(r));

      if (r && r.app_params && r.config) {
        r.app_params = _.isString(r.app_params)
            ? JSON.parse(r.app_params || "[]")
            : r.app_params;
        console.log("app_params length:", r.app_params.length);


        if (r.app_params && r.app_params.length > 0) {
          params = r.app_params.map(param => {
            param.config = _.isString(param.config)
                ? JSON.parse(param.config || "{}")
                : param.config;
            return this.warpCol(param);
          });
        }

        r.app_buttons = _.isString(r.app_buttons)
            ? JSON.parse(r.app_buttons || "[]")
            : r.app_buttons;

        if (r.app_buttons && r.app_buttons.length > 0) {
          btns = r.app_buttons.map(btn => {
            btn.config = _.isString(btn.config)
                ? JSON.parse(btn.config || "{}")
                : btn.config;

            //表达式,不需要解析
//              btn.param_config = _.isString(btn.param_config)
//                ? JSON.parse(btn.param_config || "{}")
//                : btn.param_config;
            return btn;
          });
        }


        this.view.call_back_config = r.call_back_config;
        this.view.before_config = r.before_config;
        this.view.config = _.isString(r.config)
            ? JSON.parse(r.config || "{}")
            : r.config;
        this.view.config.tools = r.config.tools || [];


        this.view.app_buttons = btns;
        this.view.app_params = params;
        this.dataTools = (r.config && r.config.tools) ? r.config.tools.filter(v => v.type == "row") : {};


        this.paramsMap = _.keyBy(params, "name");

        if (this.view.app_params) {
          for (let request of this.view.app_params) {
            this.onParamConfig(request);
            //记录类型
            if (request.query_type === 2) {
              this.type[request.name] = "number";
            } else if (request.query_type === 3) {
              this.type[request.name] = "bool";
            }


            if (request.must == 1) {
              this.rules[request.name] = [{required: true, message: '不能为空!'}]
            }

            if (request.form_type == 7) {
              this.subForm[request.name] = [];
            }

            if (request.enable == 1 && request.form_type != 11 && request.form_type != 7) {
              if (request.def_val) {
                console.log("def_val:", request.def_val, request.form_type, request.name);
                //时间范围
                if (request.form_type == 6) {
                  var range = dateTime.timeRange(request.def_val);
                  if (range && range.length > 1) {
                    this.date_range = range;
                    this.subForm[request.config.endField] = dateTime.utc2date(range[1]);
                    this.subForm[request.name] = dateTime.utc2date(range[0]);
                  }
                } else if (request.form_type == 7) {
                  this.$set(
                      this.subForm,
                      request.name,
                      request.def_val.split(",")
                  )
                } else {

                  this.$set(
                      this.subForm,
                      request.name,
                      request.def_val
                  )
                }
              } else {
                //多选
                if (request.form_type == 7) {
                  this.$set(
                      this.subForm,
                      request.name,
                      []
                  )
                } else {
                  this.$set(
                      this.subForm,
                      request.name,
                      ""
                  )
                }
              }
            } else if (request.enable == 1 && request.form_type == 11 && request.def_val) {
              //hide 变量
              this.hideSearch[request.name] = request.def_val;
              this.subForm[request.name] = request.def_val;
            } else if (request.enable == 1 && request.form_type == 7 && request.def_val) {
              //多选
              this.subForm[request.name] = [request.def_val];
            }
          }
        }

        this.init = true;
        //this.rules = rules;
      }
    },


    setData(data) {
      console.log("data:", JSON.stringify(data));

      let temp = Object.assign({}, this.subForm, data);

      //如果加载了数据,就把默认值替换掉
      for (let key in data) {
        if (this.hideSearch[key]) {
          this.hideSearch[key] = data[key];
        }
      }

      this.subForm = temp;
    }
    ,


    //
    onSubmit() {
      console.log("onSubmit:", JSON.stringify(this.rules));
      this.$refs.form.validate(valid => {
        console.log("search valid:", valid);
        if (!valid) {
          return false;
        }
        console.log("search:", JSON.stringify(this.subForm));
        console.log("extSearch:", JSON.stringify(this.extSearch));


        let search = Object.assign({}, this.subForm, this.extSearch);
        Object.assign({}, search, this.hideSearch);


        //类型转换
//          for (let key in search) {
//            let val = search[key];
//            if (val && this.type[key] && this.type[key] == "number") {
//              search[key] = ~~val;
//            }
//          }
        console.log("onSubmit:" + JSON.stringify(this.subForm));


        //this.$emit("search", {search: search, form_id: this.view.id, query_ids: query_ids});
      });
    }
    ,
    onReset() {
      console.log("subform onReset");
      this.$refs.form.resetFields();
      this.date_range = {};
      this.subForm = Object.assign({}, this.subForm, this.hideSearch);
    }
    ,
    onDateRangePicker(v, col) {

//        console.log("onDateRangePicker:", JSON.stringify(v));
//        console.log("onDateRangePicker2:", JSON.stringify(this.date_range));
      if (v) {
        this.date_range = v;
        this.extSearch[col.config.endField] = v[1];
        this.extSearch[col.name] = v[0];
        this.subForm[col.name] = v[0];
        this.subForm[col.config.endField] = v[1];
      } else {
        this.date_range = [];
        this.extSearch[col.config.endField] = '';
        this.extSearch[col.name] = '';
        this.subForm[col.name] = '';
        this.subForm[col.config.endField] = '';
      }
    }
  },
  components: {
    Dynamic,
    Tinymce,
    BaiduMap
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh(this.view);
    });
  }
};
</script>
<style lang="less" scoped>
.view-form {
  width: 100%;
  padding: 10px 10px 10px 10px;

  .form-title {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-box .el-upload-list__item-actions {
  position: absolute;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  top: 0;
  left: 0;

  span {
    padding: 0 20px;
    display: none;
    color: #fff;
    font-size: 16px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);

    span {
      display: inline;
    }
  }
}
</style>
