<template>
  <el-dialog :visible.sync="show" :close-on-press-escape="false" :close-delay="false" :close-on-click-modal="false"
             :show-close="false"
             append-to-body width="450px" class="login-dialog">
    <div
        class="right login-section flex-1 flex justify-center  items-center">
      <div class="login-container bg-white p-10 shadow rounded-md "
           style="width: 450px;">
        <div class="mb-6">
          <h3 class="text-2xl">
            {{ ENV_DATA.SYSNAME }}
          </h3>
        </div>
        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
        >
          <el-form-item prop="name">
            <el-input prefix-icon="el-icon-user" v-model="formData.name" placeholder="账号" auto-complete="on"
                      clearable></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input prefix-icon="el-icon-lock" type="password" placeholder="密码" v-model="formData.pwd" clearable
                      show-password auto-complete="new-password"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="flex items-center">
              <el-input prefix-icon="el-icon-thumb" @keyup.enter.native="onSubmit" placeholder="验证码" clearable
                        v-model="formData.code"></el-input>
              <div title="点击刷新验证码" @click="getCaptcha" class="ml-3 cursor-pointer">
                <img :src="formData.captcha" style="width: 100px;height: 38px;border: 1px;"/>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
                round
                class="w-full login-btn-normal"
                type="primary"
                @click="onSubmit"
            >登 录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import {mapState} from "vuex";
import * as Api from "@/api";
import {hexToRgb} from "@/utils/tool";

export default {
  name: 'dialog.appLogin',
  data() {
    let params = this.$route.query;
    return {
      show: false,
      ENV_DATA: {
        COMPANY_STYLE: {},
        COMPANY_NAME: '南京树安信息技术有限公司',
        SYSNAME: '数据可视化DataGo',
        LOGIN_LOGO: '/static/image/data-go-white.png',
        VERSION: process.env.VUE_APP_VERSION
      },
      bgList: [
        {
          imagePath: '/static/login/bg-0.webp',
          text: '时间，天天得到的都是二十四小时，可是一天的时间给勤勉的人带来聪明和气力，给懒散的人只留下一片悔恨。'
        },
        {
          imagePath: '/static/login/bg-1.webp',
          text: '享受每一天，不恐惧衰老，不害怕改变和失望，无论多少岁，都可以时尚，都可以保持天真之心，都有得到幸福的机会，只要你不放弃。'
        },
        {
          imagePath: '/static/login/bg-2.webp',
          text: '莫听穿林打叶声，何妨吟啸且徐行。竹杖芒鞋轻胜马，谁怕？一蓑烟雨任平生。料峭春风吹酒醒，微冷，山头斜照却相迎。回首向来萧瑟处，归去，也无风雨也无晴。'
        },
        {
          imagePath: '/static/login/bg-3.webp',
          text: '三更灯火五更鸡，正是男儿读书时，黑发不知勤学早，白发方悔读书迟。'
        },
        {
          imagePath: '/static/login/bg-4.webp',
          text: '人生来就是孤独的，不要奢望能够依靠谁，哪怕是至亲至爱。越是喧嚣处，往往更孤独。人生无处不修行，能在孤独中心静如水，才能在纷扰里安然无恙。'
        },
        {
          imagePath: '/static/login/bg-5.webp',
          text: '在薄情的世界里，每个人都来去匆匆，没有谁会永远陪伴在你身边，没有人会时刻对你关怀备至，体贴入微，倒不如学会好好爱自己，不辜负余生。'
        },
        {
          imagePath: '/static/login/bg-6.webp',
          text: '世上唯一不能复制的是时间，唯一不能重演的是人生，唯一不劳而获的是年龄，该怎么走，过什么样的生活，全凭自己的选择和努力。人生很贵，千万不要浪费！'
        },
        {
          imagePath: '/static/login/bg-7.webp',
          text: '做一个人生的观光客吧，说到底只要与人为善，以德服人，离是非远点，靠家人近点，便有了心安，有了惬意。'
        }
      ],
      path: "",
      mode: "normal",
      back_url: params.url,
      url: "",
      formData: {
        name: "",
        pwd: "",
        code: "",
        captcha: '',
        captcha_id: '',
      },
      rules: {
        name: [{required: true, message: '请输入账号', trigger: 'blur'}],
        pwd: [{required: true, message: '请输入密码', trigger: 'blur'}],
        code: [
          {
            type: 'string',
            len: 4,
            message: '请输入4位验证码',
            required: true
          }
        ]
      },
      captchaUrl: ""
    }
  },
  computed: {
    ...mapState(["user", 'sysSetting'])
  },
  methods: {
    async getCaptcha() {
      this.formData.code = ''
      const {
        id,
        img
      } = await this.$api.get('/captcha')
      this.formData.captcha = img
      this.formData.captcha_id = id
    },
    goCasLogin() {
      if (this.url)
        window.location.href = this.url;
    },
    //
    onSubmit() {
      const self = this;
      self.$refs.form.validate((valid) => {
        console.log('valid:' + valid)
        if (valid) {
          Api.post(`/login`, this.formData).then(r => {
            this.$store.commit("user", r);
            window.localStorage.setItem("name", this.name);
            window.localStorage.setItem("token", r.token);
            window.localStorage.setItem("user", JSON.stringify(r));
            this.onSuccess("登录成功");
            this.show = false
          }).catch((err) => {
            console.log(err);
          }).finally(() => {
            this.getCaptcha()
          });
        } else {
          return false;
        }
      });
    }
  },
  mounted() {
    this.$bus.off('show-app-login-dialog')

    this.$bus.on('show-app-login-dialog', () => {
      if (window.self !== window.top) {
        window.parent.postMessage('show-app-login-dialog', '*');
        return
      }
      this.show = true
      this.getCaptcha()
      const appId = window.sessionStorage.getItem('app_address_id')
      if (appId) {
        Api.get('/site/info', {id: appId}).then(res => {
          this.ENV_DATA.SYSNAME = res.name
          this.ENV_DATA.LOGIN_LOGO = res.logo
          sessionStorage.setItem('app', JSON.stringify(res))
          if (res.color) {
            document.documentElement.style.setProperty('--app-primary-color', res.color)
            document.documentElement.style.setProperty('--app-primary-color-rgb', hexToRgb(res.color))
          }
        })
      } else {
        let regex = /^\b(([01]?\d?\d|2[0-4]\d|25[0-5])\.){3}([01]?\d?\d|2[0-4]\d|25[0-5])\b/;
        let domain = window.location.host;
        if (domain && !regex.test(domain) && !domain.startsWith("localhost")) {
          Api.get('/site/info', {domain}).then(res => {
            window.sessionStorage.setItem("app_address_id", res.id);
            window.sessionStorage.setItem(domain, res.id);
            this.ENV_DATA.SYSNAME = res.name
            this.ENV_DATA.LOGIN_LOGO = res.logo
            sessionStorage.setItem('app', JSON.stringify(res))
            if (res.color) {
              document.documentElement.style.setProperty('--app-primary-color', res.color)
              document.documentElement.style.setProperty('--app-primary-color-rgb', hexToRgb(res.color))
            }
          })
        }
      }
      this.ENV_DATA.VERSION = this.sysSetting.version || process.env.VUE_APP_VERSION
      Api.get(`/setting`, {}).then(r => {
        if (r.login_mode == "cas" && r.url) {
          this.url = r.url;
        } else {
          this.mode = 'normal';
          let name = window.localStorage.getItem("name");
          //let pwd = window.localStorage.getItem("pwd");
          if (name) {
            this.name = name;
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    })
  }
}
</script>

<style scoped lang="less">
.login-dialog {
  ::v-deep .el-dialog {
    background: transparent;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
</style>
