<template>
  <div class="search-list">

    <slot></slot>

    <el-pagination class="pagination" @current-change="onPageChange" @size-change="onSizeChange" :page-size.sync="size"
                   layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import * as Api from '@/api';

export default {
  data() {
    return {
      page: 0,
      size: this.defaultSize || 10,
      dataList: [],
      total: 0,
    };
  },
  props: ['url', 'searchData', 'title', 'manual', 'defaultSize'],
  mounted() {
    if (this.url && !this.manual) {
      this.load(this.searchData);
    }
  },
  watch: {
    size() {
      this.search();
    },
  },
  methods: {
    // 搜索
    search() {
      this.page = 0;
      if (this.url) {
        this.load(this.searchData);
      } else {
        this.$emit('search', this.searchData);
      }
    },

    // 最终调用加载
    load(params) {
      params = params || {};
      params.page = this.page >= 1 ? this.page - 1 : this.page;
      params.size = this.size;
      Api.page(this.url, params).then(r => {
        if (r.length) {
          this.dataList = r;
          this.total = r.length;
        } else {
          this.dataList = r.content;
          this.total = r.totalElements;
        }
        this.$emit('data', this.dataList);
      });
    },

    //
    onPageChange(page) {
      this.page = page;
      this.load(this.searchData);
    },
    onSizeChange(s) {
      this.size = s;
      this.search();
    },
    onTableRowClassName(row, index) {
      if (index === 1) {
        return 'info-row';
      } else if (index === 3) {
        return 'positive-row';
      }
      return '';
    },
  },
};
</script>


<style lang="less">
.search-list {
  .pagination {
    margin-top: 25px;
    width: 100%;
    text-align: center;
  }
}
</style>
