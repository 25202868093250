<script>

import reg from "@/api/register";

export default {
  // eslint-disable-next-line vue/require-prop-type-constructor
  props: {'template': '', 'v': {}, 'p': {}, 'app_id': 0, page: {},},
  data() {
    return {
      root: Object,
      templateRender: null,
    };
  },
  methods: {
    ...reg
  },
  render(h) {
    if (!this.templateRender) {
      return h('div', this.row.name);
    } else { // If there is a template, I'll show it
      return this.templateRender();
    }
  },
  watch: {

    // Every time the template prop changes, I recompile it to update the DOM
    template: {
      immediate: true, // makes the watcher fire on first render, too.
      handler() {
        try {
          const res = this.compile('<div style="height: 100%">' + this.template + '</div>');
          this.templateRender = res.render;
          this.$options.staticRenderFns = []
          this._staticTrees = []
          for (const i in res.staticRenderFns) {
            this.$options.staticRenderFns.push(res.staticRenderFns[i]);
          }
        } catch (e) {
          console.info(e)
        }
      }
    },
    page: {
      immediate: true,
      deep: true,
      handler() {
        try {
          const res = this.compile('<div style="height: 100%">' + this.template + '</div>');
          this.templateRender = res.render;
          this.$options.staticRenderFns = []
          this._staticTrees = []
          for (const i in res.staticRenderFns) {
            this.$options.staticRenderFns.push(res.staticRenderFns[i]);
          }
        } catch (e) {
          console.info(e)
        }
      }
    }
  },
};
</script>


