<template>
  <div class="build-widget">
    <widget-submit-form :view="view" ref="search" :size="size"
                        :params="view.app_params"/>
  </div>
</template>

<script>
import WidgetSubmitForm from "@/components/widget/form/submit.form";
import view_method from "@/api/view.method";

export default {
  data() {
    return {};
  },
  props: {
    size: {default: 'medium'},
    view: {},
  },
  watch: {},
  computed: {},
  methods: {
    ...view_method,
  },
  mounted() {
    this.$bus.on('execView-' + this.view.id, ({method, opt}) => {
      if (this[method]) {
        this[method](opt);
      }
    });
    this.$bus.on('onFormSearch-' + this.view.id, this.search)
  },
  beforeDestroy() {
    this.$bus.off('onFormSearch-' + this.view.id, this.search)
  },
  components: {
    WidgetSubmitForm
  }
};
</script>

<style lang="less">
.build-widget {
  background-color: #fff;


  .dnd-grid-box {
    overflow: hidden;
    padding: 2px;
  }
}
</style>
