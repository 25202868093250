import {SET_PAGE, GET_VIEW_BY_PAGE, REMOVE_VIEW, UPD_VIEW, ADD_VIEW} from '@/store/mutations-type'
import * as Api from "@/api";

const state = {
    page: {},
    list: [],
}

let f = (d, i) => {
    d.sort_num = i;
    d.config = JSON.stringify(d.config);
    return d;
};

let subExec = (view) => {
    if (view.app_cols && typeof view.app_cols != "string") {
        view.app_cols = JSON.stringify(view.app_cols.map(f));
    }

    if (view.app_params && typeof view.app_params != "string") {
        view.app_params = JSON.stringify(view.app_params.map(f));
    }
    if (!view.config) {
        if (view.type == 1) {
            view.config = {"tools": [], "auto": false, "page": true, "download": false};
        } else if (view.type == 5) {
            view.config = {preview: false, padding: 0, type: 1};

        } else if (view.type == 6) {
            view.config = {type: 1};

        } else if (view.type == 8) {
            view.config = {lines: 1, cols: 2, view_mark: 'layout_col', col_map: {}};
        } else if (view.type == 7) {
            view.config = {
                'content-position': 'center',
                text: ''
            };
        }
        if (view.type == 5 || view.type == 6) {
            if (view.content) {
                if (view.content.indexOf('/app_private/') >= 0) {
                    view.config.type = 2
                }
            }
        }
    }
    if (!view.layout) {
        view.layout = {"x": 0, "y": 0, "w": 24, "h": 20, i: Math.floor(Math.random() * 24), name: "temp"};
        if (view.type == 1) {
            view.layout.h = 30
            if (view.app_size) {
                if (view.app_size === 'medium') {
                    view.layout.h += 4
                } else if (view.app_size === 'small') {
                    view.layout.h += 2
                }
            }
        } else if (view.type == 7) {
            view.layout.h = 3
        }
    }

    if (view.config && typeof view.config != "string") {
        view.config = JSON.stringify(view.config);
    }

    if (view.layout && typeof view.layout != "string") {
        view.layout = JSON.stringify(view.layout);
    }

    return view;
}


let loadExec = (r) => {
    console.log("cols:", JSON.stringify(r.app_cols));
    if (!r.app_cols) {
        r.app_cols = [];
    }
    r.app_cols = r.app_cols.map(col => {
        col.config = JSON.parse(col.config || "{}");
        return col;
    });

    if (!r.app_params) {
        r.app_params = [];
    }
    r.app_params = r.app_params.map(param => {
        param.config = JSON.parse(param.config || "{}");
        return param;
    });

    if (r.config) {
        r.config = JSON.parse(r.config);
    } else {
        r.config = {};
    }

    r.config = Object.assign(
        {
            tools: [],
            auto: false,
            page: true,
            download: false
        },
        r.config
    );


    if (r.layout) {
        r.layout = JSON.parse(r.layout);
    } else {
        r.layout = {};
    }

    r.layout = Object.assign(
        {"x": 0, "y": 0, "w": 24, "h": 20, "i": '' + r.id, n: r.id_name},
        r.layout
    );

    console.log("loadExec:", JSON.stringify(r.config));

    return r;
}


const mutations = {
    [SET_PAGE](state, data) {
        state.page = data
    },
    [GET_VIEW_BY_PAGE](state, id) {
        let ret = null;
        if (state.page.views && state.page.views.length > 0) {
            for (let i = 0; i < state.page.views.length; i++) {
                let com = state.page.views[i];
                if (com.id === id) {
                    ret = com;
                    break;
                }
            }
        }
        return ret;
    },
    [REMOVE_VIEW](state, id) {
        console.log("REMOVE_VIEW com count:" + state.page.views.length)
        if (state.page.views && state.page.views.length > 0) {
            for (let i = 0; i < state.page.views.length; i++) {
                let com = state.page.views[i];
                if (com.id === id) {
                    state.page.views.splice(i, 1);
                    break;
                }
            }
        }
        console.log("REMOVE_VIEW com count:" + state.page.views.length)
    },
    [UPD_VIEW](state, view) {
        console.log("UPD_VIEW com count:" + state.page.views.length)
        if (state.page.views && state.page.views.length > 0) {
            for (let i = 0; i < state.page.views.length; i++) {
                let com = state.page.views[i];
                if (com.id === view.id) {
                    state.page.views[i] = view;
                    break;
                }
            }
        }
        console.log("UPD_VIEW com count:" + state.page.views.length)
    },
    [ADD_VIEW](state, com) {
        if (!state.page.views) {
            state.page.views = [];
        }
        state.page.views.unshift(com);
        console.log("com count:" + state.page.views.length)
    },
}

const actions = {

    async refreshViewByPage({commit, rootState}, id) {
        console.log("refreshViewByPage in mod_page  app_id:", rootState.app.id + "   id:", id);

        let r = await Api.post(`/manager/views/get`, {id: id, app_id: rootState.app.id, test: 'ok'});
        r = loadExec(r);
        commit(UPD_VIEW, r)
        return r;
    },

    //
    async simpleUpdate({rootState}, {id, model, prop}) {
        await Api.post(`/manager/common/update`, {
            id: id,
            app_id: rootState.app.id,
            model: model,
            props: JSON.stringify(prop)
        });
        return "ok"
    },

    async updViewByPage({commit, rootState}, view) {
        view.app_id = rootState.app.id;
        view.app_size = rootState.app.size;
        view = subExec(view);
        let r = await Api.post(`/manager/views/upd`, view);
        r = loadExec(r);
        commit(UPD_VIEW, r)
        return r;
    },
    async upViewLayout({commit, rootState}, view) {
        view.app_id = rootState.app.id;
        view.app_size = rootState.app.size;
        view = subExec(view);
        let r = await Api.post(`/manager/views/layout/upd`, view);
        r = loadExec(r);
        commit(UPD_VIEW, r)
        return r;
    },
    async delViewByPage({commit, rootState}, id) {
        await Api.post(`/manager/views/del`, {app_id: rootState.app.id, id: id});
        commit(REMOVE_VIEW, id)
        return "ok"
    },

    async addViewByPage({state, commit, rootState}, view) {
        view.app_id = rootState.app.id;
        view.page_id = state.page.id;
        view.app_size = rootState.app.size;
        view = subExec(view);
        let r = await Api.post(`/manager/views/add`, view);
        r = loadExec(r);
        commit(ADD_VIEW, r)
        return r;
    },

    async getPage({commit, rootState}, id) {
        let r = await Api.post(`/manager/page/get`, {app_id: rootState.app.id, id: id});

        commit(SET_PAGE, r)

        return r;
    },

    async delPage({commit, rootState}, id) {
        await Api.post(`/manager/page/del`, {app_id: rootState.app.id, id: id});
        commit(SET_PAGE, {})
    },

    async addPage({commit}, page) {

        if (page.app_params && typeof page.app_params != "string") {
            page.app_params = JSON.stringify(page.app_params);
        }

        if (page.config && typeof page.config != "string") {
            page.config = JSON.stringify(page.config);
        }

        let r = await Api.post(`/manager/page/add`, page);

        commit(SET_PAGE, r)
        return "ok";
    },
    async updPage({commit}, page) {

        if (page.app_params && typeof page.app_params != "string") {
            page.app_params = JSON.stringify(page.app_params);
        }

        if (page.config && typeof page.config != "string") {
            page.config = JSON.stringify(page.config);
        }
        let r = await Api.post(`/manager/page/upd`, page);

        commit(SET_PAGE, r)
        return "ok";
    }
}

export default {
    state,
    mutations,
    actions
}
