<template>
  <el-input icon="search" :placeholder="placeholder" @change="onChange">
    <!--<el-button v-if="isInput" slot="append" icon="search"></el-button>-->
  </el-input>
</template>

<script>
export default {
  data() {
    return {
      isInput: false,
    }
  },
  props: {
    placeholder: {},
  },
  methods: {
    onChange(v) {
      this.isInput = !!v;
      this.$emit('input', v);
    }
  }
}
</script>

<style scoped>
</style>
