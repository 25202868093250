import Vue from 'vue'
import VueI18n from 'vue-i18n'
import util from '@/libs/util'

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    for (const key of locales.keys()) {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            const localeElementUI = require(`element-ui/lib/locale/lang/${locales(key)._element}`)
            messages[locale] = {
                ...locales(key),
                ...localeElementUI ? localeElementUI.default : {}
            }
        }
    }
    return messages
}

const messages = loadLocaleMessages()
Vue.prototype.$t = function (key) {
    const values = []
    let len = arguments.length - 1
    while (len-- > 0) values[len] = arguments[len + 1]
    const i18n = this.$i18n
    const str = i18n._t.apply(i18n, [key, i18n.locale, i18n._getMessages(), this].concat(values))
    if (key === str && str.indexOf('.') !== -1) {
        const i = str.lastIndexOf('.')
        return str.slice(i + 1)
    }
    return str
}
Vue.prototype.$languages = Object.keys(messages).map(langlage => ({
    label: messages[langlage]._name,
    value: langlage
}))
let locale = util.cookies.get('lang') || process.env.VUE_APP_I18N_LOCALE
if (locale !== 'zh-chs' && locale !== 'en') {
    locale = 'zh-chs'
    util.cookies.set('lang', locale)
}
if (process.env.VUE_APP_PRODUCT_TYPE === 'COMMUNITY') {
    locale = 'zh-chs'
    util.cookies.set('lang', locale)
}
const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'zh-chs',
    messages,
    silentTranslationWarn: true
})

export const translate = (localeKey, data) => {
    const hasKey = i18n.te(localeKey, locale) // 使用i18n的 te 方法来检查是否能够匹配到对应键值
    const translatedStr = i18n.t(localeKey, data)
    if (hasKey) {
        return translatedStr
    }
    const i = localeKey.lastIndexOf('.')
    if (i !== -1) {
        return localeKey.slice(i + 1)
    }
    return localeKey
}

export default i18n
