<template>
  <div>
    <el-popover ref="popover1" placement="top-start" trigger="hover" :content="content">
    </el-popover>
    <span v-popover:popover1>
      <slot></slot>
    </span>
  </div>
</template>
<script>

export default {
  data() {
    return {}
  },
  computed: {},
  props: {
    content: {}
  },
  watch: {},
  methods: {},
  components: {},
  mounted() {
  },
}
</script>
<style>

</style>
