<template>
  <div class="widget-chart">
    <component v-if="data!=null" ref="chart_view" :theme-name="themeName" :colors="chartColors"
               :is="toChart(chartType)" :afterConfig="afterConfig" :extend="chartExtend"
               :data="chartData" :settings="chartSettingsMap" :width="width" :height="height" :title="title"
               :toolbox="toolbox"/>
  </div>
</template>
<script>
import * as Api from "@/api";
import * as _ from "lodash";
import reg from "@/api/register";
import moment from "moment";
import {
  VeLine,
  VeRadar,
  VeHistogram,
  VeScatter,
  VeMap,
  VeBar,
  VePie,
  VeRing,
  VeWaterfall,
  VeFunnel
} from 'v-charts/lib';


const typeMap = {
  "line": VeLine,
  "radar": VeRadar,
  "histogram": VeHistogram,
  "bar": VeBar,
  "pie": VePie,
  "ring": VeRing,
  "waterfall": VeWaterfall,
  "funnel": VeFunnel,
  "map": VeMap,
  "scatter": VeScatter,
};

const colors = {
  default: ["#5B8FF9", "#61DDAA", "#65789B", "#F6BD16", "#7262fd", "#78D3F8", "#9661BC", "#F6903D", "#008685", "#F08BB4"],
  dark: ['#dd6b66', '#759aa0', '#e69d87', '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab', '#91ca8c', '#f49f42'],
  vintage: ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'],
  infographic: [
    '#C1232B', '#27727B', '#FCCE10', '#E87C25', '#B5C334',
    '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD',
    '#D7504B', '#C6E579', '#F4E001', '#F0805A', '#26C0C0'
  ],
  macarons: [
    '#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80',
    '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa',
    '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050',
    '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'
  ],
  roma: ['#E01F54', '#001852', '#f5e8c8', '#b8d2c7', '#c6b38e',
    '#a4d8c2', '#f3d999', '#d3758f', '#dcc392', '#2e4783',
    '#82b6e9', '#ff6347', '#a092f1', '#0a915d', '#eaf889',
    '#6699FF', '#ff6666', '#3cb371', '#d5b158', '#38b6b6'
  ],
  shine: [
    '#c12e34', '#e6b600', '#0098d9', '#2b821d',
    '#005eaa', '#339ca8', '#cda819', '#32a487'
  ]
}


export default {
  data() {
    return {
      init: false,
      chartColors: colors['default'],
      themeName: 'default',
      dataZoom: {},
      dataEmpty: true,
      smooth: true,
      areaStyle: null,
      mockData: false,
      pageIndex: 0,
      page_size: this.defavultSize || 20,
      toolbox: {
        feature: {
          dataView: {show: false, readOnly: false},
          saveAsImage: {show: false}
        }
      },
      title: {
        show: false,
        text: '',
        subtext: '',
      },
      chartType: 'line',
      width: 'auto',
      height: '200px',
      theme: 'dark',
      chartData: {
        columns: [],
        rows: []
      },
      grid: {},
      chartExtend: {},
      chartSettings: {
        digit: 3,
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 0
          }
        },
        yAxis: {
          type: 'value'
        },

        labelMap: this.labelMap,
        dataType: this.dataType
      },
      dataType: {},
      labelMap: {},
      loading: false,
      dataList: [],
      view: {},

    };
  },
  computed: {
    chartSettingsMap() {
      const data = {...this.chartSettings}
      delete data.dataType
      return data
    },
  },
  props: {
    pro: {default: false},
    data: {},
    dev: {
      default: false
    }
  },
  watch: {},
  filters: {
    time(v, format) {
      return moment(v).format(format || "YYYY-MM-DD");
    }
  },
  methods: {
    ...reg,
    afterConfig(options) {
      options.series.forEach((s) => {
        s.smooth = this.smooth;
        s.areaStyle = this.areaStyle;
      });

      options.grid = this.grid;
      return options
    },
    toChart(type) {
      let t = typeMap[type];
      if (t) {
        return t;
      }

      return VeLine;
    },
    resetConfig() {
      this.dataEmpty = true;
      this.smooth = true;
      this.pageIndex = 0;
      this.page_size = 20;
      this.mockData = false;
      this.colMap = {};
      this.chartData.columns = [];
      this.chartData.rows = [];
      this.chartType = 'line';
      this.width = 'auto';
      this.height = '400px';
      this.themeName = 'default';
      this.grid = {};

      this.labelMap = {};
      this.dataType = {};
      this.title = {
        show: false,
        text: '',
        subtext: '',
      };

      this.tooltip = {
        show: false,
        saveAsImage: {show: false}
      };

      this.toolbox = {
        feature: {
          dataView: {show: false, readOnly: false},
          saveAsImage: {show: false}
        }
      };

      this.chartSettings = {
        width: 'auto',
        height: '400px',
//          yAxisType: ['percent'],
//          xAxisType: 'time',

        digit: 3,
        labelMap: this.labelMap,
        dataType: this.dataType,
        metrics: [],
        dimension: [],
      };

      console.log("this.chartType:", this.chartType);


      this.chartExtend = {
        xAxis: {
          axisLabel: {
            show: true,
            interval: 'auto',
            rotate: 0
          }
        },
        yAxis: {
          show: true,
          axisLabel: {
            show: true,
            interval: 'auto',
            rotate: 0
          }
        },
        series: {
          zoom: 0.1,
          barMaxWidth: 15
        }
      };


    },
    // 搜索
    refresh(r) {
      console.log("chart refresh");
      this.refreshView(r || this.data);
    },
    refreshView(r) {


      this.init = false;

      console.log("start refreshView ", this.data.id);

      this.resetConfig();

      if (r.style) {
        this.loadCss(r.style);
      }

      if (r && r.app_cols) {
        r.config = _.isString(r.config) ? JSON.parse(r.config || "{}") : r.config;
        const stack = {}
        r.app_cols = r.app_cols.map(col => {
          col.config = _.isString(col.config) ? JSON.parse(col.config || "{}") : col.config;
          this.labelMap[col.name] = col.label;
          if (col && col.config && col.config.stack) {
            stack[col.label] = col.config.stack
          }
          return this.warpCol(col);
        });
        this.chartSettings.stack = stack
        this.colMap = _.keyBy(r.app_cols, "name");


        if (r && r.config && r.config.page_size && r.config.page_size > 0) {
          this.page_size = r.config.page_size;

          console.log("page_size:", this.page_size, this.data.id);

          if (this.page_size > 500) {
            this.page_size = 500;
          }
        }

        if (r.config.width && r.config.width > 0) {
          this.width = r.config.width + "px";
        }

        if (r.config.height && r.config.height > 0) {
          this.height = r.config.height + "px";
        }

        if (r.config.chart_type) {

          this.chartType = r.config.chart_type;
        }

        // console.log("chartType:", r.config.chart_type);


        if (r.config.download) {
          this.toolbox.feature.saveAsImage.show = true;
        }
        if (r.config.dataview) {
          this.toolbox.feature.dataView.show = true;
        }

        if (r.config.title) {
          this.title.text = r.config.title;
          if (r.config.sub_title) {
            this.title.subtext = r.config.sub_title;
          }
          this.title.show = true;
        }

        //排序
        r.app_cols = _.orderBy(r.app_cols, ["sort_num"], ["asc"])


        if (r && r.app_data && r.app_data.data_type === 3) {
          this.mockData = true;
        }

        let metrics = [];//指数,
        let dimension = [];//维度


        if (this.mockData) {
          dimension = ['category'];
          metrics = ['value']
          this.chartData.columns = ['category', 'value'];
        } else {
          //
          let columns = [];
          for (let col of r.app_cols) {
            if (col.enable == 1) {
              columns.push(col.name);
            }
          }
          this.chartData.columns = columns;

          //预留以后支持多维度
          for (let i = 0; i < columns.length; i++) {
            if (i == 0) {
              dimension.push(columns[i]);
            } else {
              metrics.push(columns[i]);
            }
          }
        }


        this.view = r;
        this.chartSettings.dimension = dimension;
        this.chartSettings.metrics = metrics;


        if (r.config.chart_theme) {
          this.themeName = r.config.chart_theme;
          this.chartColors = colors[this.themeName];
        }


        console.log("xxx config:", JSON.stringify(r.config));

        //x坐标配置是否显示
        if (r.config.chart_xaxis_show) {
          this.chartExtend.xAxis.axisLabel.show = false;
        }

        //文字水平样式
        if (r.config.chart_xaxis_rotate) {
          this.chartExtend.xAxis.axisLabel.rotate = ~~r.config.chart_xaxis_rotate;

//            if (r.config.chart_xaxis_rotate == "45") {
//              this.grid.bottom = "30%";
//            } else if (r.config.chart_xaxis_rotate == "90") {
//              this.grid.bottom = "40%";
//            }


//            this.chartExtend.xAxis.axisLabel.formatter =  function (value) {
//              //x轴的文字改为竖版显示
//              var str = value.split("");
//              return str.join("\n");
//            };

        }


        //图形边距
        if (r.config.chart_grid_left) {
          this.grid.left = ~~r.config.chart_grid_left;
        }

        if (r.config.chart_grid_bottom) {
          this.grid.bottom = ~~r.config.chart_grid_bottom;
        }

        if (r.config.chart_grid_top) {
          this.grid.top = ~~r.config.chart_grid_top;
        }

        if (r.config.chart_grid_right) {
          this.grid.right = ~~r.config.chart_grid_right;
        }

        if (r.config.chart_yaxis_interval) {
          if (r.config.chart_yaxis_interval != 'auto') {
            this.chartExtend.yAxis.axisLabel.interval = ~~r.config.chart_yaxis_interval;
          }
        }

        //间隔设定默认auto
        if (r.config.chart_xaxis_interval) {
          if (r.config.chart_xaxis_interval != 'auto') {
            this.chartExtend.xAxis.axisLabel.interval = ~~r.config.chart_xaxis_interval;
          }
        }

        //字体粗细
        if (r.config.chart_xaxis_fontweight) {
          this.chartExtend.xAxis.axisLabel.fontWeight = r.config.chart_xaxis_fontweight;
        }

//          //x坐标轴类型
        if (r.config.chart_xaxis_type) {
          this.chartExtend.xAxis.type = r.config.chart_xaxis_type;
        }


        if (r.config.chart_yaxis_show) {
          this.chartExtend.yAxis.show = false;
        }

        if (r.config.chart_yaxis_type) {
          this.chartExtend.yAxis.type = r.config.chart_yaxis_type;
        }


        console.log("xxx chartExtend:", JSON.stringify(this.chartExtend));


        if (this.chartType == 'line') {
          if (r.config.chart_no_smooth) {
            this.smooth = false;
          }
          if (r.config.chart_area_style) {
            this.areaStyle = {};
          }

          if (r.config.chart_magic) {
            this.toolbox.feature.magicType = {type: ['line', 'bar']};
          }
        } else if (this.chartType == 'pie' || this.chartType == 'ring') {
          if (r.config.chart_rose_type) {
            this.chartSettings.roseType = 'radius';
          }

          if (r.config.chart_num_limit && r.config.chart_num_limit > 0) {
            this.chartSettings.limitShowNum = r.config.chart_num_limit;
          }

        } else if (this.chartType == 'bar') {
          if (r.config.chart_order && (r.config.chart_order == 'asc' || r.config.chart_order == 'desc')) {

            let dataOrder = {
              label: this.chartSettings.metrics[0],
              order: r.config.chart_order
            };

            this.chartSettings.dataOrder = dataOrder;

            console.log("==========dataorder========");
          }


          if (r.config.chart_num_limit && r.config.chart_num_limit > 0) {
            this.chartSettings.limitShowNum = r.config.chart_num_limit;
          }

        } else if (this.chartType == 'map') {
          if (r.config.chart_map_position) {
            this.chartSettings.position = r.config.chart_map_position;
          } else {
            this.chartSettings.position = 'china';
          }


          this.chartExtend = {
            series: {
              zoom: 1,
              barMaxWidth: 15
            }
          };
        }
      }

      if (this.$refs['chart_view']) {
        this.$refs['chart_view'].resize();
      }


      console.log("end refreshView ", this.data.id);
      console.log(this.chartSettings)
      this.init = true;

    },
    wrapData(data) {
      //console.log(" data :", JSON.stringify(data));

      for (let key in data) {
        let col = this.colMap[key];
        if (!col) {
          continue;
        }
        //console.log(" key :", key);

        if (col.transFun) {
          //console.log(" tttt :", col.transFun(this.getRoot(), data));
          data[key] = col.transFun(this.getRoot(), data);
        }
      }
      return data;
    },
    warpCol(col) {

      try {
        if (col.trans) {
          col.transFun = new Function("_self", "v", col.trans);
        }

      } catch (e) {
        console.error(`报错了 => `, col, e);
      }
      return col;
    },

    autoSearch() {
//        if (!this.view.config.timer || this.dev) {
//          return;
//        }
//        curTimer = setInterval(() => {
//          this.search();
//        }, this.view.config.timer * 1000);
    },
    search(searchData) {
      this.pageIndex = 0;
      return this.load(searchData);
    },

    // 最终调用加载
    load(params) {
      //this.loading = true;


      //确保在渲染后再执行查询
      if (!this.init) {
        this.refreshView(this.data);
      }

      params = params || {};

      console.log("load page_size:", this.page_size, this.data.id);

      params = this.execBefore(this.view, params);


      return Api.post(`/app/data/run`, {
        app_id: this.data.app_id,
        data_id: this.data.data_id,
        form_id: this.data.query_id,
        has_total: 1,
        data_params: JSON.stringify(params),
        page_no: 0,
        page_size: this.page_size
      })
          .then(r => {
            if (r && r.success && r.response && r.response.ok && r.response.data) {
              try {
                this.execCallback2(this.view, r.response.data, params, ({count, ok, msg, list}) => {
                  if (ok) {
                    this.dataList = list.map(this.wrapData);
                    this.total = count || list.length;
                    this.makeChart(this.dataList);
                    this.$store.commit('updateAllViewData', {
                      list: this.dataList,
                      form: {...params},
                      total: this.total,
                      page_no: 0,
                      page_size: this.page_size,
                      view_name: this.data.name
                    })
                  } else {
                    this.showFail(msg)
                  }
                })
              } catch (e) {
                this.showFail(e)
              }
            } else {
              this.showFail(r.msg)
            }
          })
          .finally(() => {
            //this.loading = false;
          });
    },
    makeChart(dataList) {
      let rows = [];
      if (rows.length > 0) {
        this.dataEmpty = false;
      }
      if (!this.data.config.chart_type || this.data.config.chart_type === 'line' || this.data.config.chart_type === 'histogram' || this.data.config.chart_type === 'bar') {
        const columns = []
        const clearColumns = []
        const firstCol = this.chartData.columns[0]
        dataList.forEach(data => {
          let row = {};
          for (let col of this.chartData.columns) {
            const colMap = this.data.app_cols.find(e => e.name === col) || {}
            if (colMap && colMap.config && typeof colMap.config === 'string') {
              try {
                colMap.config = JSON.parse(colMap.config);
              } catch (e) {
                console.log(e)
              }
            }
            if (firstCol !== col && colMap && colMap.config && colMap.config.showNum) {
              if (columns.indexOf(data[col]) === -1) {
                columns.push(data[col])
              }
              if (clearColumns.indexOf(col) === -1) {
                clearColumns.push(col)
              }
              row[data[col]] = data[colMap.config.showNum]
            } else {
              row[col] = data[col];
            }
          }
          rows.push(row);
        });
        const dimensions = []
        rows.forEach(e => {
          if (dimensions.indexOf(e[firstCol]) === -1) {
            dimensions.push(e[firstCol])
          }
        })
        const data = []
        for (let i = 0; i < dimensions.length; i++) {
          const r = rows.filter(e => e[firstCol] === dimensions[i])
          const d = Object.assign(...r)
          data.push(d)
        }
        if (columns && columns.length > 0) {
          const metrics = [...this.chartSettings.metrics]
          for (let i = 0; i < columns.length; i++) {
            if (metrics.indexOf(columns[i]) === -1) {
              metrics.push(columns[i]);
            }
          }
          for (let i = 0; i < clearColumns.length; i++) {
            const index = metrics.indexOf(clearColumns[i])
            if (index !== -1) {
              metrics.splice(index, 1)
            }
          }
          this.chartSettings.metrics = metrics;
        }
        this.chartData.rows = data;
      } else {
        dataList.forEach(data => {
          let row = {};
          for (let col of this.chartData.columns) {
            row[col] = data[col];
          }
          rows.push(row);

        });
        this.chartData.rows = rows;
      }
      //重新改变大小
      if (this.$refs['chart_view']) {
        console.log("resize~~~~~~~~~~~~");
        //console.log("resize~~~~~~~~~~~~",this.$refs['chart_view'].getWidth(),this.$refs['chart_view'].getHeight());
        this.$refs['chart_view'].resize({"width": "auto", "height": "auto"});
      }
    }
  },
  components: {},
  mounted() {
    this.$nextTick(() => {
      this.refreshView(this.data);
      this.$bus.on('onFormSearch-' + this.data.id, this.search)
    });
  },
  beforeDestroy() {
    this.$bus.off('onFormSearch-' + this.data.id, this.search)
  }

};
</script>
<style lang="less">
.widget-chart {
  padding: 20px 25px 10px 25px;
  border: 1px solid #eee;
  box-sizing: border-box;
  background: #ffffff;

  .widget-tools {
    margin-bottom: 20px;
  }

  .chart {
  }

}
</style>
