<template>
  <div class="view-widget search-form">
    <el-form :size="size"
             :model="search"
             ref="form"
             :rules="searchRules"
             :inline="true">
      <template v-for="request in params">
        <el-form-item :size="size"
                      :key="request.name"
                      :prop="request.name"
                      v-if="request.enable == 1&&request.form_type!=11">
          <span slot="label" style="word-break:break-all;">
            {{ request.label }}
          </span>
          <el-input v-if="request.form_type == 1"
                    v-model="search[request.name]" :size="size"></el-input>
          <el-select v-else-if="request.form_type == 2" clearable :popper-append-to-body="false"
                     v-model="search[request.name]" :size="size">
            <el-option v-for="opt in request.data_option"
                       :key="opt.k"
                       :label="opt.v"
                       :value="opt.k"></el-option>
          </el-select>
          <template v-else-if="request.form_type == 18">
            <el-cascader v-if="request.dict_type===1"
                         :key="request.id"
                         ref="cascader"
                         clearable
                         :props="{label:'v',value:'k',children:'subs'}"
                         v-model="search[request.name]" :popper-append-to-body="false"
                         :options="request.data_option"></el-cascader>
            <el-cascader v-if="request.dict_type===2"
                         :key="request.name+'-dict'"
                         :ref="request.name+'-dict'"
                         clearable
                         :props="{label:'name',value:'code',lazy:true,lazyLoad:getDictNodes}"
                         v-model="search[request.name]" :popper-append-to-body="false"
                         :options="request.data_option"></el-cascader>
          </template>

          <el-date-picker v-else-if="request.form_type == 3" :size="size"
                          v-model="search[request.name]" :popper-append-to-body="false"
                          @change="v => onDatePicker(v, request)"
          ></el-date-picker>


          <el-checkbox-group v-else-if="request.form_type == 4 " v-model="search[request.name]"
          >
            <template v-for="opt in request.data_option">
              <el-checkbox v-if="request.data_option" :key="opt.k" :size="size"
                           :true-label="opt.k">{{ opt.v }}
              </el-checkbox>
            </template>

          </el-checkbox-group>


          <el-checkbox-group v-else-if="request.form_type == 7"
                             v-model="search[request.name]">
            <template v-for="opt in request.data_option">
              <el-checkbox :key="opt.k" v-if="request.data_option" :size="size"
                           :label="opt.k">{{ opt.v }}
              </el-checkbox>
            </template>

          </el-checkbox-group>
          <el-date-picker type="datetimerange" :size="size"
                          :unlink-panels="true"
                          v-model="search[request.name]"
                          v-else-if="request.form_type == 6"
                          :picker-options="pickerOptions"
                          range-separator="至"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间"
                          :popper-append-to-body="false"
                          @change="v => onDateRangePicker(v, request)"
                          align="right"
          >
          </el-date-picker>


          <el-input v-if="request.form_type == 15"
                    v-model="search[request.name]" :size="size">
            <template slot="append">
              <template v-for="btn in request.app_buttons">
                <app-button :btn="btn" :size="size" :v="{}" v-if="request.form_type == 15&&request.app_buttons"
                            @click="appButtonClick" :key="btn.id">
                </app-button>
              </template>
            </template>
          </el-input>


        </el-form-item>
      </template>

      <el-form-item :size="size">
        <el-button type="primary"
                   @click="onSearch" :size="size">查询
        </el-button>
        <el-button type="info" :size="size" v-if="resetBtn"
                   @click="onReset">重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import * as _ from "lodash";
import dateTime from "@/api/date_time";
import {dataConfigMap, transformTree, listToTree, setNullForEmptyChildren} from "@/utils/tool";
import reg from "@/api/register";

const shortcuts = dateTime.shortcuts;

export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: shortcuts
      },
      resetBtn: true,
      curTimer: null,
      //date_range: [],

      date_map: {},
      arr_map: {},
      date_val: {},
      query_ids: [],
      search: {},
      type: {},
      extSearch: {},
      hideSearch: {},
      searchRules: {},
      timeMap: {},
    };
  },
  computed: {},
  props: {
    size: {
      default:
          'medium'
    }
    ,
    pro: {
      default:
          false
    }
    ,
    view: {}
    ,
    params: {
      default
          () {
        return [];
      }
    },
    dev: {
      default: false
    }
  },
  watch: {
    params: () => {
      console.log("search config params change ....");
    },
    search: {
      immediate: true,
      deep: true,
      handler(v) {
        this.$store.commit('updateAllViewData', {
          list: [],
          form: {...v},
          total: 0,
          page_no: 0,
          page_size: 0,
          view_name: this.view.name
        })
      }
    }
    // view: {
    //   deep: true,
    //   handler() {
    //     console.log('view', cloneDeep(this.view))
    //     this.refresh()
    //   }
    // }
  },
  methods: {
    ...reg,
    getDateTimePickerOption() {
      return this.pickerOptions
    }
    ,

    refresh() {
      clearInterval(this.curTimer);
      this.timeMap = {};
      this.arr_map = {}
      this.resetBtn = true;
      let search = {};
      let searchRules = {};
      if (this.params) {
        console.log("params:" + this.params.length);
        for (let request of this.params) {
          this.onParamConfig(request);
          //记录类型
          if (request.query_type === 2) {
            this.type[request.name] = "number";
          } else if (request.query_type === 3) {
            this.type[request.name] = "bool";
          }

          if (request.form_type == 7) {
            search[request.name] = [];
          }

          if (request.enable == 1 && request.form_type != 11 && request.form_type != 7) {
            if (request.def_val) {
              console.log("def_val:", request.def_val, request.form_type, request.name);
              //时间范围
              if (request.form_type == 6) {
                var range = dateTime.timeRange(request.def_val);
                if (range && range.length > 1) {
                  search[request.name] = range;
                  this.extSearch[request.config.endField] = dateTime.utc2date(range[1]);
                  this.extSearch[request.name] = dateTime.utc2date(range[0]);
                  this.timeMap[request.name] = {
                    def: request.def_val,
                    end: request.config.endField
                  }
                }
              } else {
                search[request.name] = request.def_val;
              }
            } else {
              search[request.name] = "";
            }
            searchRules[request.name] = [];
            // 表单验证
            if (request.must && request.enable) {
              searchRules[request.name] = [{required: true, message: '不能为空', trigger: 'blur'}];
            }

          } else if (request.enable == 1 && request.form_type == 11 && request.def_val) {
            //hide 变量
            this.hideSearch[request.name] = request.def_val;
          } else if (request.enable == 1 && request.form_type == 7 && request.def_val) {
            //多选
            //search[request.name] = [request.def_val];
          } else if (request.form_type == 15) {
            search.app_buttons = request.app_buttons ? request.app_buttons : [];
          }
        }
      }
      this.search = search;
      this.searchRules = searchRules;
      console.log(this.searchRules)
      if (this.view) {
        this.query_ids = [this.view.query_id];
        if (this.view.type == 2) {
          //表单类型,获取关联的其他视图id
          this.query_ids = this.view.tables || [];
        }

        let r = this.view;

        if (r.style) {
          this.loadCss(r.style);
        }

        if (this.view.app_params) {
          for (let request of this.view.app_params) {
            console.log("request:", request);
            if (request.form_type == 6) {
              this.date_map[request.name] = 1;
            }
          }
        }


        r.config = _.isString(r.config) ? JSON.parse(r.config || "{}") : r.config;


        if (r.config && r.config.no_reset_btn) {
          this.resetBtn = false;
        }

        console.log("search form config:", JSON.stringify(r.config));
        if (r.config.once) {
          this.query();
        }
        if (r.config.timer) {
          if (r.config.timer && !this.dev) {
            this.curTimer = setInterval(() => {
              console.log("=============", r.config.timer * 1000);
              this.query()
            }, r.config.timer * 1000)
          }
          if (!r.config.once) {
            this.query();
          }
        }
      }
    }
    ,
    refreshParams(params) {
      params = params || [];
      let search = {};
      this.timeMap = {};

      let searchRules = {};
      for (let request of params) {
        this.onParamConfig(request);
        search[request.name] = "";
        searchRules[request.name] = [];
      }

      this.params = params;
      this.search = search;
      this.searchRules = searchRules;
    }
    ,
    setData(data) {
      this.setParams(data);
    },
    setParams(new_params) {
      console.log("new_params:", JSON.stringify(new_params));
      //let search = Object.assign({}, this.search, params);
      if (new_params) {
        for (let name in new_params) {
          console.log("n:" + name);
          if (new_params[name]) {
            this.search[name] = new_params[name];
            if (this.extSearch[name]) {
              this.extSearch[name] = new_params[name];
              if (name == 'start') {
                if (this.extSearch[name].length == 0) {
                  this.extSearch[name] = [new_params[name], ''];
                } else {
                  this.extSearch[name][0] = new_params[name];
                }
              } else if (name == 'end') {
                if (this.extSearch[name].length == 0) {
                  this.extSearch[name] = ['', new_params[name]];
                } else {
                  this.extSearch[name] = [this.extSearch[name][0], new_params[name]]
                }
              }
            }
          }
        }
      }
    },
    getDictNodes(node, resolve) {
      if (node.level === 0) {
        return
      }
      if (node.data.child_dict && node.data.child_dict.length >= 1) {
        let data = node.data.child_dict[0]
        data.app_id = node.data.app_id
        data.param_id = node.data.param_id
        this.getApiSubs(data, resolve, node)
      } else {
        return resolve([])
      }
    },
    getApiSubs(data, resolve, node) {
      data = dataConfigMap(_.cloneDeep(data), 'config', {name: '', code: '', p_col: ''})
      data = dataConfigMap(_.cloneDeep(data), 'app_data', {})
      let params = null
      if (data.app_data.db_type === 'http') {
        if (node) {
          params = {...node.data}
          delete params.child_dict
          delete params.children
        }
      } else {
        if (node && data.config.p_col) {
          params = {}
          params[data.config.p_col] = node.data.code
        }
      }
      if (data.app_id) {
        this.$api.post('/app/param/data/run', {
          app_id: data.app_id,
          param_id: data.param_id,
          dict_id: data.id,
          page_no: -1,
          page_size: -1,
          data_params: params ? JSON.stringify(params) : null
        }).then(res => {
          if (data.app_data.db_type === 'http') {
            if (data.app_data.call_back_config) {
              if (res && res.success && res.response && res.response.ok && res.response.data) {
                try {
                  let response = res.response.data.list;
                  let cbFun = new Function('v', data.app_data.call_back_config);
                  const {ok, msg, list} = cbFun(response);
                  if (ok) {
                    list.forEach(e => {
                      e.child_dict = data.child_dict
                      e.app_id = data.app_id
                      e.param_id = data.param_id
                      e.children = []
                      if ((!data.child_dict) || data.child_dict.length === 0) {
                        e.leaf = true
                      }
                    })
                    return resolve(list)
                  } else {
                    this.$message.warning(msg)
                    return resolve([])
                  }

                } catch (e) {
                  return resolve([])
                }
              } else {
                return resolve([])
              }
            } else {
              return resolve(res?.response?.data?.list || [])
            }
          } else {
            let list = _.get(res, 'response.data.list', [])
            list = list.map(e => {
              e.name = e[data.config.name || 'name']
              e.code = e[data.config.code || 'id']
              e.child_dict = data.child_dict
              e.app_id = data.app_id
              e.param_id = data.param_id
              e.children = []
              if ((!data.child_dict) || data.child_dict.length === 0) {
                e.leaf = true
              }
              return e
            })
            return resolve(list)
          }
        })
      }
    },
    onParamConfig(request) {
      if (typeof request.config === 'string') {
        request.config = JSON.parse(request.config || "{}");
      }
      let oldData = _.cloneDeep(request)
      oldData = dataConfigMap(oldData, 'data_config')
      oldData = dataConfigMap(oldData, 'input_config', {framework: 2, delimiter: '/'})
      if (oldData.form_type == 2 || oldData.form_type == 4 || oldData.form_type == 18 || oldData.form_type == 7) {
        if (oldData.form_type == 18) {
          this.arr_map[request.name] = {
            delimiter: oldData.input_config.delimiter || '/',
            stockpile: oldData.input_config.stockpile || 'code'
          };
        }
        if (oldData.data_type === 2) {
          this.$api.post('/app/param/dict', {
            id: oldData.id,
            app_id: oldData.app_id
          }).then((res) => {
            console.log(res)
            // k是值 v是label
            const {sub_dict = [], dict_type = 1} = res
            this.$set(request, 'dict_type', dict_type)
            if (dict_type === 1) {
              const tree = transformTree(_.cloneDeep(sub_dict || []), [
                {oldKey: 'name', newKey: 'v'},
                {oldKey: 'code', newKey: 'k'}
              ], 'subs')
              this.$set(request, 'data_option', tree)
            } else if (dict_type === 2) {
              this.getApiSubs({...res, app_id: oldData.app_id, param_id: oldData.id}, (list) => {

                list.forEach(e => {
                  if (oldData.input_config.framework === 2) e.leaf = true
                  e.v = e.name
                  e.k = e.code
                })
                if (list.length >= 1 && oldData.form_type == 18) {
                  for (let i = list.length - 1; i > list.length - 3; i--) {
                    const node = list[i]
                    if (node.child_dict && node.child_dict.length >= 1) {
                      let data = node.child_dict[0]
                      data.app_id = node.app_id
                      data.param_id = node.param_id
                      this.getApiSubs(data, (l = []) => {
                        node.children = [...l]
                      }, {data: node})
                    }
                  }
                }
                this.$set(request, 'data_option', list)
              })
            }
          })
        } else {
          let jsonVal = oldData.data_config;
          //兼容以前的数据
          if (_.isArray(jsonVal)) {
            request.data_option = jsonVal;
          } else {
            let list = [];
            for (let k in jsonVal) {
              list.push({p: 0, k: k, v: jsonVal[k]});
            }
            request.data_option = [...list]
          }
          if (oldData.input_config.framework === 1) {
            request.data_option = listToTree(_.cloneDeep(request.data_option), 'k', 'p', 'subs')
            request.data_option.forEach(e => {
              setNullForEmptyChildren(e, 'subs')
            })
          }
        }
      }
    }
    ,

    query(params) {
      console.log("#########query########", JSON.stringify(params));

      if (params) {
        this.setParams(params);
      }

      this.$refs.form.validate(valid => {
        //         console.log("search valid:", valid);
        if (!valid) {
          return false;
        }
        let search = _.cloneDeep(this.search)
        //只有自动刷新才获取
        if (this.curTimer) {
          //定时刷新,保存相对时间
          for (let start in this.timeMap) {
            let data = this.timeMap[start];
            let def = data.def;
            let end = data.end;
            var range = dateTime.timeRange(def);
            this.extSearch[end] = dateTime.utc2date(range[1]);
            this.extSearch[start] = dateTime.utc2date(range[0]);
          }
        }
        for (let key in search) {
          if (this.date_map[key]) {
            let tempDate = search[key];
            if (tempDate instanceof Array && tempDate.length > 1) {
              tempDate[0] = dateTime.utc2date(tempDate[0]);
              tempDate[1] = dateTime.utc2date(tempDate[1]);
              search[key] = tempDate;
            }

          } else if (this.arr_map[key]) {
            let tempDate = search[key];
            if (tempDate instanceof Array) {
              if (this.arr_map[key].stockpile === 'name') {
                let cascader
                if (Array.isArray(this.$refs[key + '-dict'])) {
                  cascader = this.$refs[key + '-dict'][0]
                } else {
                  cascader = this.$refs[key + '-dict']
                }
                if (cascader) {
                  const node = cascader.getCheckedNodes()[0]
                  if (node) {
                    search[key] = node.pathLabels.join(this.arr_map[key].delimiter)
                  }
                }
              } else {
                search[key] = search[key].join(this.arr_map[key].delimiter);
              }
            }
          }
        }


        search = Object.assign({}, search, this.extSearch);

//          //类型转换
        for (let key in search) {
          let val = search[key];
          if (val && this.type[key] && this.type[key] == "number") {
            search[key] = ~~val;
          }
        }


        search = Object.assign({}, search, this.hideSearch);
        if (this.query_ids && this.query_ids.length > 0) {
          this.$nextTick(() => {
            this.$emit("search", {search: search, form_id: this.view.id, query_ids: this.query_ids});
          })
        }

      });
    }
    ,
    //
    onSearch() {

      this.query();
    }
    ,
    onReset() {
      this.$refs.form.resetFields();
      //this.date_range = {};
      this.extSearch = {};
    }
    ,
    onDatePicker(v, col) {
      console.log("onDatePicker v:", v);
      if (v) {
        this.extSearch[col.name] = dateTime.utc2date(v);

      }
    },
    onDateRangePicker(v, col) {
      if (v) {
        //this.date_range = v;
        this.extSearch[col.config.endField] = dateTime.utc2date(v[1]);
        this.extSearch[col.name] = dateTime.utc2date(v[0]);
      } else {
        // this.date_range = [];
        this.extSearch[col.config.endField] = '';
        this.extSearch[col.name] = '';
      }
    }
  },
  components: {},
  mounted() {
    this.$nextTick(() => {
      this.refresh();
    });
  }
  ,
  beforeDestroy() {
    clearInterval(this.curTimer);
  }
}
;
</script>
<style lang="less">

.search-form {
  padding: 25px 35px;
  border: 1px solid #eee;
  box-sizing: border-box;
  background: #ffffff;
}

/*.el-button--medium {*/
/*font-size: 8px;*/
/*}*/

/*.el-form-item__label {*/
/*font-size: 8px;*/
/*}*/

/*.el-form-item__content {*/
/*height: 30px;*/
/*line-height: 30px;*/
/*}*/

/*.el-range-editor--medium.el-input__inner {*/
/*height: 30px;*/
/*}*/

/*.el-input--medium .el-input__inner {*/
/*height: 30px;*/
/*line-height: 30px;*/
/*}*/

.hide {
  background-color: #909399;
}

.search-form {
  padding: 15px 10px 5px 15px;
}
</style>
