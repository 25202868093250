import {SET_PAGE} from '@/store/mutations-type'


const state = {
    go_page: {},
}


const mutations = {
    [SET_PAGE](state, page) {
        state.go_page = page
    },
}


const actions = {
    async setGoPage({commit}, page) {
        console.log("vuex setGoPage:", JSON.stringify(page));
        commit(SET_PAGE, page)
    }
}

export default {
    state,
    mutations,
    actions
}
