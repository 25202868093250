import * as Api from "@/api";

//系统全局函数,用户组件交互
export default {

    showFail(msg) {
        this.$message({
            showClose: true,
            duration: 2000,
            type: "warning",
            message: msg || "调用失败!"
        });
    },
    showOk(msg) {
        this.$message({
            showClose: true,
            duration: 2000,
            type: "success",
            message: msg
        });
    },


    confirm(msg, ok, fail) {
        if (!ok) {
            ok = () => {
            };
        }

        if (!fail) {
            fail = () => {
            };
        }
        console.dir(ok)
        if (!msg)
            return;
        this.$confirm(msg).then(ok, fail);
    },

    //打开下载弹窗
    openDownload(id, params, sorts, source) {
        this.$refs.downloadDialog.show(this.appId, id, params, sorts, source);
    },


    openPage(id, mode, opt, source) {
        console.log("sys openPage:", id, mode, opt, source);
        if (!opt) {
            opt = {};
        }

        if (!mode) {
            mode = 'dialog';
        }

        console.log("window:" + mode);
        //console.dir(this.$store);


        if (mode == 'dialog') {
            if (this.$refs.pageDialog) this.$refs.pageDialog.show(this.appId, id, opt, source);
        } else if (mode == 'new') {
            let routeData = this.$router.resolve({name: "page", params: {appId: this.appId, pageId: id}, query: opt});
            window.open(routeData.href, '_blank');
        } else if (mode == 'tab') {
            if (window.parent && window.parent.app && window.parent.app.handleSelect) {
                console.log("go page url parent");
                window.parent.app.handleSelect(id, '', {opt});
            } else {
                let routeData = this.$router.resolve({
                    name: "page",
                    params: {appId: this.appId, pageId: id},
                    query: opt
                });
                window.open(routeData.href, '_blank');
            }
        } else if (mode == 'self') {
            let url = this.$route.path;
            url = "/" + this.appId + "/" + id;
            console.log("go page url:", url);
            this.$router.push({path: url, query: opt});
        }

    },
    openUrl(url) {
        console.log(arguments);
        console.log("url:", url);
        window.open(url);
    },

    async hasPermissionAction(action_id) {
        let r = await Api.post('/app/has/permission', {
            app_id: this.app.id,
            source_type: 'action',
            source_id: action_id
        });
        return r;
    },


    // doButton(btn) {
    //   console.log("doButton:", JSON.stringify(btn));
    // },
    async doAction(id, opt, source) {
        console.log("doAction:", JSON.stringify(opt));
        console.log("source:", JSON.stringify(source));

        let r = "";
        let param = opt.param ? opt.param : {};
        try {
            // r=this.hasPermissionAction(id);
            // if(r==true){
            let url = `/app/action/run`;
            let query = {
                action_id: id,
                app_id: this.app.id,
                button_id: source.id,
                data_params: JSON.stringify(param)
            };
            if (source && source.id) {
                url = `/app/button/run`;
                query.button_id = source.id;
            }

            r = await Api.post(url, query).then((r) => {
                    if (r && r.success) {
                        console.log("then:", JSON.stringify(opt));

                        var response = r.response;
                        if (r.callback) {
                            console.log("callback:", r.callback);
                            try {
                                let cbFun = new Function("_self", "v", r.callback);
                                response = cbFun(this.getRoot(), response);
                            } catch (e) {
                                console.error(e);
                            }
                        }

                        if (response && response.ok) {
                            this.showOk("执行成功!");
                            //回调方法
                            if (source && source.config) {
                                //刷新打开的表格
                                if (source.config.reloadParentView) {
                                    this.windowCallBack("reloadParentView");
                                }

                                if (source.config.close) {
                                    this.closeSelfWindow();
                                }
                                console.log("call_back_id:", source.call_back_id);
                                console.log("reload:", source.config.reload);
                                if (source.config.reload && source.call_back_id) {
                                    this.reLoadView(source.call_back_id);
                                }
                            }
                        } else {
                            this.showFail(response.msg)
                        }

                    } else {
                        this.showFail(r.msg)
                    }
                }
            )
            // this.onSuccess(JSON.stringify(r));
            // }
        } catch (err) {
            console.log("doAction err:", err);
            r = "err";
        }
        return r;
    },
    //关闭弹窗(父页面调用)
    closeWindow() {
        this.$emit("close");
    },
    //关闭弹窗(弹窗调用)
    closeSelfWindow() {
        //
        setTimeout(() => {
            this.$emit("dialogCallback", {act: "closeSelf"});
        }, 1500)
    },

    //page组件通过windowCallBack 调用 dialog 对象
    // dialog(弹窗)回调emit callback==>openCallback 在父窗口page执行最终的callback
    windowCallBack(act, opt) {
        //先调用到dialogCallback,然后在dialogCallback emit  page的callback
        this.$emit("dialogCallback", {act: act, opt: opt});
    },

    //子窗口 emit callback==>openCallback 在父窗口page里执行最终的callback
    openCallback(opt) {
        console.log("openCallback ... 222 ", opt);

        if (opt && opt.act == 'reloadParentView') {
            if (opt && opt.opt && opt.opt.id) {
                console.log("openCallback reloadParentView id:", opt.opt.id);
                this.reLoadView(opt.opt.id);
            }
        } else if (opt && opt.act == 'setParam') {
            //todo
            if (opt && opt.opt && opt.opt.id) {
                console.log("openCallback setParam id:", opt.opt.id);
                this.setFormData(opt.opt.id, opt.opt.param);
            }
        } else if (opt && opt.act == 'setParamAndQuery') {
            //todo
            if (opt && opt.opt && opt.opt.id) {
                console.log("openCallback setParamAndQuery id:", opt.opt.id);
                this.searchForm(opt.opt.id, opt.opt.param);
            }
        }
    },

    //改变大小时,通知图表重绘
    onResize(id) {
        this.$bus.emit('execView-' + id, {method: 'resize', opt: null})
    },

    //重载视图
    reLoadView(id) {
        this.$bus.emit('execView-' + id, {method: 'app_reLoad', opt: null})
    },


    //调用视图执行方法
    execView(id, method, opt) {
        this.$bus.emit('execView-' + id, {method, opt})
    },

    //后退
    goBack() {
        //弹窗不后退
        if (!this.modal) {
            this.$router.back(-1);
        }
    },

    //直接使用数据填充form
    setFormData(id, data) {
        this.execView(id, 'form_set_data', data);
    },

    //执行搜索
    searchForm(id, opt) {
        this.execView(id, 'app_search', opt);
    },

    //重置表单
    resetForm(id) {
        this.execView(id, 'reset', {});
    },

    //执行搜索(表格)
    searchView(id, opt) {
        this.execView(id, 'app_search', opt);
    },

    //重置
    resetView(id) {
        this.execView(id, 'reset', {});
    },
    appPageOpen() {
        console.log(" appPageOpen");
    },
    appAction() {
        console.log("appAction");
    },
}
