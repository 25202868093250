export default {
    //表单查询
    form_search(params) {
        console.log("app_search:", JSON.stringify(params));
        params = params || {};
        this.$refs.search.query(params);
    },
    //通过data填充表单
    form_set_data(data) {
        this.$refs.search.setData(data);
    },
    //表单重置
    form_reset() {
        console.log("form_reset:");
        this.$refs.search.onReset();
    },

    //表格查询
    table_search(data) {
        this.$refs.widget.search(data);
    },

    //表格刷新
    table_reLoad() {
        this.$refs.widget.reLoad();
    },

    resize() {
        if (this.chart)
            this.$refs.widget.refresh();
    },

    search(params) {
        console.log("view method search:", JSON.stringify(params));
        //let data = {app_id: this.view.app_id, data_id: this.view.data_id, component_id: this.view.component_id}
        params = params || {};
        this.$refs.widget.search(params);
    },

    reset() {
        console.log("**************  reset() ********************");

        this.$refs.search.onReset();
    },

    app_search(params) {
        console.log("app_search:", JSON.stringify(params));
        params = params || {};
        if (this.$refs.search && this.$refs.search.query) this.$refs.search.query(params);
    },

    app_reLoad() {
        this.$refs.widget.reLoad();
    },

    onFormSearch(searchData) {
        console.log("custom search :", JSON.stringify(searchData));
        this.$emit("search", searchData)
    },


}
