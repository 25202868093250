<template>
  <div class="btn-del">
    <el-popover ref="popover" placement="top" width="160" v-model="visible">
      <p>{{ tip }}</p>
      <div style="text-align: right; margin: 0">
        <el-button :size="size" type="text" @click="visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="onConfirm">确定</el-button>
      </div>
    </el-popover>
    <el-button :type="type" :size="size" v-popover:popover @click.stop :class="{mleft,mright}">
      <slot></slot>
    </el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false
    };
  },
  props: {
    type: {
      default: "danger"
    },
    size: {
      default: ""
    },
    tip: {
      default: "确定要删除数据吗?"
    },
    mleft: {
      type: Boolean,
      default: false
    },
    mright: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onConfirm() {
      this.visible = false;
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
.btn-del {
  display: inline-block;
  margin-left: 10px;
}

.mleft {
  margin-left: 5px;
}

.mright {
  margin-right: 5px;
}
</style>

