<template>
  <div class="common-tag">
    <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="onClose(tag)">
      {{ tag }}
    </el-tag>
    <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="mini"
              @keyup.enter.native="onInput" @blur="onInput">
    </el-input>
    <el-button v-else size="mini" @click="onShow">{{ buttonText }}</el-button>
  </div>
</template>
<script>

export default {
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    }
  },
  computed: {},
  props: {
    value: {},
    buttonText: {
      default: '新增'
    }
  },
  watch: {
    value(nv) {
      this.dynamicTags = nv;
    }
  },
  methods: {

    //
    onClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    onShow() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    onInput() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
      this.$emit('input', this.dynamicTags);
    }
  },
  components: {},
  mounted() {
  },
}
</script>
<style lang="less">
.common-tag {
  .el-tag {
    height: 28px;
    line-height: 26px;
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
