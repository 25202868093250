<!--
  文件描述：index
  创建时间：2023/9/8 13:56
  创建人：zangyan
-->
<template>
  <el-row class="d-layout">
    <el-col :span="span" v-for="(item, index) in viewList" :key="index"
            :style="{height:`calc(100% / ${view.config.lines})`}">
      <component v-if="item.view" :is="toWidget(item.view.item)" :view="item.view.item" :page="page"
                 :data="item.view.item" :size="size"
                 @refresh='$emit("refresh")' :ref="'view-' + item.view.i" @search='(d)=>{$emit("search", d)}'
                 @refreshView='$emit("refreshView")'
                 @save='$emit("save")' @del='(id)=>{$emit("del", id)}'></component>
      <div v-else class="d-layout-view" :id="item.id" :view-id="view.id" :page-id="page.id">
      </div>
    </el-col>
  </el-row>
</template>

<script>
import ComponentTable from "@/build/component.table";
import ComponentSearch from "@/build/component.search";
import ComponentForm from "@/build/component.form";
import ComponentChart from "@/build/component.chart";
import ComponentImage from "@/build/component.image";
import ComponentVideo from "@/build/component.video";
import ComponentCustom from "@/build/component.custom";

const typeMap = {
  1: ComponentTable,
  2: ComponentSearch,
  3: ComponentForm,
  4: ComponentChart,
  5: ComponentImage,
  6: ComponentVideo,
  8: 'layout',
  10: ComponentCustom,
};
export default {
  name: 'layout',
  components: {},
  data() {
    return {
      view: {
        app_cols: [],
        app_buttons: [],
        content: '',
        config: {
          lines: 1,
          cols: 2
        },
      },
      viewList: []
    }
  },
  props: {
    page: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    span() {
      return 24 / this.view.config.cols
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.refresh(this.data);
        })
      }
    },
  },
  methods: {
    toWidget(item) {
      return typeMap[item.type];
    },
    refresh(r) {
      this.view = {...r, config: {lines: 1, cols: 2}}
      if (r.config && typeof r.config === 'string') {
        try {
          this.view.config = JSON.parse(r.config)
        } catch (e) {
          console.log(e)
        }
      } else if (r.config && typeof r.config === 'object') {
        this.view.config = Object.assign(this.view.config, r.config)
      }
      this.$set(this, 'viewList', [])
      if (!this.view.config.col_map) {
        this.view.config.col_map = {}
      }
      const config = this.view.config
      for (let i = 1; i <= (config.cols * (config.line || 1)); i++) {
        const data = {
          id: this.view.id + '_' + (config.view_mark || 'layout_col') + '_' + i,
          view: null
        }
        const sub_id = config.col_map[this.view.id + '_' + config.view_mark + '_' + i]
        if (sub_id) {
          data.view = this.view.sub_views.find(e => String(e.i) === String(sub_id)) || null
        }
        this.viewList.push(data)
        console.log(this.viewList)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh(this.data);
    })
  }
}
</script>

<style scoped lang="less">
.d-layout {
  height: 100%;

  .el-col {
    padding: 10px;
    overflow-y: auto;

    > div {
      //border: 1px solid #eee;
    }

    .d-layout-view {
      height: 100%;
      background-color: #eee;
    }
  }
}
</style>