<!--
  文件描述：component.layout
  创建时间：2023/9/8 17:53
  创建人：zangyan
-->
<template>
  <layout :size="size" :data="view" :page="page" @search="(d)=>$emit('search', d)"></layout>
</template>

<script>
import layout from "@/components/widget/layout";

export default {
  name: 'component.layout',
  components: {layout},
  data() {
    return {}
  },
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    view: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: Object,
      default: () => ({})
    }
  },
}
</script>

<style scoped lang="less">

</style>