<template>
  <img :src="url + '?_t=' + t" alt="" @click="onRefresh">
</template>
<script>

export default {
  data() {
    return {
      t: new Date().getTime()
    };
  },
  computed: {},
  props: {
    url: {}
  },
  watch: {},
  methods: {
    onRefresh() {
      this.t = new Date().getTime();
    }
  },
  components: {},
  mounted() {
  }
};
</script>
<style lang="less">

</style>
