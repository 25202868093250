<template>
  <div class="view-widget view-image" :style="{padding:(view.config.padding || 0) + 'px'}"
       :class="view.config.alignment" v-if="init">
    <template v-if="view.config.preview">
      <div class="img-box preview">
        <img :src="view.content" alt="" draggable="false">
        <el-image :src="view.content"
                  :fit="view.config.fit" :preview-src-list="[view.content]">
        </el-image>
      </div>
    </template>
    <template v-else>
      <div class="img-box">
        <img :src="view.content" alt="" draggable="false">
      </div>
    </template>
    <div class="clearfix"></div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {API_HOST} from '@/api'

export default {
  data() {
    return {
      loading: false,
      init: false,
      view: {
        app_cols: [],
        app_buttons: [],
        content: '',
        config: {
          alignment: 'left',
          preview: false,
          padding: 0,
          fit: 'scale-down'
        }
      }
    };
  },
  computed: {
    ...mapState(["menuMap"])
  },
  props: {
    size: {default: 'medium'},
    pro: {default: false},
    data: {}
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.refresh(this.data);
      })
    }
  },
  methods: {
    refresh(r) {
      this.init = false;
      const querys = process.env.NODE_ENV === 'production' ? '' : '?access_token=' + window.localStorage.getItem("token")
      this.view.content = (API_HOST || window.location.origin) + r.content + querys
      if (r.config && typeof r.config === 'string') {
        try {
          this.view.config = JSON.parse(r.config)
        } catch (e) {
          console.log(e)
        }
      } else if (r.config && typeof r.config === 'object') {
        this.view.config = Object.assign(this.view.config, r.config)
      }
      this.init = true;

    }
  },
  components: {},
  mounted() {
    this.$nextTick(() => {
      this.refresh(this.data);
    })
  }
};
</script>
<style lang="less">
.view-widget {
  &.view-image {
    height: 100%;
  }

  .img-box {
    height: 100%;
    width: 100%;

    &.preview {
      position: relative;

      .el-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        > img {
          opacity: 0;
        }
      }
    }

    > img {
      height: 100%;
      width: 100%;
    }
  }

  .widget-tools {
    margin-bottom: 20px;
  }

  background-color: #ffffff;

  .pagination {
    float: right;
  }
}

.bottom {
  line-height: 12px;
}

.mark {
  width: 100px;
  overflow-wrap: break-word;
}

.time {
  font-size: 12px;
  color: #999;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.el-table thead th {
  background: rgba(#EBEEF5, 0.8);
}
</style>

<style lang="less" scoped>
::v-deep .el-image {
  width: 100%;
  height: 100%;
}
</style>
