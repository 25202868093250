import moment2 from "moment-timezone";
import * as rangeUtil from './lib/rangeutil';
import * as dateMath from './lib/datemath';
import moment from "moment";


let timeRange = (k) => {
    let start;
    let end;

    let info = rangeUtil.describeTimeOption(k);

    if (info && info.from && info.to) {
        //已经存在的时间
        start = dateMath.parse(info.from, false);
        end = dateMath.parse(info.to, true);
    } else {
        let arr = k.split(' to ');
        if (arr.length > 1) {
            start = dateMath.parse(arr[0], false);
            end = dateMath.parse(arr[1], true);
        }
    }

    console.log("info:", JSON.stringify(info));
    console.log("start:", start);
    console.log("end:", end);


    return [start ? start : start, end ? end : end];
}
const showTime = (expire) => {
    return moment(new Date(expire)).format('YYYY-MM-DD HH:mm:ss')
}

let shortcuts = [];
let daterange = [];

let rangeOptions = rangeUtil.getRangeOptions();
if (rangeOptions && rangeOptions.length > 0) {
    rangeOptions.forEach((range) => {
        let n = range.from + " to " + range.to;
        let sc = {
            text: range.display,
            n: n,
            r: range,
            onClick(picker) {
                picker.$emit("pick", timeRange(n));
            }
        }
        shortcuts.push(sc);
        daterange.push({"n": n, "k": range.display});
    });
}


//系统全局函数,用户组件交互
export default {
    utc2date(t) {
        let r = moment2(t).tz('Asia/ShangHai').format('YYYY-MM-DD HH:mm:ss');
        return r;
    },
    daterange: daterange,
    timeRange: timeRange,
    showTime,
    shortcuts: shortcuts
}
