import Vue from 'vue';
import Router from 'vue-router';
import store from "@/store";
import {get} from 'lodash'

const APP_ENV = process.env.VUE_APP_ENV;
Vue.use(Router);

//
const routes = [
    {
        path: '/',
        extra: true,
        meta: {
            requiresAuth: false
        },
        component: resolve => require(['@/build/app'], resolve),
        children: [{
            path: '/:appId(\\d+)/page/:pageId(\\d+)',
            component: resolve => require(['@/build/page'], resolve),
            meta: {
                requiresAuth: false
            }
        }]
    },
    {
        path: '/app/admin',
        component: resolve => require(['@/build/admin'], resolve),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/app/user',
        component: resolve => require(['@/build/user'], resolve),
        meta: {
            requiresAuth: true
        },
        children: [
            // 设置
            {
                path: '/app/user/info',
                component: resolve => require(['@/build/user/info.vue'], resolve),
                meta: {}
            },
            {
                path: '/app/user/pwd',
                component: resolve => require(['@/build/user/pwd.vue'], resolve),
                meta: {}
            }

        ]
    },
    {
        path: '/default',
        extra: true,
        meta: {
            requiresAuth: false
        },
        component: resolve => require(['@/build/default/app'], resolve),
        children: [{
            path: '/default/:appId(\\d+)/page/:pageId(\\d+)',
            component: resolve => require(['@/build/page'], resolve),
            meta: {
                requiresAuth: false
            }
        }]
    },
    {
        path: '/default/:appId(\\d+)',
        extra: true,
        meta: {
            requiresAuth: false
        },
        component: resolve => require(['@/build/default/app'], resolve),
        children: [{
            path: '/default/:appId(\\d+)/page/:pageId(\\d+)',
            component: resolve => require(['@/build/page'], resolve),
            meta: {
                requiresAuth: false
            }
        }]
    },
    {
        path: '/simple',
        extra: true,
        meta: {
            requiresAuth: false
        },
        component: resolve => require(['@/build/simple/app'], resolve),
        children: [{
            path: '/default/:appId(\\d+)/page/:pageId(\\d+)',
            component: resolve => require(['@/build/page'], resolve),
            meta: {
                requiresAuth: false
            }
        }]
    },
    {
        path: '/:appId(\\d+)/:pageId(\\d+)',
        name: 'page',
        extra: true,
        meta: {
            requiresAuth: false
        },
        component: resolve => require(['@/build/page'], resolve)
    },
    {
        path: '/simple/:appId(\\d+)',
        extra: true,
        meta: {
            requiresAuth: false
        },
        component: resolve => require(['@/build/simple/app'], resolve),
        children: [{
            path: '/simple/:appId(\\d+)/page/:pageId(\\d+)',
            component: resolve => require(['@/build/page'], resolve),
            meta: {
                requiresAuth: false
            }
        }]
    },
    {
        path: '/:appId(\\d+)',
        extra: true,
        name: 'visitApp',
        meta: {
            requiresAuth: false
        },
        component: resolve => require(['@/build/app'], resolve),
        children: [{
            path: '/:appId(\\d+)/page/:pageId(\\d+)',
            component: resolve => require(['@/build/page'], resolve),
            meta: {
                requiresAuth: false
            }
        }]
    },
    {
        path: '/cas/:ticket',
        component: resolve => require(['@/pages/login/cas'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login',
        component: resolve => require(['@/pages/login/index'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/appLogin',
        component: resolve => require(['@/pages/login/appLogin'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/installer',
        component: resolve => require(['@/pages/installer/index'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login-sqldev',
        component: resolve => require(['@/pages/login/sqldev'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        name: 'logout',
        path: '/logout',
        component: resolve => require(['@/pages/logout'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/reg-license',
        component: resolve => require(['@/pages/login/reg.license'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/reg-license/:type',
        component: resolve => require(['@/pages/login/reg.license'], resolve),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/build',
        component: resolve => require(['@/pages/framework'], resolve),
        redirect: '/build/app',
        meta: {},
        children: [
            // 设置
            {
                path: '/build/setting/user',
                component: resolve => require(['@/pages/setting/user'], resolve),
                meta: {}

            },

            {
                path: '/build/setting/log',
                component: resolve => require(['@/pages/setting/log'], resolve),
                meta: {}
            },
            {
                path: '/build/setting/license',
                component: resolve => require(['@/pages/setting/license'], resolve),
                meta: {}
            },


            // app
            {
                path: '/build/app/:app_id(\\d+)',
                component: resolve => require(['@/pages/apps/app'], resolve),
                children: [
                    // 设置
                    {
                        path: '/build/app/:app_id(\\d+)/page',
                        component: resolve => require(['@/pages/apps/app/modules/page/index.vue'], resolve),
                        meta: {}
                    },
                    {
                        path: '/build/app/:app_id(\\d+)/api',
                        component: resolve => require(['@/pages/apps/app/modules/api/index.vue'], resolve),
                        meta: {}
                    },
                    {
                        path: '/build/app/:app_id(\\d+)/action',
                        component: resolve => require(['@/pages/apps/app/modules/action'], resolve),
                        meta: {}
                    },

                    {
                        path: '/build/app/:app_id(\\d+)/auth',
                        component: resolve => require(['@/pages/apps/app/modules/auth'], resolve),
                        meta: {}
                    },
                    {
                        path: '/build/app/:app_id(\\d+)/file',
                        component: resolve => require(['@/pages/apps/app/modules/file'], resolve),
                        meta: {}
                    }

                ]
            },

            {
                path: '/build/main',
                component: resolve => require(['@/pages/apps/main'], resolve),
                children: []
            },
            {
                path: '/build/app',
                component: resolve => require(['@/pages/apps'], resolve),
                children: []
            },
            {
                path: '/build/recycle',
                component: resolve => require(['@/pages/apps/recycle'], resolve),
                children: []
            },
            {
                path: '/build/property',
                component: resolve => require(['@/pages/property/home'], resolve),
                redirect: '/build/property/database',
                meta: {},
                children: [
                    {
                        path: '/build/property/database',
                        component: resolve => require(['@/pages/property/database'], resolve),
                        meta: {}
                    },
                    {
                        path: '/build/property/sensitiveMask',
                        component: resolve => require(['@/pages/property/sensitiveMask'], resolve),
                        meta: {}
                    },
                    {
                        path: '/build/property/dictionaries',
                        component: resolve => require(['@/pages/property/dictionaries'], resolve),
                        children: []
                    },
                ]
            }
        ]
    },
    {
        path: '/file/:file_url',
        name: 'file',
        component: resolve => require(['@/pages/file'], resolve),
    }
]
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
    return VueRouterReplace.call(this, location).catch(err => err)
}
const router = new Router({
    routes
});

//
router.beforeEach((to, from, next) => {
    console.log('to:', to);
    if (to.name === 'file') {
        next()
        return;
    }
    if (to.name === 'visitApp' || to.path.indexOf('simple') >= 0 || to.path.indexOf('default') >= 0 || to.path === 'appLogin') {
        if (to.params.appId) {
            sessionStorage.setItem('app_address_id', to.params.appId)
            sessionStorage.setItem("access_address", window.location.href);
        }
        next()
        return;
    }
    let token = window.localStorage.getItem('token');
    if (!token) {
        const user_count = get(store, 'state.sysSetting.user_count')
        const init = get(store, 'state.sysSetting.init')
        if ((init === true || user_count === 0) && to.path !== '/installer') {
            next({
                path: '/installer'
            })
            return
        }
    }
    if (to.meta.requiresAuth == false || token) {
        console.log('go  ', to);
        next();
    } else {
        if (APP_ENV === 'sqldev') {
            next('/login-sqldev');
            return;
        }
        //window.localStorage.setItem("beforeUrl", beforeUrl );
        next('/login?url=' + to.hash);
    }
});

export default router;
