import Vue from "vue";
import Vuex from "vuex";
import mod_app from './modules/mod_app';
import mod_view from './modules/mod_view';
import mod_buttons from './modules/mod_buttons';
import mod_page from './modules/mod_page';
import mod_action from './modules/mod_action';
import mod_router from './modules/mod_router';
import {findData} from "@/utils/tool";


Vue.use(Vuex);
//
// eslint-disable-next-line no-unused-vars
let selectApp = window.localStorage.getItem("selectApp");

const cacheGet = (key, def) => {
    let v = window.localStorage.getItem(key);
    console.log(v)
    return v ? JSON.parse(v) : def;
};

const cacheSet = (key, v) => {
    window.localStorage.setItem(key, JSON.stringify(v));
};
//
export default new Vuex.Store({
    modules: {
        m_app: {
            namespaced: true,
            ...mod_app
        },
        m_view: {
            namespaced: true,
            ...mod_view
        },
        m_button: {
            namespaced: true,
            ...mod_buttons
        },
        m_page: {
            namespaced: true,
            ...mod_page
        },
        m_action: {
            namespaced: true,
            ...mod_action
        },
        m_router: {
            namespaced: true,
            ...mod_router
        }
    },

    state: {
        app_path: cacheGet('app_path', {}),
        user: {},
        app: {},
        group: {
            id: 0
        },
        menuPage: {},
        page: {},
        component: {},
        menuMap: {},
        actionDialogShow: false,
        actionDialog: {},
        actionDialogPage: null,
        actionDialogPageData: {},
        sysSetting: {},
        loginShow: false,
        dragMap: {
            dragEl: null,
            targetEl: null
        },
        allViewData: {},
        activeTarget: null,
        menuStatus: cacheGet('menuStatus', {
            resource: false,
        }),
    },
    mutations: {
        sysSetting(s, v) {
            s.sysSetting = v
        },
        upMenu(state, key) {
            state.menuStatus[key] = !state.menuStatus[key]
            cacheSet('menuStatus', state.menuStatus)
        },
        upActiveView(s, v) {
            const viewPanel = document.querySelectorAll('.view-panel')
            viewPanel.forEach(e => {
                e.style.border = 'none'
                e.draggable = false
            })
            if (v) {
                const targetMap = findData(v)
                if (targetMap) {
                    s.activeTarget = targetMap
                    targetMap.target.draggable = true
                    targetMap.target.style.border = '1px solid #1c9ffb'
                } else {
                    s.activeTarget = null
                }
            } else {
                s.activeTarget = null
            }

        },
        updateAllViewData(s, v) {
            const data = {...s.allViewData}
            if (v && v.view_name) {
                const key = `${v.view_name}`;
                data[key] = {...v}
            }
            s.allViewData = data
        },
        upDragMapDragEl(s, v) {
            s.dragMap.dragEl = v
        },
        upDragMapTargetEl(s, v) {
            s.dragMap.targetEl = v
        },
        upLoginShow(s, v) {
            s.loginShow = Boolean(v)
        },
        path(s, v) {
            s.app_path = v;
            cacheSet('app_path', v);
        },
        user(s, p) {
            s.user = p;
        },
        app(s, p) {
            s.app = p;
        },
        group(s, p) {
            s.group = p;
        },
        page(s, p) {
            s.page = p;
        },
        component(s, p) {
            s.component = p;
        },
        goMenuPage(s, p) {
            console.log("goMenuPage:", JSON.stringify(p));
            s.menuPage = p;
        },
        menuMap(s, p) {
            s.menuMap = p;
        },

        //
        showActionDialogPage(s, p) {
            s.actionDialogShow = true;
            s.actionDialog = p.action;
            s.actionDialogPage = p.page;
            s.actionDialogPageData = p.data;
        },
        closeActionDialog(s) {
            s.actionDialogShow = false;
        },
        setSetting(s, p) {
            s.sysSetting = p
        }
    }
});
