export default {
    month() {
        let r = [];
        for (let i = 1; i <= 12; i++) {
            r.push({label: `${i}月`, value: i});
        }
        return r;
    },

    year() {
        let r = [];
        let d = new Date().getFullYear();
        for (let i = 10; i >= 0; i--) {
            r.push({label: `${d - i}年`, value: d - i});
        }
        for (let i = 0; i <= 10; i++) {
            r.push({label: `${d + i}年`, value: d + i});
        }
        return r;
    },
}
