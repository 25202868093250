<!--
  文件描述：index
  创建时间：2023/10/17 13:47
  创建人：zangyan
-->
<template>
  <el-divider :content-position="view.config['content-position']">{{ view.config.text }}</el-divider>
</template>

<script>
export default {
  name: 'index',
  components: {},
  props: {
    size: {default: 'medium'},
    pro: {default: false},
    data: {}
  },
  watch: {
    data: {
      handler() {
        console.log(this.data)
        this.$nextTick(() => {
          this.refresh(this.data);
        })
      },
      deep: true
    }
  },
  data() {
    return {
      view: {
        config: {
          'content-position': 'center',
          text: ''
        }
      }
    }
  },
  methods: {
    refresh(r) {
      this.init = false;
      if (r.config && typeof r.config === 'string') {
        try {
          this.view.config = JSON.parse(r.config)
        } catch (e) {
          console.log(e)
        }
      } else if (r.config && typeof r.config === 'object') {
        this.view.config = Object.assign(this.view.config, r.config)
      }
      this.init = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh(this.data);
    })
  }
}
</script>

<style scoped lang="less">

</style>