<template>
  <div class="view-widget view-table" :id="view_id">

    <div class="widget-tools"
         v-if="view&&view.app_buttons&&view.app_buttons.length>0">

      <template v-for="(btn,i) in view.app_buttons.filter(e=>e.btn_type!==2)">
        <template v-if="btn.enable">
          <el-dropdown split-button type="primary" :key="i" @visible-change="showTaskList"
                       v-if="btn.target_type=='download'" style="margin-right: 10px"
                       trigger="hover" @click="doDownloadButton(btn,params)" :size="size">
            {{ btn.label }}
            <el-dropdown-menu slot="dropdown">
              <template v-if="taskList && taskList.length>0">
                <el-dropdown-item v-for="task in taskList" :key="task.id">
                  <div class="bottom clearfix">
                    <label class="mark">{{ task.mark }}</label>
                    <time class="time" style="padding: 0 10px">{{ task.start_time | time }}</time>
                    <el-button type="text" plain size="small" @click="download(task.id)">下载</el-button>
                    <el-button type="text" plain size="small" @click="delDownload(task.id)">删除</el-button>
                  </div>
                </el-dropdown-item>
              </template>
              <template v-else>
                <el-dropdown-item>
                  暂无数据
                </el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-else :type="btn.display==='button'?'':btn.display" :key="i"
                     @click="doTableButton(btn,params,sorts)" style="margin-right: 10px"
                     :size="size">{{ btn.label }}
          </el-button>
        </template>
      </template>
    </div>

    <!--<div class="filter-container">-->
    <!--<el-checkbox-group>-->
    <!--<el-checkbox label="apple">apple</el-checkbox>-->
    <!--<el-checkbox label="banana">banana</el-checkbox>-->
    <!--<el-checkbox label="orange">orange</el-checkbox>-->
    <!--</el-checkbox-group>-->
    <!--</div>-->
    <template v-if="view&&view.config&&view.config.view_type!==2">
      <vxe-table :ref="'view_'+view.id" :key="table_key" :data="dataList" style="width: 100%"
                 v-if="!hideTable"
                 :border="!!view.config.border" :stripe="!!view.config.stripe"
                 :loading="loading" :height="view.config.height || containHeight" :size="size"
                 :row-config="{isCurrent: true, isHover: true}"
                 :row-style="{position: 'relative'}"
                 @checkbox-change="onSelectionChange"
                 @sort-change="onSortChange"
                 @cell-click="({row})=>{rowClick(row,1)}"
                 @cell-dblclick="({row})=>{rowClick(row,2)}"
                 @cell-contextmenu="({row})=>{rowClick(row,3)}">
        <vxe-column type="checkbox" width="55" v-if="!!view.config.checkbox" fixed="left"></vxe-column>
        <template v-for="(col, index) in cols">
          <vxe-column v-if="col.enable"
                      :field="col.name"
                      :title="col.label"
                      :key="index + table_key"
                      :width="String(col.width) !== '0' ? col.width : ''"
                      :fixed="col&&col.config?col.config.fixed:''"
                      :type="(col.expand===1)?'expand':''"
                      :show-overflow="col.ot?'tooltip':false"
                      :min-width="col.width!=='auto' && String(col.width) !== '0' ? col.width: (colWidth[col.name])||100"
                      :sortable="col.sort === 1">
            <template #content="{row}">
              <div style="padding: 10px;white-space: pre-wrap;">
                {{ row[col.name] }}
              </div>
            </template>
            <template #default="{ row }">
              <template v-if="col.form_type == 3">
                <a :href="urlToken(row,col.linkMap.link)" :target="col.linkMap.link==='#'?'':'_blank'"
                   class="underline-text"
                >{{ showLabel(row, col.linkMap.label) }}</a>
              </template>
              <template v-else-if="col.form_type == 5">
                <template v-if="col.linkMap">
                  {{ showLabel(row, col.linkMap.label) }}
                  <el-image
                      style="height: 28px;vertical-align: top;border: 1px solid #ccc"
                      :style="getImageStyle(col.linkMap)"
                      :src="urlToken(row,col.linkMap.link)"
                      :preview-src-list="[urlToken(row,col.linkMap.link)]">
                  </el-image>
                </template>

                <el-image
                    v-else
                    style="height: 28px;vertical-align: top;border: 1px solid #ccc"
                    :src="row[col.name]"
                    :preview-src-list="[row[col.name]]">
                </el-image>
              </template>
              <template v-else-if="col.form_type == 6">
                <div v-html='row[col.name]'></div>
              </template>
              <template v-else-if="col.form_type == 4">
                <!--<div>{{cp_html(col.html)}}</div>-->
                <div v-if="col.app_buttons">
                  <template v-for="(btn,bi) in col.app_buttons">
                    <app-button :btn="btn"
                                :class="{mr10:(bi+1)!==col.app_buttons.length && col.app_buttons.filter(e=>e.enable).length>1}"
                                :size="size" :v="row"
                                @click="appButtonClick" :key="btn.id"
                                v-if="col.enable">
                    </app-button>
                  </template>
                </div>
              </template>
              <template v-else-if="col.form_type == 7">
                <span @click.stop="showVideo(row[col.name])" class="cursor-pointer text-info">
                  {{ row[col.name] }}
                </span>
              </template>
              <template v-else-if="col.form_type == 99">
                <!--<div>{{cp_html(col.html)}}</div>-->
                <dynamic :template="col.html" :v="row" :p="params" :app_id="col.app_id"></dynamic>
              </template>
              <template v-else>
                <template>{{ row[col.name] === null ? '&lt;NULL&gt;' : row[col.name] }}</template>
              </template>
            </template>
          </vxe-column>
        </template>
      </vxe-table>
    </template>
    <div v-else class="list">
      <div v-for="i in (view.config.col || 4)" :style="`width: calc(100% / ${(view.config.col || 4)} - 10px)`" :key="i">
        <el-card v-for="(row,index) in col_list[i-1]" :key="i+'key'+index" class="box-card"
                 :class="{no_border:view.config.card_border,no_shadow:view.config.card_shadow}">
          <template v-for="(col,col_index) in cols">
            <div v-if="col.enable" :key="i+'key'+index+col_index" style="padding: 5px 0">
              <span class="text-default" v-if="!view.config.hide_key">{{ col.label ? col.label + ':' : '' }}</span>
              <template v-if="col.form_type == 3">
                <a :href="urlToken(row,col.linkMap.link)" :target="col.linkMap.link==='#'?'':'_blank'"
                   class="underline-text"
                >{{ showLabel(row, col.linkMap.label) }}</a>
              </template>
              <template v-else-if="col.form_type == 5">
                <template v-if="col.linkMap">
                  {{ showLabel(row, col.linkMap.label) }}
                  <el-image
                      style="height: 30px;vertical-align: top;border: 1px solid #ccc"
                      :style="getImageStyle(col.linkMap)"
                      :src="urlToken(row,col.linkMap.link)"
                      :preview-src-list="[urlToken(row,col.linkMap.link)]">
                  </el-image>
                </template>

                <el-image
                    v-else
                    style="height: 30px;vertical-align: top;border: 1px solid #ccc"
                    :src="row[col.name]"
                    :preview-src-list="[row[col.name]]">
                </el-image>
              </template>
              <template v-else-if="col.form_type == 6">
                <span v-html='row[col.name]'></span>
              </template>
              <template v-else-if="col.form_type == 4">
                <div v-if="col.app_buttons">
                  <template v-for="btn in col.app_buttons">
                    <app-button :btn="btn" :size="size" :v="row"
                                @click="appButtonClick" :key="btn.id"
                                v-if="col.enable">
                    </app-button>
                  </template>

                </div>
              </template>
              <template v-else-if="col.form_type == 7">
                <span @click.stop="showVideo(row[col.name])" class="cursor-pointer text-info">
                  {{ row[col.name] }}
                </span>
              </template>
              <template v-else-if="col.form_type == 99">
                <!--<div>{{cp_html(col.html)}}</div>-->
                <dynamic :template="col.html" :v="row" :p="params" :app_id="col.app_id"></dynamic>

              </template>
              <template v-else>
                {{ row[col.name] === null ? '&lt;NULL&gt;' : row[col.name] }}
              </template>
            </div>
          </template>
        </el-card>
      </div>
    </div>
    <el-pagination v-if="view&&view.config&&view.config.page"
                   class="pagination"
                   @current-change="onPageChange"
                   @size-change="onSizeChange"
                   :page-size.sync="page_size"
                   layout="total, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
    <div class="clearfix"></div>
    <el-dialog v-dialog-drag title="视频预览" :visible.sync="showVideoDialog" width="50%">
      <video :src="videoUrl" controls="controls" style="width: 100%;height: 100%" v-if="showVideoDialog"
             autoplay></video>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showVideoDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as Api from "@/api";
import reg from "@/api/register";
import * as _ from "lodash";
import moment from "moment";
import {EJSON} from "bson";
import {mapState} from "vuex";
import Action from "../action";
import Dynamic from "../common/dynamic";
import {calculateMaxKeyLength} from "@/utils/tool";
import {nanoid} from "nanoid";
// import CustomButton from "../common/button.vue"
// import ElScrollbar from "element-ui/packages/scrollbar/src/main";
export default {
  data() {
//      let params = this.$route.params;
    return {
      videoUrl: null,
      showVideoDialog: false,
      view_id: nanoid(),
      hideTable: false,
      containHeight: null,
      loading: false,
      init: false,
      params: {},
      mapping: {},
      table_key: nanoid(),
      content: this.$parent.$parent.$parent.content,
      searchData: {},
      pageIndex: 0,
      page_size: this.defavultSize || 10,
      multipleSelection: [],
      sorts: {},
      dataList: [],
      taskList: [],
      dataTools: [],
      total: 0,
      selectItems: [],
      view: {
        app_cols: [],
        app_buttons: [],
        config: {
          download: false,
          page: true
        }
      },
      colWidth: {}
    };
  },
  computed: {
    ...mapState(["menuMap"]),
    cols() {
      return this.view.app_cols;
    },
    col_list() {
      const list = [...this.dataList]
      const cols = this.view.config.col || 4
      const sList = []
      for (let i = 1; i <= cols; i++) {
        sList.push([])
      }
      list.forEach((e, i) => {
        const ix = i % cols
        sList[ix].push(e)
      })
      return sList
    },
  },
  props: {
    size: {default: 'medium'},
    pro: {default: false},
    data: {},
    zoom: {default: 1},
    formRef: {default: null},
  },
  watch: {
    view: {
      immediate: true,
      deep: true,
      handler() {
        this.table_key = nanoid()
      }
    }
  },
  filters: {
    time(v, format) {
      console.log("v:", v, "   time format:", format);
      return moment(v).format(format || "YYYY-MM-DD HH:mm:ss");
    }
  },
  methods: {
    ...reg,
    showVideo(videoUrl) {
      console.log('xxx')
      this.videoUrl = videoUrl
      this.showVideoDialog = true
    },
    getImageStyle(data) {
      const style = {};
      if (data.width) {
        style.width = data.width;
        style.height = ''
      }
      if (data.height) {
        style.height = data.height;
      }
      return style
    },
    urlToken(data, url) {
      url = this.showLabel(data, url)
      return url.replace('${TOKEN}', window.localStorage.getItem("token"))
    },
    showLabel(data, str) {
      // 使用正则表达式替换
      return str.replace(/\$\{v\.([\w\u4e00-\u9fa5]+)\}/g, function (match, key) {
        // 提取匹配的键，然后检查对象中是否有匹配的属性，如果有则返回对应的值，否则保持原样
        const propName = key.trim();
        // eslint-disable-next-line no-prototype-builtins
        return data.hasOwnProperty(propName) ? data[propName] : match;
      });

    },
    rowClick(val, type) {
      const typeToLabelMap = {
        1: '行选中',
        2: '双击行',
        3: '右键行',
      };
      const label = typeToLabelMap[type];
      const events = this.view.app_buttons.filter(e => e.btn_type === 2 && e.enable && e.label === label)
      events.forEach(e => {
        this.doTableButton(e, this.params, this.sorts, [{...val}])
      })
    },
    setTip(e) {
      if (this.zoom !== 1) {
        setTimeout(() => {
          this.$nextTick(() => {
            const rect = e.target.getBoundingClientRect();
            const x = rect.left * this.zoom;
            const y = rect.top * this.zoom;
            const tooltip = document.getElementById(e.target.getAttribute('aria-describedby'))
            if (tooltip) {
              const width = tooltip.offsetWidth;
              const height = tooltip.offsetHeight
              const windowHeight = window.innerHeight;
              const windowWidth = window.innerWidth
              if (windowHeight - (y + 20) < height) {
                tooltip.style.top = (y - height - 30) + 'px'
              } else {
                tooltip.style.top = (y + 20) + 'px'
              }
              if (windowWidth - (x - (width / 2)) < width) {
                tooltip.style.left = (windowWidth - width - 20) + 'px'
              } else {
                tooltip.style.left = (x) + 'px'
              }
            }
          })
        }, 250)
      }


    },
    labelHead(h, {column}) {
      let l = column.label.length
      let f = 16 //每个字大小，其实是每个字的比例值，大概会比字体大小差不多大一点，
      column.minWidth = f * l //字大小乘个数即长度 ,注意不要加px像素，这里minWidth只是一个比例值，不是真正的长度
      //然后将列标题放在一个div块中，注意块的宽度一定要100%，否则表格显示不完全
      return h('div', {class: 'table-head', style: {width: '300px'}}, [column.label])
    },

    download(id) {
      //this.setCookieToken();
      let token = window.localStorage.getItem("token");
      window.open(Api.getSyncHost() + `/app/export/download?app_id=` + this.data.app_id + "&task_id=" + id + "&access_token=" + token);
    },
    delDownload(id) {
      console.log("delDownload:", id);

      Api.post(`/app/export/del`, {
        task_id: id,
        app_id: this.data.app_id
      })
          .then(r => {
            if (r && r.success) {
              for (var i = 0; i < this.taskList.legnth; i++) {
                let t = this.taskList[i];
                if (t.id == id) {
                  this.taskList.splice(i, 1);
                  break;
                }
              }
              this.showOk("删除成功!")
            } else {
              this.showFail(r.msg)
            }
          })
    },
    doDownloadButton(btn, params) {
      if (this.dataList.length > 0) {
        this.doTableButton(btn, params);
      } else {
        this.showFail("请查询后,选择导出.");
      }
    },

    showTaskList(show) {
      if (show) {
        console.log("show:", show);
        Api.post(`/app/export/list`, {
          view_id: this.data.id,
          app_id: this.data.app_id
        })
            .then(r => {

              if (r && r.success) {
                var response = r.response;

                if (response.ok) {
                  if (response.data && response.data.list) {
                    this.taskList = response.data.list;
                  } else {
                    this.showFail("未查询到数据")
                  }
                } else {
                  this.showFail(response ? response.msg : "")
                }
              } else {
                this.showFail(r.msg)
              }
            })
      }
    },
    refresh(r) {
      this.init = false;
      r = r || this.data;
      this.dataList = [];
      let cols = [];
      let btns = [];

      if (r && r.style) {
        this.loadCss(r.style);
      }

      if (r && r.app_cols && r.config) {
        r.app_cols = _.isString(r.app_cols)
            ? JSON.parse(r.app_cols || "[]")
            : r.app_cols;
        console.log("app_cols length:", r.app_cols.length);

        if (r.app_cols && r.app_cols.length > 0) {
          cols = r.app_cols.map(col => {
            col.config = _.isString(col.config)
                ? JSON.parse(col.config || "{}")
                : col.config;
            return this.warpCol(col);
          });
        }

        r.app_buttons = _.isString(r.app_buttons)
            ? JSON.parse(r.app_buttons || "[]")
            : r.app_buttons;

        if (r.app_buttons && r.app_buttons.length > 0) {
          btns = r.app_buttons.map(btn => {
            btn.config = _.isString(btn.config)
                ? JSON.parse(btn.config || "{}")
                : btn.config;

            //表达式,不需要解析
//              btn.param_config = _.isString(btn.param_config)
//                ? JSON.parse(btn.param_config || "{}")
//                : btn.param_config;
            return btn;
          });
        }


        this.view.call_back_config = r.call_back_config;
        this.view.before_config = r.before_config;
        this.view.config = _.isString(r.config)
            ? JSON.parse(r.config || "{}")
            : r.config;
        this.view.config.tools = r.config.tools || [];


        if (this.view && this.view.config && this.view.config.page_size && this.view.config.page_size > 0) {
          this.page_size = this.view.config.page_size;

          console.log("page_size:", this.page_size);
//            if (this.page_size > 200) {
//              this.page_size = 200;
//            }
        }


        this.view.app_buttons = btns;
        this.view.app_cols = cols;
        this.dataTools = (r.config && r.config.tools) ? r.config.tools.filter(v => v.type == "row") : {};


        this.colMap = _.keyBy(cols, "name");
      }

      this.init = true;
    },
    onSelectionChange({records}) {
      console.log(records)
      this.multipleSelection = records;
    },
    wrapData(data) {
      for (let key in data) {

        let col = this.colMap[key];
        if (!col) {
          continue;
        }

        //气泡默认不生效
        col.ot = false;

        //console.log("wrapData  key :", key);
        if (col.transFun) {
          try {
            var ret = col.transFun(this.getRoot(), EJSON, data);
            if (ret.then) {
              ret.then((d) => {
                data[key] = d;
              });
            } else {
              data[key] = ret;
            }

          } catch (e) {
            console.error(e);
          }
        }

        if (col.form_type == 2) {
          if (col.config.timeFormat) {
            if (data[key]) {
              data[key] = moment(data[key]).format(col.config.timeFormat);
            } else {
              data[key] = "";
            }
          }
        }

        if (col.config && col.config.col_large) {
          if (col.config.col_large == '1') {
            col.ot = true;
          } else if (col.config.col_large == '2') {
            if (col.config.col_max_len && col.config.col_max_len > 1) {
              let maxLength = ~~col.config.col_max_len;
              if (data[key] && data[key].length > maxLength) {
                data[key + "_source_"] = data[key];
                data[key] = data[key].substr(0, maxLength) + " ..."
              }
            }
          }
        }
        if (col.config && col.config.linkMap) {
          if (typeof col.config.linkMap === "string") {
            try {
              const linkMap = JSON.parse(col.config.linkMap)
              col.linkMap = linkMap
            } catch (e) {
              console.log(e)
            }
          } else if (typeof col.config.linkMap === "object") {
            col.linkMap = col.config.linkMap
          }
        } else {
          if (col.form_type == 3) {
            col.linkMap = {
              label: '${v.' + col.name + '}',
              link: '#'
            }
          }
        }
      }
      return data;
    },
    warpCol(col) {
      try {

        if (col.trans) {
          console.log("trans:" + col.trans);
          col.transFun = new Function("_self", "EJSON", "v", col.trans);
        }

        if (!col.width || ~~col.width < 0) {
          col.width = "auto"
        }
//          if (col.html) {
//            col.html = new Function("v", col.html);
//          }
      } catch (e) {
        console.error(`报错了 => `, col, e);
      }
      return col;
    },
    search(searchData) {
      this.mapping = {};

      //映射分页
      if (searchData) {
        for (let k in searchData) {
          if (searchData[k] == 'APP_PAGE_NO') {
            this.mapping["APP_PAGE_NO"] = k;
          } else if (searchData[k] == 'APP_PAGE_SIZE') {
            this.mapping["APP_PAGE_SIZE"] = k;
          } else if (searchData[k] == 'APP_PAGE_START') {
            this.mapping["APP_PAGE_START"] = k;
          }
        }
      }

      this.searchData = searchData || this.searchData;
      this.pageIndex = 0;
      this.load(this.searchData);
    },

    reLoad() {
      console.log("table reLoad:", JSON.stringify(this.params));
      this.load(this.params);
    },

    // 最终调用加载
    load(params) {
      console.log("search load:", JSON.stringify(this.searchData), JSON.stringify(params));

      //确保在渲染后再执行查询
      if (!this.init) {
        this.refresh(this.data);
      }

      this.loading = true;
      ///let {page} = this.view.config;

      params = params || {};
      this.params = params;

      let page_no = this.pageIndex >= 1 ? this.pageIndex - 1 : this.pageIndex;
      let page_size = this.page_size;


      if (this.mapping["APP_PAGE_NO"]) {
        params[this.mapping["APP_PAGE_NO"]] = page_no + "";
      }

      if (this.mapping["APP_PAGE_SIZE"]) {
        params[this.mapping["APP_PAGE_SIZE"]] = page_size + "";
      }

      if (this.mapping["APP_PAGE_START"]) {
        let start = page_no * page_size;
        params[this.mapping["APP_PAGE_START"]] = start + "";
      }

      params = this.execBefore(this.view, params);

      Api.post(`/app/data/run`, {
        app_id: this.data.app_id,
        data_id: this.data.data_id,
        form_id: this.data.query_id,
        has_total: 1,
        data_params: JSON.stringify(params),
        page_no: page_no,
        page_size: page_size,
        order_params: JSON.stringify(this.sorts)
      })
          .then(r => {

            this.dataList = [];
            this.total = 0;

            if (r && r.success && r.response && r.response.ok && r.response.data) {
              try {
                this.execCallback2(this.view, r.response.data, params, ({count, ok, msg, list}) => {
                  if (ok) {
                    this.dataList = list.map(this.wrapData);
                    this.total = count || r.response.count || list.length
                    this.colWidth = calculateMaxKeyLength(this.cols, this.dataList, this.size)
                    this.hideTable = true
                    this.$nextTick(() => {
                      this.table_key = nanoid()
                      this.hideTable = false
                    })
                    this.$store.commit('updateAllViewData', {
                      list: this.dataList,
                      total: this.total,
                      form: {...params},
                      page_no,
                      page_size,
                      view_name: this.data.name
                    })
                  } else {
                    this.showFail(msg)
                  }
                })
              } catch (e) {
                this.showFail(e)
              }
            } else {
              this.showFail(r.msg)
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },

    //
    onRunExp(tool) {
      let mapperData = eval("(" + tool.actionMapper + ")");
      Action.action(this.data.app_id, tool.action, mapperData);
    },
    onSortChange(v) {
      console.log(v)
      this.sorts = {[v.field]: v.order};
      this.search();
    },
    onSelected(v) {
      this.selectItems = v;
      this.$emit("select", v.map(i => i.id));
    },
    onPageChange(page) {
      this.pageIndex = page;
      this.load(this.searchData);
    },
    onSizeChange(s) {
      this.page_size = s;
      this.search();
    },
    countHeight() {
      this.containHeight = null
      // let height = null
      // let view = document.getElementById(this.view_id)
      // let formHeight = 0
      // if (view) {
      //   if (this.formRef && this.formRef.search) {
      //     formHeight = this.formRef?.search?.offsetHeight || this.formRef?.search?.$el?.offsetHeight || 0
      //   }
      //   let parentElement = view.parentNode;
      //   // 循环检查父级元素的类名
      //   while (parentElement && parentElement !== document.body) {
      //     if (parentElement === document.body) {
      //       console.dir('The target element is the body element.');
      //       break;
      //     } else if (parentElement.classList.contains('grid-stack-item')) {
      //       // 如果父级元素的类名是 grid-stack-item，则获取其高度
      //       height = parentElement.offsetHeight
      //       let h = 60
      //       if (this.size === 'mini') {
      //         h = 65
      //         if (this.zoom > 1) {
      //           h += 70;
      //         }
      //         if (this.view.app_buttons && this.view.app_buttons.length > 0) {
      //           h += 40
      //         }
      //
      //       } else if (this.size === 'small') {
      //         h = 70
      //         if (this.zoom > 1) {
      //           h += 70;
      //         }
      //         if (this.view.app_buttons && this.view.app_buttons.length > 0) {
      //           h += 40
      //         }
      //       } else if (this.size === 'medium') {
      //         h = 75
      //         if (this.zoom > 1) {
      //           h += 70;
      //         }
      //         if (this.view.app_buttons && this.view.app_buttons.length > 0) {
      //           h += 40
      //         }
      //       }
      //       if (this.zoom > 1.2) {
      //         h += 90
      //         if (formHeight > 0) {
      //           h += 10
      //         }
      //       }
      //       h += formHeight
      //       height -= h
      //
      //       break; // 找到符合条件的元素后跳出循环
      //     }
      //     // 继续向上查找
      //     parentElement = parentElement.parentNode;
      //   }
      // }
      // this.containHeight = height > 150 ? height : null
    }
  },
  components: {
    // ElScrollbar,
    Dynamic
    // CustomButton
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh(this.data);
      if (this.data && this.data.app_cols) {
        const cols = {}
        this.data.app_cols.map(e => {
          return [e.name]
        }).forEach(key => {
          cols[key] = ''
        })
        this.colWidth = calculateMaxKeyLength(this.data.app_cols, [cols], this.size)
      }
      this.countHeight()
      this.$bus.on('onFormSearch-' + this.data.id, this.search)
      this.$bus.on('onCountHeight', this.countHeight)
    })
  },
  beforeDestroy() {
    this.$bus.off('onFormSearch-' + this.data.id)
    this.$bus.off('onCountHeight')
  }
};
</script>
<style lang="less">

.view-table {
  padding: 5px;
}

/*.el-table {*/
/*font-size: 8px;*/
/*td{*/
/*padding: 8px;*/
/*}*/
/*th {*/
/*padding: 3px 0;*/
/*line-height: 1.4285;*/
/*}*/
/*}*/

/*.el-table .cell {*/
/*white-space: pre-line;*/
/*}*/

.view-widget {

  .widget-tools {
    margin-bottom: 20px;
  }

  background-color: #ffffff;

  .pagination {
    float: right;
  }
}

.bottom {
  line-height: 12px;
}

.mark {
  width: 100px;
  overflow-wrap: break-word;
}

.time {
  font-size: 12px;
  color: #999;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.el-table thead th {
  background: rgba(#EBEEF5, 0.8);
}
</style>

<style lang="less" scoped>
::v-deep .list {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;

  .el-card {
    margin-bottom: 13px;
  }
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td,
::v-deep .vxe-table--render-default .vxe-body--row.row--stripe {
  background-color: #e5ffe5;
}

::v-deep .vxe-table--render-default .vxe-body--row.row--hover,
::v-deep .vxe-table--render-default .vxe-body--row.row--hover.row--stripe {
  background-color: #b8d7f6;
}

::v-deep .vxe-table .vxe-table--render-default .vxe-body--row.row--current {
  background-color: #e6f7ff;
}

::v-deep .vxe-table--render-default .vxe-body--column > .vxe-cell,
::v-deep .vxe-table--render-default .vxe-footer--column > .vxe-cell,
::v-deep .vxe-table--render-default .vxe-header--column > .vxe-cell {
  max-height: 100% !important;
}

.vxe-cell {
  .el-button + .el-button {
    margin-left: 0;
    margin-bottom: 2px;
    margin-top: 2px;
  }

  .mr10 {
    margin-right: 10px;
  }
}

.no_border {
  border: none !important;
}

.no_shadow {
  box-shadow: none !important;
}
</style>
