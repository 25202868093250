// import Cookies from 'js-cookie'

/* 放弃使用Cookies */
const Cookies = {
    get: function () {
        return localStorage.getItem(...arguments)
    },
    set: function () {
        localStorage.setItem(...arguments)
    },
    remove: function () {
        localStorage.removeItem(...arguments)
    }
}

const cookies = {}

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function (name = 'default', value = '') {
    // const currentCookieSetting = {
    //   expires: 1
    // }
    // merge(currentCookieSetting, cookieSetting)
    // Cookies.set(`d2admin-${process.env.VUE_APP_VERSION}-${name}`, value)
    Cookies.set(`d2admin-x.x.x-${name}`, value)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function (name = 'default') {
    // return Cookies.get(`d2admin-${process.env.VUE_APP_VERSION}-${name}`)
    return Cookies.get(`d2admin-x.x.x-${name}`)
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
    return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = 'default') {
    // return Cookies.remove(`d2admin-${process.env.VUE_APP_VERSION}-${name}`)
    return Cookies.remove(`d2admin-x.x.x-${name}`)
}

export default cookies
