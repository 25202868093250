<template>
  <div class='search-table'>
    <el-form class='search-container' size='small' :inline='true'>
      <slot name='searchs'></slot>
      <el-form-item style='float:right'>
        <slot name='searchs-right'></slot>
        <el-button type='primary' class='add-btn' size='small' @click='onSearch'>搜索</el-button>
        <el-button class='add-btn' size='small' @click='onReset'>重置</el-button>
      </el-form-item>
      <div class='clearfix'></div>
    </el-form>

    <el-row v-if="$slots['buttons']" class='button-container'>
      <el-col :span='24'>
        <slot name='buttons'></slot>
      </el-col>
    </el-row>

    <el-table size='small' :default-expand-all='expand' @selection-change='onSelected' :height='height'
              v-if="$slots['columns']" :data='dataList' style='width: 100%' border
              :row-class-name='onTableRowClassName'>
      <el-table-column v-if='!!select' type='selection' width='55'>
      </el-table-column>
      <slot name='columns'></slot>
    </el-table>

    <el-pagination v-if="$slots['columns']" class='pagination' @current-change='onPageChange'
                   @size-change='onSizeChange' :page-size.sync='size' layout='total, sizes, prev, pager, next, jumper'
                   :total='total'>
    </el-pagination>

    <div class='clearfix'></div>
  </div>
</template>

<script>
import * as Api from '@/api';

export default {
  data() {
    return {
      pageIndex: 1,
      size: this.defaultSize || 10,
      dataList: [],
      total: 0,
      selectItems: []
    };
  },
  props: {
    url: {},
    searchData: {},
    title: {},
    manual: {},
    defaultSize: {},
    select: {},
    height: {},
    expand: {},
    page: {
      default: true
    },
    param_cali: {
      default: () => (() => {
        return true
      })
    }
  },
  mounted() {
    if (this.url && !this.manual) {
      this.load(this.searchData);
    }
  },
  watch: {
    url() {
      this.search();
    },
    size() {
      this.search();
    }
  },
  methods: {
    // 搜索
    search() {
      this.pageIndex = 0;
      if (this.url) {
        this.load(this.searchData);
      } else {
        this.$emit('search', this.searchData);
      }
    },

    // 最终调用加载
    load(params) {
      params = params || {};
      params.page_no = this.pageIndex >= 1 ? this.pageIndex - 1 : this.pageIndex;
      params.page_size = this.size;
      let u = `${this.url}`;
      if (!this.param_cali(params)) {
        setTimeout(() => {
          this.load(params)
        }, 200)
        return
      }
      Api.page(u, params).then(r => {
        if (r.length) {
          this.dataList = r;
          this.total = r.length;
        } else {
          this.dataList = r.list;
          this.total = r.count;
        }
      });
    },

    //
    onReset() {
      this.$emit('reset');
    },
    onSearch() {
      this.search();
    },
    onSelected(v) {
      this.selectItems = v;
      this.$emit('select', v.map(i => i.id));
    },
    onPageChange(page) {
      this.pageIndex = page;
      this.load(this.searchData);
    },
    onSizeChange(s) {
      this.size = s;
      this.search();
    },
    onTableRowClassName(row, index) {
      if (index === 1) {
        return 'info-row';
      } else if (index === 3) {
        return 'positive-row';
      }
      return '';
    }
  }
};
</script>


<style lang='less'>
.search-table {
  .search-container {
    padding: 0;
  }

  .search-container .el-input {
    width: 200px;
  }

  .el-form .el-form-item {
    margin-bottom: 15px;
  }

  .button-container {
    margin: 0 0 15px;
  }

  .el-table .info-row {
    background: #c9e5f5;
  }

  .el-table .positive-row {
    background: #e2f0e4;
  }

  .el-pagination {
    float: right;
  }

  .pagination {
    margin-top: 25px;
  }
}
</style>
