<template>
  <div class="menu-btn">
    <el-link type="info"
             style="font-size: 20px"
             @click="upMenu(menuKey)"
             :title="menuStatus[menuKey]?'展开':'收起'"
             :underline="false"
             :icon="menuStatus[menuKey]?'el-icon-caret-right':'el-icon-caret-left'">
    </el-link>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'fold.menu',
  props: ['menuKey'],
  computed: {
    ...mapState(['menuStatus'])
  },
  methods: {
    ...mapMutations(['upMenu'])
  },
  watch: {
    menuStatus: {
      deep: true,
      immediate: true,
      handler (v) {
        this.$emit('upKey', v[this.menuKey] || false)
      }
    }
  }
}
</script>

<style scoped lang="less">
.menu-btn {
  position: absolute;
  right: -15px;
  top: 50%;
  height: 40px;
  line-height: 40px;
  transform: translateY(-50%);
  z-index: 2;
}
</style>
