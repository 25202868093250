<!--
  文件描述：index
  创建时间：2023/10/9 15:07
  创建人：zangyan
-->
<template>
  <el-form label-width="100px" size="mini" label-suffix=":">
    <el-form-item>
      <!--      <span slot="label" style="font-weight: 700">文本样式:</span>-->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="文字大小">
            <el-input-number v-model="config.text_size" :min="12" :max="24"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="文字粗细">
            <el-radio-group v-model="config.text_weight">
              <el-radio label="normal">正常</el-radio>
              <el-radio label="bold">粗体</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!--        <el-col :span="6">-->
        <!--          <el-form-item label="背景颜色">-->
        <!--            <el-input type="color" v-model="config.background_color" style="width: 100px"></el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-row>
    </el-form-item>
    <el-form-item>
      <span slot="label" style="font-weight: 700">边框样式:</span>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="宽度">
            <el-input-number v-model="config.border_width" :min="0" :max="24"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="颜色">
            <el-input type="color" v-model="config.border_color" style="width: 100px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="类型">
            <el-radio-group v-model="config.border_type">
              <el-radio label="solid">实线</el-radio>
              <el-radio label="dashed">虚线</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="圆角">
            <el-input-number v-model="config.border_radius" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <span slot="label" style="font-weight: 700">内边距样式:</span>
      <el-row :gutter="10">
        <el-col :span="24">
          <!--          <el-form-item label="内边距">-->
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="上">
                <el-input-number v-model="config.padding_top" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="右">
                <el-input-number v-model="config.padding_right" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下">
                <el-input-number v-model="config.padding_bottom" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="左">
                <el-input-number v-model="config.padding_left" :min="0"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <!--          </el-form-item>-->
        </el-col>
        <!--        <el-col :span="12">-->
        <!--          <el-form-item label="外边距">-->
        <!--            <el-row :gutter="10">-->
        <!--              <el-col :span="6">-->
        <!--                <el-form-item label="上" >-->
        <!--                  <el-input-number v-model="config.margin_top" :min="0"></el-input-number>-->
        <!--                </el-form-item>-->
        <!--              </el-col>-->
        <!--              <el-col :span="6">-->
        <!--                <el-form-item label="右" >-->
        <!--                  <el-input-number v-model="config.margin_right" :min="0"></el-input-number>-->
        <!--                </el-form-item>-->
        <!--              </el-col>-->
        <!--              <el-col :span="6">-->
        <!--                <el-form-item label="下" >-->
        <!--                  <el-input-number v-model="config.margin_bottom" :min="0"></el-input-number>-->
        <!--                </el-form-item>-->
        <!--              </el-col>-->
        <!--              <el-col :span="6">-->
        <!--                <el-form-item label="左" >-->
        <!--                  <el-input-number v-model="config.margin_left" :min="0"></el-input-number>-->
        <!--                </el-form-item>-->
        <!--              </el-col>-->
        <!--            </el-row>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-row>
    </el-form-item>
    <!--    <el-form-item>-->
    <!--      <span slot="label" style="font-weight: 700">阴影样式:</span>-->
    <!--      <el-row :gutter="10">-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="水平位置">-->
    <!--            <el-input-number v-model="config.shadow_offset_x" :min="0" :max="24"></el-input-number>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="垂直位置">-->
    <!--            <el-input-number v-model="config.shadow_offset_y" :min="0" :max="24"></el-input-number>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="模糊距离">-->
    <!--            <el-input-number v-model="config.shadow_blur" :min="0" :max="24"></el-input-number>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="阴影大小">-->
    <!--            <el-input-number v-model="config.shadow_spread" :min="0" :max="24"></el-input-number>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="阴影颜色">-->
    <!--            <el-input type="color" v-model="config.shadow_color" style="width: 100px"></el-input>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="内侧阴影">-->
    <!--            <el-switch v-model="config.shadow_inset" :active-value="true" :inactive-value="false"></el-switch>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </el-form-item>-->
    <el-form-item>
      <el-button type='success' size='small'
                 @click="onSubmit('params')">生成
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {dataConfigMap} from "@/utils/tool";
import _ from "lodash";

export const setting = {
  text_size: 14,
  // text_color: '',
  // background_color: '#ffffff',
  text_weight: 'normal',
  border_width: 0,
  border_color: '',
  border_type: 'solid',
  border_radius: 0,
  shadow_color: '',
  // shadow_blur: 0,
  // shadow_offset_x: 0,
  // shadow_offset_y: 0,
  // shadow_spread: 0,
  // shadow_opacity: 0,
  padding_top: 0,
  padding_right: 0,
  padding_bottom: 0,
  padding_left: 0,
  // margin_top: 0,
  // margin_right: 0,
  // margin_bottom: 0,
  // margin_left: 0,
  shadow_inset: false
}
export default {
  name: 'index',
  components: {},
  props: {
    data: {
      default: () => ({})
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(v) {
        if (v) {
          const newData = dataConfigMap(_.cloneDeep(v), 'setting', setting)
          this.config = {...setting, ...newData.setting}
        } else {
          this.config = {...setting}
        }
        console.log(this.config, 'xxx')
      }
    }
  },
  data() {
    return {
      config: {...setting}
    }
  },
  methods: {
    onSubmit() {
      this.$set(this.data, 'setting', JSON.stringify(this.config))
      this.$emit('save')
    }
  }
}
</script>

<style scoped lang="less">
</style>