<template>
  <el-button :type="realBtn.display" @click.native="onClick" v-show="show" :size="size"
             v-if="realBtn&&realBtn.id&&realBtn.enable&&condBtn()">
    <slot>{{ realBtn.label }}</slot>
  </el-button>
</template>
<script>
import * as Api from '@/api'
import * as _ from 'lodash'

let btnCache = {};

export default {
  data() {
    return {
      realBtn: {},
      type: 'success'
    }
  },
  computed: {},
  props: {
    params: {
      type: Object,
      default: function () {
        return {};
      }
    },
    id: {
      default: 0,
    },
    app_id: {
      default: 0,
    },
    size: {default: 'medium'},
    v: {
      type: Object,
      default: function () {
        return {};
      }
    },
    show: {
      default: true,
    },
    btn: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  watch: {}
  ,
  methods: {
    //条件显示
    condBtn() {
      if (this.realBtn && this.realBtn.id && this.realBtn.cond) {

        try {
          let v = this.v;
          let btn = this.realBtn;
          let condFun = new Function("v", "p", btn.cond);
          let r = condFun(v, this.params);
          console.log("condBtn return :", r);
          return r;
        } catch (e) {
          console.log("condBtn err:", e);
        }
      }
      return true;
    },
    loadExec(d) {
      // console.dir(d);
      try {
        d.config = JSON.parse(d.config || "{}");
        d.call_back_config = JSON.parse(d.call_back_config || "{}");
      } catch (e) {
        console.error(e);
        d.config = {};
        d.call_back_config = {};
      }


      if (!d.config.check_type) {
        d.config.check_type = "1";
      }

      if (!d.config.confirm_msg) {
        d.config.confirm_msg = "是否确定要执行该操作?";
      }


      if (!d.call_back_config.refresh) {
        d.call_back_config.refresh = true;
      }

      return d;
    },
    onClick() {
      console.log("button onClick");
      this.$emit("click", {btn: this.realBtn, v: this.v, p: this.params});
    },

    refresh() {
      console.log("button refresh:");
      if (this.btn && this.btn.id) {
        this.realBtn = _.cloneDeep(this.btn);
        if (this.realBtn.display) {
          this.type = this.realBtn.display;
        }
      } else if (this.id) {

        console.log("button app_id:", this.app_id, this.id);

        if (btnCache[this.id]) {
          this.realBtn = btnCache[this.id];
        } else {
          var query = {button_id: this.id};
          if (this.app_id) {
            query.app_id = this.app_id
          } else {
            query.domain = window.location.host;
          }
          Api.page(`/app/button/get`, query)
              .then(r => {
                if (r && r.id) {
                  let button = this.loadExec(r);
                  btnCache[this.id] = button;
                  this.realBtn = _.cloneDeep(button);
                }
              })
        }
      }
    }//end fun
  }
  ,
  components: {}
  ,
  mounted() {
    this.$nextTick(() => {
      this.refresh();
    })
  }
  ,
}
</script>
