<template>
  <div class=".map-box" style="min-height: 400px;"></div>
</template>
<script>
const BMap = window.BMap
export default {
  data() {
    return {
      map: null
    };
  },
  computed: {},
  props: {
    height: {
      default: 400
    },
    formData: {},
    // eslint-disable-next-line vue/require-prop-type-constructor
    'template': "",
    'v': {},
    'p': {},
    // eslint-disable-next-line vue/require-prop-type-constructor
    'app_id': 0
  },
  methods: {
    onClick(e) {
      var pt = e.point;
      if (this.marker) {
        this.map.clearOverlays(this.marker);
      }
      this.marker = new BMap.Marker(pt);
      this.map.addOverlay(this.marker);

      //
      let geoc = new BMap.Geocoder();
      geoc.getLocation(pt, rs => {
        var addComp = rs.addressComponents;
        this.formData.lat = pt.lat + '';
        this.formData.lng = pt.lng + '';
        this.formData.address =
            addComp.province +
            addComp.city +
            addComp.district +
            addComp.street +
            addComp.streetNumber;

        console.log(this.formData);
      });
    }
  },
  components: {},
  mounted() {
    let BMap = window.BMap;
    this.map = new BMap.Map(document.getElementsByClassName(".map-box")[0]);
    //   var pt = { lat, lng };
    //   var marker = new BMap.Marker({ lat, lng });
    //   map.clearOverlays(marker);
    //   map.addOverlay(marker);
    //   map.setCenter(model.address);
    var point = new BMap.Point(116.331398, 39.897445);
    this.map.centerAndZoom(point, 12);

    new BMap.LocalCity().get(result => {
      this.map.setCenter(result.name);
    });

    //
    this.map.addEventListener("click", e => this.onClick(e));
  }
};
</script>
<style lang="less">
.map-box {
  width: 100%;
  height: 100%;
}
</style>
