<template>

  <div class="box">
    <div class="build-widget" v-if="view.id==view.query_id" v-show="!view.is_hide_form">
      <widget-search-form ref="search" :view="view" @search="onFormSearch" :pro="true" :params="view.app_params"
                          :size="size" v-if="view.id==view.query_id"/>
    </div>
    <div class="build-widget">
      <component :formRef="$refs" :is="toWidget(view)" ref="widget" :data="view"
                 class="widget" :pro="true" :size="size" :zoom="zoom"/>
    </div>
  </div>

</template>

<script>
import view_method from "@/api/view.method";
import "@dattn/dnd-grid/dist/dnd-grid.css";
import WidgetTable from "@/components/widget/table";
import WidgetSearchForm from "@/components/widget/form/search.form";
import WidgetChart from "@/components/widget/chart/index.vue";
import {GridLayout, GridItem} from 'vue-grid-layout'


export default {
  data() {
    return {};
  },
  props: {
    size: {default: 'medium'},
    view: {},
    modal: {default: false},
    zoom: {default: 1}
  },
  computed: {},
  methods: {
    ...view_method,


    refresh() {

    },

    toWidget(item) {
      switch (item.type) {
        case 1:
          return WidgetTable;
        case 2:
          return WidgetChart;
      }
    },


  },
  mounted() {
    this.refresh();
    this.$bus.on('execView-' + this.view.id, ({method, opt}) => {
      if (this[method]) {
        this[method](opt);
      }
    });
  },
  components: {
    WidgetTable,
    WidgetSearchForm,
    GridLayout,
    GridItem,
  }
};
</script>

<style lang="less">

.table-box {
  padding: 0px;
  margin: 0px;
}

.build-widget {
  /*padding: 10px;*/
  margin-bottom: 10px;
  background-color: #E4E7ED;
}

/*.vue-grid-item {*/
/*height: auto  !important;*/
/*overflow: scroll;*/

/*}*/
</style>
