<template>
  <div class="v-root" v-loading="loading" :class="{
  'flex-left':page&&page.config&&page.config.align==='1',
  'flex-right':page&&page.config&&page.config.align==='3',
  'flex-center':page&&page.config&&!page.config.align}"
       :style="{
    backgroundColor:page&&page.config&&page.config.bgColor?page.config.bgColor:'#e5e7eb'
  }">
    <div :class="pageClass"
         :style="{width:page&&page.config&&page.config.width?page.config.width+'px':'100%'}">
      <el-row :gutter="8">
        <el-col v-for="c in layout" :key="c.id" :span="c.w">
          <component :is="toWidget(c.item)" :ref="'view-' + c.i" :view="c.item" @search="onFormSearch" :page="page"
                     :size="page.size" v-show="!c.item.is_hide"/>
        </el-col>
      </el-row>
      <page-dialog ref="pageDialog" @callback="openCallback"/>
      <download-dialog ref="downloadDialog"/>
      <div style="height: 50px;">&nbsp;</div>
    </div>
  </div>

</template>
<script>
import * as Api from "@/api";
import * as _ from "lodash";
import {Loading} from 'element-ui';
import 'gridstack/dist/gridstack.min.css';
import ComponentSearch from "./component.search";
import ComponentTable from "./component.table";
import ComponentChart from "./component.chart";
import ComponentForm from "./component.form";
import ComponentCustom from "./component.custom";
import ComponentImage from './component.image'
import ComponentVideo from "@/build/component.video";
import ComponentDivider from "@/build/component.divider";
import PageDialog from "./page.dialog";
import DownloadDialog from "./download.dialog";
import {mapState, mapActions} from "vuex";
import sys from "@/api/sys";
import ComponentLayout from "@/build/component.layout";
import {buildTree, dataConfigMap} from "@/utils/tool";
import {nanoid} from "nanoid";
import {setting} from '@/components/widget/viewStyle/index'

let loadingInstance;
const typeMap = {
  1: ComponentTable,
  2: ComponentSearch,
  3: ComponentForm,
  4: ComponentChart,
  5: ComponentImage,
  6: ComponentVideo,
  7: ComponentDivider,
  8: ComponentLayout,
  10: ComponentCustom,
};

export default {
  data() {
    let params = this.$route.params;
    return {
      id: 'stack-' + nanoid(),
      pageClass: "page",
      loading: false,
      query: this.$route.query,
      page: {
        config: {
          width: '',
          align: '',
          bgColor: ''
        }
      },
      load: false,
      is_root: true,
      layout: [],
      app: {id: this.appId},
      appId: params.appId,
      pageId: params.pageId,
      views: [],
      hb: 1,
      grid: null,
    };
  },
  computed: {
    ...mapState(["m_router"]),
  },
  props: {
    modal: {
      default: false
    }
  },
  watch: {
    $route(v) {
      if (!this.modal) {
        this.pageId = v.params.pageId;
        this.appId = v.params.appId;
        this.refresh(this.appId, this.pageId, v.query);
      }
    }
  },
  methods: {
    ...sys,
    //定义dialog回调时使用
    ...mapActions('m_router', ["setGoPage"]),
    viewStyle(styleConfig) {
      let style = ''
      if (styleConfig) {
        style += `font-size:${styleConfig['text_size']}px;
        font-weight:${styleConfig['text_weight']};
        border:${styleConfig['border_width']}px ${styleConfig['border_type']} ${styleConfig['border_color']};
        border-radius:${styleConfig['border_radius']}px;
        padding:${styleConfig['padding_top']}px ${styleConfig['padding_right']}px ${styleConfig['padding_bottom']}px ${styleConfig['padding_left']}px;`
      }
      return style
    },
    toWidget(item) {
      return typeMap[item.type];
    },
    reset() {
      this.page = {};
      this.layout = [];
    },

    onFormSearch(data) {
      if (data && data.form_id && data.query_ids) {
        this.$bus.emit('onFormSearch-' + data.form_id, data.search);
        if (data.query_ids && data.query_ids.length > 0) {
          data.query_ids.forEach(id => {
            this.$bus.emit('onFormSearch-' + id, data.search);
          })
        }
      }
    },

    refresh(appId, pageId, params, cb = () => {
    }) {
      this.load = true;
      //console.log("page refresh:", pageId, appId, JSON.stringify(params));

      this.layout = [];
      this.loading = true;

      var query = {page_id: pageId};

      if (appId) {
        query.app_id = appId;
      } else {
        query.domain = window.location.host;
      }

      Api.post(
          `/app/page/get`,
          query
      ).then(r => {
        if (r && r.config) {
          r.config = Object.assign({
            width: '',
            align: '',
            bgColor: ''
          }, JSON.parse(r.config));
        } else {
          r.config = {
            width: '',
            align: '',
            bgColor: ''
          };
        }
        this.page = r;
        console.log(this.page)
        if (typeof cb === "function") {
          cb(r)
        }
        this.app.id = r.app_id;
        this.views = r.views;
        this.viewsMap = _.keyBy(this.views, "id");
        this.initLayout(this.views);

        if (params) {
          setTimeout(() => {
            this.initQuery(params);
          }, 500)
        }
      }).finally(() => {
        loadingInstance.close();
        this.loading = false;
      });
    },
    initQuery(query) {
      if (!query || JSON.stringify(query) === '{}') {
        return;
      }
      this.views.forEach(({id}) => {
        this.$bus.emit('execView-' + id, {method: 'app_search', opt: query})
      })
    },
    initLayout(components) {
      this.layout = [];
      this.hidden = [];
      let tempLayout = [];
      let hb = 0;
      if (components) {
        for (let c of components) {
          let la = {"x": 0, "y": 0, "w": 24, "h": 10};
          try {
            console.log(c.id, "  com  layout:", c.layout);
            if (typeof c.layout == "string") {
              la = JSON.parse(c.layout);
            } else {
              la = c.layout;
            }
          } catch (e) {
            console.log("布局解析失败,使用默认布局:", e);
          }

          //隐藏组件
          if (c.is_hide && c.type == 2) {
            la = {"x": 0, "y": 0, "w": 0, "h": 0};
          }
          if (la.hb && la.hb > hb) {
            hb = la.hb
          }
          la.i = c.id + '';
          la.name = c.id_name;
          c = dataConfigMap(c, 'setting', setting);
          la.item = c;

          //解决x一样可能导致排序不准确
          la.sort = la.x + la.y;
          tempLayout.push(la);
        }
      }
      //重新排序,保证位置不乱
      tempLayout = _.sortBy(tempLayout, ["y", "sort"])
      const offsetWidth = this.$refs?.gridStack?.offsetWidth || document.querySelector('v-root')?.offsetWidth
      if (hb && hb > 800 && offsetWidth) {
        this.hb = Number(offsetWidth / hb).toFixed(6)
      } else {
        this.hb = 1
      }

      //console.log("layout:", tempLayout);
      this.layout = buildTree(tempLayout)
      this.id = 'stack-' + nanoid()
    },
  },
  components: {
    ComponentSearch,
    ComponentForm,
    ComponentTable,
    PageDialog,
    DownloadDialog
  },
  beforeMount() {
    this.reset();
  },
  mounted() {
    console.log("page load!", JSON.stringify(this.query));

    if (this.query && this.query.single) {
      this.pageClass = "page s_page"
    }
    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
      if (!this.modal) {
        if (this.pageId && this.appId) {
          loadingInstance = Loading.service({fullscreen: true});
          this.refresh(this.appId, this.pageId, this.query);
          this.$api.get('/site/info', {id: this.appId}).then(r => {
            if (r) document.title = r.name + ' DataGo';
          })
        }
      } else {
        loadingInstance = Loading.service({fullscreen: false});
      }

    });
  }
};
</script>
<style scoped lang="less">
::v-deep .dialog-body .page {
  height: auto !important;
}

.v-root {

  &.flex-center {
    display: flex;
    justify-content: center;
  }

  &.flex-left {
    display: flex;
    justify-content: flex-start;
  }

  &.flex-right {
    display: flex;
    justify-content: flex-end;
  }
}

.page {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 5px;
}

.sa_body {
  height: 100%;
}

.s_page {
  height: 100vh;
  overflow-y: scroll;
  padding-left: 5px;
}

.page {
  line-height: 1.28;
}

::v-deep .grid-stack {
  margin: 5px 0;
}

::v-deep .grid-stack > .grid-stack-item {
  overflow: auto;

  .grid-stack-item-content {
    background-color: #fff;
  }
}
</style>
