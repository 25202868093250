import {cloneDeep, isNumber} from 'lodash-es'

const span = document.createElement('span')
document.body.appendChild(span);

export function objIntToString(obj) {
    if (!Object.keys(obj)) {
        return obj
    }
    const result = {...obj}
    for (const key in obj) {
        if (isNumber(obj[key])) {
            result[key] = String(obj[key])
        }
    }
    return result
}

/**
 * 移动数组
 * @param {Array} arr 数组
 * @param {Number} newIndex 移动后的位置
 * @param {Number} oldIndex 移动前的位置
 */
export function move(arr, newIndex, oldIndex) {
    // arr = cloneDeep(arr)
    // 如果当前元素在拖动目标位置的下方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置的地方新增一个和当前元素值一样的元素，
    // 我们再把数组之前的那个拖动的元素删除掉，所以要len+1
    if (oldIndex > newIndex) {
        // 向上拖动,

        // 添加,
        arr.splice(newIndex, 0, cloneDeep(arr[oldIndex]))
        // 删除
        arr.splice(oldIndex + 1, 1)
    } else {
        // 向下拖动

        // 如果当前元素在拖动目标位置的上方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置+1的地方新增一个和当前元素值一样的元素，
        // 这时，数组len不变，我们再把数组之前的那个拖动的元素删除掉，下标还是index
        // 添加
        arr.splice(newIndex + 1, 0, cloneDeep(arr[oldIndex]))
        // 删除
        arr.splice(oldIndex, 1)
    }
    return arr
}

export const download = function (text, filename = `sqldev-${Date.now()}.txt`) {
    const data = new Blob([text], {type: 'application/octet-stream'})
    const downloadUrl = window.URL.createObjectURL(data)
    const anchor = document.createElement('a')
    anchor.href = downloadUrl
    anchor.download = filename
    anchor.click()
    // 释放createObjectURL
    window.URL.revokeObjectURL(data)
}

export const random = function (min, max) {
    switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * min + 1, 10)
        case 2:
            return parseInt(Math.random() * (max - min + 1) + min, 10)
        default:
            return 0
    }
}

export const changeFavicon = link => {
    let $favicon = document.querySelector('link[rel="icon"]')
    // If a <link rel="icon"> element already exists,
    // change its href to the given link.
    if ($favicon !== null) {
        $favicon.href = link
        // Otherwise, create a new element and append it to <head>.
    } else {
        $favicon = document.createElement('link')
        $favicon.rel = 'icon'
        $favicon.href = link
        document.head.appendChild($favicon)
    }
}

export function bottom(layout) {
    let max = 0, bottomY;
    for (let i = 0, len = layout.length; i < len; i++) {
        bottomY = layout[i].y + layout[i].h;
        if (bottomY > max) max = bottomY;
    }
    return max;
}

export function buildTree(list, pid = 0) {
    const map = {};
    const tree = []
    list.forEach(e => {
        map[e.item.id] = {...e};
    });

    list.forEach(e => {
        if (e.item.pid == pid) {
            tree.push(map[e.item.id]);
        }
    });
    if (tree.length > 0) {
        tree.forEach(e => {
            if (e.item.type == 8) {
                e.item.sub_views = buildTree(list, e.item.id)
            }
        })
    }
    return tree
}

export function findDataId(event, key = 'data-id') {
    // 获取事件的目标元素
    var target = event.target;

    // 如果目标元素的 className 是 "view-panel"，则返回其 data-id
    if (target.className === "view-panel") {
        return target.getAttribute(key);
    }

    // 否则，查找目标元素的 offsetParent
    var offsetParent = target.offsetParent;

    // 如果 offsetParent 存在并且其 className 为 "view-panel"，则返回其 data-id
    if (offsetParent && offsetParent.className === "view-panel") {
        return offsetParent.getAttribute(key);
    }

    // 如果没有找到符合条件的元素，则继续递归查找当前 offsetParent 的 offsetParent
    if (offsetParent) {
        return findDataId({target: offsetParent});
    }

    // 如果找不到符合条件的元素，则返回 null 或适合你的默认值
    return null;
}

export function findData(event, key = 'data-id') {
    // 获取事件的目标元素
    var target = event.target;

    // 如果目标元素的 className 是 "view-panel"，则返回其 data-id
    if (target.className === "view-panel") {
        return {id: target.getAttribute(key), target: target};
    }

    // 否则，查找目标元素的 offsetParent
    var offsetParent = target.offsetParent;

    // 如果 offsetParent 存在并且其 className 为 "view-panel"，则返回其 data-id
    if (offsetParent && offsetParent.className === "view-panel") {
        return {id: offsetParent.getAttribute(key), target: offsetParent};
    }

    // 如果没有找到符合条件的元素，则继续递归查找当前 offsetParent 的 offsetParent
    if (offsetParent) {
        return findData({target: offsetParent});
    }

    // 如果找不到符合条件的元素，则返回 null 或适合你的默认值
    return null;
}

export function fristDataId(event) {
    // 获取事件的目标元素
    var target = event.target;

    // 如果目标元素的 className 是 "view-panel"，则返回其 data-id
    if (target.className === "view-panel") {
        return target.getAttribute("data-id");
    }

    // 否则，查找目标元素的第一个子元素
    var firstChild = target.firstChild;

    // 如果第一个子元素存在并且其 className 为 "view-panel"，则返回其 data-id
    if (firstChild && firstChild.className === "view-panel") {
        return firstChild.getAttribute("data-id");
    }

    // 如果没有找到符合条件的元素，则继续递归查找当前第一个子元素的第一个子元素
    if (firstChild) {
        return findDataId({target: firstChild});
    }

    // 如果找不到符合条件的元素，则返回 null 或适合你的默认值
    return null;
}

// eslint-disable-next-line no-unused-vars
function strLen(str) {
    str = String(str)
    var len = 0
    for (let i = 0; i < str.length; i++) {
        const c = str.charCodeAt(i)
        if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
            len++
        } else len += 2
    }
    return len
}

function calculateStringLength(str, fontSize, fontWeight = 'normal') {
    span.textContent = str
    span.style.fontSize = fontSize + 'px';
    span.style.fontWeight = fontWeight
    span.style.fontFamily = '-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol';
    span.style.fontFeatureSettings = '"tnum"'
    span.style.lineHeight = '22px'
    span.style.position = 'fixed'
    span.style.top = '-1000px'

    // 获取文字宽度
    // 移除 span 元素
    // document.body.removeChild(span);
    return span.offsetWidth;
}

export function calculateMaxKeyLength(cols, arr, size = 'medium') {
    // 创建一个空对象来存储每个 key 的最大长度
    const maxKeyLengths = {};
    let sizeNum = 14
    switch (size) {
        case 'mini':
            sizeNum = 12
            break;
        case 'small':
            sizeNum = 13
            break;
        case 'medium':
            sizeNum = 14
            break;
    }
    // 10冗余 sizeNum排序 20边距
    let addedNum = 10 + sizeNum + 20
    const parseInt = (num) => {
        return window.parseInt(String(num))
    }
    if (cols && cols.length > 0) {
        cols.forEach(e => {
            maxKeyLengths[e.name] = parseInt(calculateStringLength(e.label, sizeNum, 'bold') + addedNum);
        })
    }
    // 遍历数组中的每个对象
    arr.forEach(function (obj) {
        for (const key in obj) {
            const col = cols.find(col => col.name === key);
            if (col && (col.form_type == 4 || col.form_type == 5)) {
                continue
            }
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                // const valueLength = strLen(obj[key])
                const max = parseInt(calculateStringLength(obj[key], sizeNum) + 20 + 10);
                // const max = parseInt(Math.max(Math.min(valueLength, 50), 3) * sizeNum / 1.5 + addedNum);
                // 如果该 key 在 maxKeyLengths 中不存在或当前 value 长度大于已记录的最大长度，则更新最大长度
                if (!(key in maxKeyLengths) || max > maxKeyLengths[key]) {
                    maxKeyLengths[key] = max > 500 ? 500 : max
                }
            }
        }
    });
    // 返回包含最大长度的对象
    return maxKeyLengths;
}

// json转为对象
export function dataConfigMap(row, configName, value = []) {
    if (row[configName]) {
        if (typeof row[configName] === 'string') {
            try {
                row[configName] = JSON.parse(row[configName])
            } catch (e) {
                console.log(e)
            }
        } else if (typeof row[configName] === 'object') {
            row[configName] = cloneDeep(row[configName])
        }
    } else {
        row[configName] = cloneDeep(value)
    }
    return row
}

//树内部字段映射
export function transformTree(tree, fieldMappings, childrenFieldName) {
    if (!tree || !Array.isArray(fieldMappings)) {
        return tree; // 返回原始树结构数组
    }
    return tree.map(node => {
        const transformedNode = {...node};

        fieldMappings.forEach(mapping => {
            const oldKey = mapping.oldKey;
            const newKey = mapping.newKey;

            if (transformedNode[oldKey] !== undefined) {
                transformedNode[newKey] = transformedNode[oldKey];
                delete transformedNode[oldKey];
            }
        });

        if (transformedNode[childrenFieldName] && transformedNode[childrenFieldName].length > 0) {
            transformedNode[childrenFieldName] = transformTree(
                transformedNode[childrenFieldName],
                fieldMappings,
                childrenFieldName
            ); // 递归处理子节点
        }
        return transformedNode;
    });
}

// tree转list结构
export function treeToList(tree, childrenFieldName = 'children') {
    if (!tree || !Array.isArray(tree)) {
        return [];
    }

    const resultList = [];

    function traverse(node) {
        resultList.push(node);
        if (node[childrenFieldName] && node[childrenFieldName].length > 0) {
            node[childrenFieldName].forEach(child => {
                traverse(child);
            });
        }
        delete node[childrenFieldName]; // 移除子节点字段
    }

    tree.forEach(node => {
        traverse(node);
    });

    return resultList;
}

// list转tree结构
export function listToTree(list, id, parentFieldName, childrenFieldName) {
    if (!list || !Array.isArray(list)) {
        return [];
    }

    const map = new Map();

    list.forEach(item => {
        item[childrenFieldName] = [];
        map.set(item[id], item);
    });

    const tree = [];

    list.forEach(item => {
        const parentId = item[parentFieldName];
        if ((parentId !== null || parentId !== 0) && map.has(parentId)) {
            map.get(parentId)[childrenFieldName].push(item);
        } else {
            tree.push(item);
        }
    });

    return tree;
}

// 树末节点内部child置为null
export function setNullForEmptyChildren(node, key) {
    if (node[key].length === 0) {
        node[key] = null;
    } else {
        node[key].forEach(child => {
            setNullForEmptyChildren(child, key);
        });
    }
}

export function hexToRgb(hex) {
    // 去除可能包含的 # 符号
    hex = hex.replace(/^#/, '');

    // 如果 hex 是缩写的 3 位颜色码，将其扩展为 6 位
    if (hex.length === 3) {
        hex = hex
            .split('')
            .map(char => char + char)
            .join('');
    }

    // 将 hex 分割成 R、G、B 部分
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // 返回 RGB 形式的颜色
    return `${r}, ${g}, ${b}`;
}