<template>
  <el-upload class="avatar-uploader" action="//qdlfz.oss-cn-beijing.aliyuncs.com" :data="uploadData" :on-error="onError"
             :show-file-list="false" :on-success="onSuccess" :before-upload="onBeforeUpload">
    <img v-if="model" :src="model" class="avatar">
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
import * as Api from "@/api";
import {Loading} from 'element-ui';

export default {
  data() {
    return {
      uploadData: {
        key: "",
        token: ""
      },
      model: this.value
    };
  },
  watch: {
    value(nv) {
      this.model = nv;
    }
  },
  props: ["value", "extra"],
  methods: {
    refresh() {
      Api
          .get(`/upload`)
          .then((r) => {
            this.uploadData = r;
            this.uploadData.OssaccessKeyId = r.ossaccessKeyId;
          })
    },

    //
    onBeforeUpload() {
      this.loading = Loading.service({fullscreen: true});
      return true;
    },
    onSuccess() {
      this.model = `//qdlfz.oss-cn-beijing.aliyuncs.com/${this.uploadData.key}${this.extra || ''}`;
      this.$emit("input", this.model);
      this.onEnd();
    },
    onError(evt) {
      console.log('上传出错 => ' + evt)
      this.onEnd();
    },
    onEnd() {
      if (this.loading) {
        this.loading.close();
      }
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style lang="less" scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
