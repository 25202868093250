<template>
  <el-button :type="type" @click="onClick">
    <slot v-if="!showTime"></slot>
    <template v-else>
      {{ remain }}后{{ placeholder }}
    </template>
  </el-button>
</template>
<script>

export default {
  data() {
    return {
      showTime: false,
      remain: this.time,
    };
  },
  computed: {},
  props: {
    placeholder: {
      default: '可再次点击',
    },
    time: {
      default: 60,
    },
    type: {},
  },
  watch: {},
  methods: {
    //
    tick() {
      if (this.remain == 0) {
        this.remain = this.time;
        this.showTime = false;
        return;
      }
      this.remain--;
      setTimeout(() => {
        this.tick();
      }, 1 * 1000);
    },

    //
    onClick() {
      if (this.showTime) {
        return;
      }
      this.$emit('click');
      this.showTime = true;
      this.tick();
    },
  },
  components: {},
  mounted() {
  },
};
</script>
<style lang="less">

</style>