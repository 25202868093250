import axios from "axios";
import {Loading, Message, MessageBox} from "element-ui";
import router from "../router";
import qs from "qs";
import store from "../store";
import Vue from "vue";

let host = '';
let api_host = process.env.VUE_APP_API_HOST
if (api_host === undefined || api_host === 'undefined') {
    api_host = ''
}
export const API_HOST = api_host;

if (API_HOST) {
    host = API_HOST
}

//
const REQ_INSTANCE = axios.create({
    timeout: 50000,
    headers: {
        // "Content-Type": "multipart/form-data"
        "Content-Type": "application/x-www-form-urlencoded"
        // "Content-Type": "application/json"
    },
    data: {},
    // withCredentials: true,
    transformRequest: [
        function (data) {
            return qs.stringify(data);
        }
    ]
});

//
let loadInstance = null;
REQ_INSTANCE.interceptors.request.use(
    config => {
        if (config.url.indexOf('/app') == -1) {
            loadInstance = Loading.service({
                fullscreen: true,
                text: "请求中.."
            });
        }

        if (API_HOST !== '' && API_HOST !== undefined && API_HOST !== 'undefined' && config.url.indexOf('http') == -1) {
            config.url = API_HOST + config.url;
        }

        console.log("config.url:", config.url);

        let token = window.localStorage.getItem("token");
        if (token) {
            config.headers["X-Access-Token"] = token;
            const setting = store?.state?.sysSetting
            if (setting && setting.version) {
                config.headers['Datago-Static-Version'] = setting.version
            }
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

//
const errorHandler = {};
const handleReponse = (url, res) => {
    console.log("====handleReponse=== url:", url);

    let win = window;
    if (window.parent) {
        win = window.parent;
    }

    return new Promise((s, f) => {
        res.then(
            v => {
                loadInstance && loadInstance.close();
                const appId = sessionStorage.getItem('app_address_id')
                let r = v.data;
                const appLoginFun = (appId) => {
                    if (appId) {
                        if (window.self !== window.top) {
                            Vue.bus.emit('show-app-login-dialog')
                            f(new Error('未登录，请登录后再访问。'))
                        } else {
                            router.replace('/appLogin')
                        }
                    } else {
                        window.localStorage.setItem("beforeUrl", win.location.href);
                        router.replace("/login?url=" + win.location.href);
                    }
                }
                switch (r.result) {
                    case 200:
                        s(r.data);
                        break;
                    case 304:
                        MessageBox.confirm('版本已升级，请刷新后使用。', '升级提示').then(() => {
                            window.onbeforeunload = null
                            window.location.reload()
                            if (window.top.location.reload) {
                                window.top.location.reload()
                            }
                        })
                        break
                    case 401:
                        Message.error({
                            title: "请求",
                            message: `权限不足`,
                            type: "error",
                            duration: 2000
                        });
                        // window.localStorage.removeItem("token");
                        // window.localStorage.removeItem("user");
                        // router.replace("/app/login");
                        s({});
                        break;
                    case 405:
                        console.log("=======405=====");
                        window.localStorage.removeItem("token");
                        window.localStorage.removeItem("user");
                        appLoginFun(appId)
                        break;
                    case 403:
                        window.localStorage.removeItem("token");
                        window.localStorage.removeItem("user");
                        // eslint-disable-next-line no-case-declarations
                        appLoginFun(appId)
                        break;
                    case 406:
                        // 应用登录
                        window.sessionStorage.setItem("access_address", win.location.href);
                        // eslint-disable-next-line no-case-declarations
                        const params = {...router.currentRoute.params}
                        if (params.appId) {
                            window.sessionStorage.setItem("app_address_id", params.appId);
                        } else {
                            window.sessionStorage.removeItem("app_address_id");
                        }
                        appLoginFun(1)
                        break
                    case 4003:
                        // router.replace("/error?msg=" + "抱歉，许可证已过期，请联系厂商申请新的许可证。");
                        router.replace('/reg-license')
                        f(new Error('抱歉，许可证已过期，请联系厂商申请新的许可证。'))
                        break
                    case 4004:
                        // router.replace("/error?msg=" + "抱歉，软件未配置许可证信息,请配置后重新启动。");
                        router.replace('/reg-license')
                        f(new Error('抱歉，软件未配置许可证信息,请配置后重新启动。'))
                        break
                    default:
                        // eslint-disable-next-line no-case-declarations
                        let handler = errorHandler[r.result];
                        if (handler) {
                            handler(r);
                        } else {
                            Message.error({
                                title: "请求",
                                message: `${v.config.url} => ${r.result} | ${JSON.stringify(r.data || '')} | ${r.msg}`,
                                type: "error",
                                duration: 2000
                            });
                            f(r);
                        }
                }
            },
            r => {
                loadInstance && loadInstance.close();

                Message.error({
                    title: "请求",
                    message: `${r.config.url}->${r}`,
                    type: "error",
                    duration: 2000
                });
            }
        )

    })

};
export const addErrorHandler = (result, cb) => {
    errorHandler[result] = cb;
};

// 分页
export const page = (url, request) => {
    return handleReponse(url,
        REQ_INSTANCE.post(url, request || {
            page_no: -1,
            page_size: -1
        })
    );
};

// 分页
export const list = (url, request) => {
    return handleReponse(url,
        REQ_INSTANCE.post(url, request || {
            page_no: -1,
            page_size: -1
        })
    ).then((data) => {
        if (!data) {
            data = {}
        }
        if (Array.isArray(data)) {
            data = {list: data}
        }
        return data.list || [];
    });
};

export const one = (url, request) => {
    return list(url, request)
        .then((rs) => {
            return rs[0] || {};
        })
};


//
export const post = (url, request) => {
    return handleReponse(url, REQ_INSTANCE.post(url, request));
};
export const get = (url, request) => {
    return handleReponse(url, REQ_INSTANCE.get(url, {
        params: request || {}
    }));
};

export const download = (url, request) => {
    let query = "";
    if (request) {
        for (let k in request) {
            query += `&${k}=${encodeURIComponent(request[k])}`;
        }
    }
    if (url.indexOf("?") == -1) {
        url += "?";
    }
    window.open(API_HOST + url + query);
};

export const upload = (url, params) => {
    const token = window.localStorage.getItem('token')
    const instance = axios.create({
        timeout: 0,
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token
        }
    })

    const formData = new FormData()
    for (const key in params) {
        formData.append(key, params[key])
    }
    url = API_HOST + url
    return instance.post(url, formData).then((r) => {
        return r
    })
}
export const getSyncHost = () => {
    return host;
};


export const getHost = () => {
    return new Promise((s) => {
        s(host);
    });
};
