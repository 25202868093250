<template>
  <el-dialog
      v-dialogDrag :title="title" :visible.sync="isShow" width="80%"
      ref="dialog__wrapper" :modal-append-to-body="true" :append-to-body="true" :fullscreen="false"
      @open="openModePage()" @close="closeModePage()"
  >
    <div class="dialog-body">
      <common-page ref="comPage" :modal="true" @close="closeSelf" @dialogCallback="dialogCallback"></common-page>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      app_id: 0,
      page_d: 0,
      source: {},
      title: "弹出窗口"
    };
  },
  computed: {},
  props: {
    modal: {
      default: false
    }
  },

  methods: {

    dialogCallback(opt) {
      console.log("dialogCallback opt:", opt);
      if (opt && opt.act == 'closeSelf') {
        //关闭弹窗
        this.closeSelf();
      } else if (opt && opt.act == 'setParam') {
        //关闭弹窗
        this.$emit("callback", {act: 'setParam', opt: opt.opt});
        this.closeSelf();
      } else if (opt && opt.act == 'setParamAndQuery') {
        //关闭弹窗
        this.$emit("callback", {act: 'setParamAndQuery', opt: opt.opt});
        this.closeSelf();
      } else if (opt && opt.act == 'reloadParentView') {
        if (this.source && this.source.source_type == "view") {
          console.log("reloadParentView dialog by view:", this.source);
          this.$emit("callback", {act: 'reloadParentView', opt: {id: this.source.call_back_id}});
        } else if (this.source && this.source.source_type == "col") {
          console.log("reloadParentView dialog by col:", this.source);
          this.$emit("callback", {act: 'reloadParentView', opt: {id: this.source.call_back_id}});
        }
      } else {
        //this.$emit("callback", act, opt);
      }
    },

    openModePage() {
      console.log("openModePage2 ...");
    },
    closeModePage() {
      console.log("closeModePage2 ...");
    },
    closeSelf() {
      this.isShow = false;
    },
    show(app_id, page_id, opt, source) {
      this.title = "弹出窗口";
      if (!app_id || !page_id) {
        return;
      }

      this.isShow = true;
      this.app_id = app_id;
      this.page_id = page_id;
      this.source = source;

      console.log("page show:", app_id, page_id, opt);
      console.log("page show source:", JSON.stringify(source));


      this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        this.$refs.comPage.refresh(app_id, page_id, opt, (page) => {
          if (page && page.name) {
            this.title = page.name
          }
        });
      });
    },
  },
  components: {
//      ComponentPage
  },
  mounted() {
    console.log('page dialog mounted');
  }
};
</script>
<style lang="less">

</style>
