<template>
  <svg v-if="type == 'svg'" :style="styleObject" class="icon xxxxxxxx" aria-hidden="true">
    <use :xlink:href="icon2Class"/>
  </svg>
  <img v-else class="icon" :style="styleObject" :src="icon2Class" :alt="icon || name"/>
</template>
<script>

export default {
  name: 'Icon',
  props: {
    icon: {
      type: String
    },
    name: {
      type: String
    },
    type: {
      type: String,
      default: () => {
        // svg || image
        return 'svg'
      }
    },
    size: {
      type: String
    }
  },
  computed: {
    styleObject() {
      if (this.size) {
        return {
          width: this.size,
          height: this.size
        }
      }
      return {}
    },
    icon2Class() {
      const icon = this.icon || this.name || 'icon-file'
      if (this.type == 'svg') {
        if (!icon.includes('icon-')) {
          return `#icon-${this.icon}`
        }
        // icon- 开头
        return `#${icon}`
      }
      // 图片
      return '/static/icons/' + icon + '.png'
    }
  }
}
</script>

<style lang="less" scoped>
img.icon {
  width: 1em;
  height: 1em;
}

svg.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
