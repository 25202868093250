import {LIST_BUTTON, ADD_BUTTON, DEL_BUTTON} from '@/store/mutations-type'
import * as Api from "@/api";
import * as _ from "lodash";

const state = {
    list: []
}

// eslint-disable-next-line no-unused-vars
let f = (d, i) => {
    d.sort_num = i;
    d.config = JSON.stringify(d.config);
    if (d.call_back_config) {
        d.call_back_config = JSON.stringify(d.call_back_config);
    }
    return d;
};


let subExec = (d) => {

    console.log("subExec isMap:", _.isObject(d.config));

    if (d.config && _.isObject(d.config)) {
        d.config = JSON.stringify(d.config);
    }
    if (d.call_back_config && _.isObject(d.call_back_config)) {
        d.call_back_config = JSON.stringify(d.call_back_config);
    }
    return d;
}

let loadExec = (d) => {
    // console.dir(d);
    try {
        d.config = JSON.parse(d.config || "{}");
        d.call_back_config = JSON.parse(d.call_back_config || "{}");
    } catch (e) {
        console.error(e);
        d.config = {};
        d.call_back_config = {};
    }


    if (!d.config.check_type) {
        d.config.check_type = "1";
    }

    if (!d.config.confirm_msg) {
        d.config.confirm_msg = "是否确定要执行该操作?";
    }


    if (!d.call_back_config.refresh) {
        d.call_back_config.refresh = true;
    }

    return d;
}

const mutations = {
    [ADD_BUTTON](state, data) {
        if (!state.list) {
            state.list = [];
        }
        state.list.unshift(data);
    },
    [DEL_BUTTON](state, id) {
        if (state.list && state.list.length > 0) {
            for (let i = 0; i < state.list.length; i++) {
                let item = state.list[i];
                if (item.id === id) {
                    state.list.splice(i, 1);
                    break;
                }
            }
        }
    },
    [LIST_BUTTON](state, data) {
        state.list = data;

        console.log("LIST_BUTTON:", state.list.length);
    }
}


const actions = {
    async addButton({commit, rootState}, {page_id, source_id, source_type, call_back_id, call_back_type}) {
        let name = 'btn_' + new Date().getTime();
        var btn = {
            app_id: rootState.app.id,
            sort_num: 999,
            page_id: page_id,
            source_id: source_id,
            source_type: source_type,
            call_back_type: call_back_type,
            call_back_id: call_back_id,
            name: name,
            label: name,
            enable: 1,
            display: 'button',
            type: 1
        }

        let r = await Api.post(`/manager/buttons/add`, btn);
        r = loadExec(r);
        commit(ADD_BUTTON, r)
    },

    async delButton({commit, rootState}, id) {
        await Api.post(`/manager/buttons/del`, {app_id: rootState.app.id, id: id});
        commit(DEL_BUTTON, id)
    },


    async updButtons({commit, rootState, state}, {source_id, source_type}) {
        let data = [];

        for (let i = 0; i < state.list.length; i++) {
            let btn = state.list[i];
            btn = subExec(btn);
            data.push(btn);
        }
        await Api.post(`/manager/buttons/add/json`, {
            app_id: rootState.app.id,
            source_id: source_id,
            source_type: source_type,
            app_buttons: JSON.stringify(data)
        });

        let list = await Api.list(`/manager/buttons/list`, {
            app_id: rootState.app.id,
            source_id: source_id,
            source_type: source_type,
            page_no: -1,
            page_size: -1
        });

        if (list && list.length > 0) {
            for (var d of list) {
                d = loadExec(d);
            }
        }


        commit(LIST_BUTTON, list);

        return "ok";
    },

    async queryList({commit, rootState}, {source_id, source_type}) {

        let list = await Api.list(`/manager/buttons/list`, {
            app_id: rootState.app.id,
            source_id: source_id,
            source_type: source_type,
            page_no: -1,
            page_size: -1
        });

        if (list && list.length > 0) {
            for (var d of list) {
                d = loadExec(d);
            }
        }


        commit(LIST_BUTTON, list);

        return "ok";
    }
}

export default {
    state,
    mutations,
    actions
}
