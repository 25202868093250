<template>
  <el-dialog
      v-dialogDrag :title="title" :visible.sync="isShow" width="30%"
      ref="dialog__wrapper" :modal-append-to-body="true" :append-to-body="true" :fullscreen="false" center>
    <div class="dialog-body">
      <el-form :model="formData" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="formData.mark"></el-input>
        </el-form-item>

        <!--<el-form-item label="导出类型">-->
        <!--<el-select v-model="formData.taskType" placeholder="请选择导出类型">-->
        <!--<el-option label="csv" value="2"></el-option>-->
        <!--<el-option label="xls" value="5"></el-option>-->
        <!--</el-select>-->
        <!--</el-form-item>-->

        <el-form-item label="导出数目">
          <el-select v-model="formData.rows" placeholder="请选择导出数量">
            <el-option label="3" value="3"></el-option>
            <el-option label="1000" value="1000"></el-option>
            <el-option label="2000" value="2000"></el-option>
            <el-option label="5000" value="5000"></el-option>
            <el-option label="1万" value="10000"></el-option>
            <el-option label="2万" value="20000"></el-option>
            <el-option label="3万" value="30000"></el-option>
            <el-option label="5万" value="50000"></el-option>
            <el-option label="15万" value="150000"></el-option>
            <el-option label="30万" value="300000"></el-option>
            <el-option label="50万" value="500000"></el-option>
          </el-select>
        </el-form-item>

      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="success" @click="submit" plain>提交</el-button>
      <el-button @click="hide" type="info" plain>取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as Api from "@/api";

export default {
  data() {
    return {
      formData: {
        taskType: 5,
      },
      isShow: false,
      view_id: 0,
      app_id: 0,
      page_d: 0,
      params: {},
      sorts: {},
      source: {},
      title: "弹出窗口"
    };
  },
  computed: {},
  props: {
    modal: {
      default: false
    }
  },

  methods: {
    submit() {
      if (!this.formData.mark) {
        this.$message({
          message: '标题不能为空',
          type: 'warning'
        });
      }

      if (!this.formData.taskType) {
        this.$message({
          message: '导出类型不能为空',
          type: 'warning'
        });
      }

      if (!this.formData.rows) {
        this.$message({
          message: '导出数目不能为空',
          type: 'warning'
        });
      }

      //数据导出
      Api.post(`/app/export`, {
        title: this.formData.mark,
        app_id: this.app_id,
        button_id: this.source.id,
        has_total: 1,
        rows: this.formData.rows,
        export_type: this.formData.taskType,
        data_params: JSON.stringify(this.params),
        order_params: JSON.stringify(this.sorts)
      })
          .then(r => {

            if (r && r.success) {
              this.$message({
                message: '导出成功!',
                type: 'success'
              });
              this.isShow = false;
            } else {
              this.$message({
                message: r.msg,
                type: 'warning'
              });
            }
          })

    },

    hide() {
      this.isShow = false;
    },

    closeSelf() {
      this.isShow = false;
    },

    show(app_id, page_id, params, sorts, source) {
      if (!app_id || !page_id) {
        return;
      }

      this.isShow = true;
      this.app_id = app_id;
      this.page_id = page_id;
      this.source = source;
      this.params = params;
      this.sorts = sorts;
    },
  },
  components: {},
  mounted() {
    console.log('page dialog mounted');
  }
};
</script>
<style lang="less">

</style>
