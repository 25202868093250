import Vue from 'vue'
import BtnTip from './button/btn.tip'
import BtnTime from './button/btn.time'
import SearchTable from './table/search.table'
import SearchList from './table/search.list'
import SearchPop from './common/search.pop'
import CommonTimeText from './common/time.text'
import CommonUpload from './common/upload'
import CommonSelect from './common/select'
import CommonSearch from './common/search'
import CommonRegion from './common/region'
import CommonMultiUpload from './common/upload.multi'
import CommonTip from './common/tip'
import CommonTags from './common/tags'
import CommonLoading from './common/loading'
import Ranger from './utils/ranger'
import Region from './utils/region'
import BtnApi from './button/btn.api'
import CommonTitle from './common/title'
import CommonRefreshImage from './common/refresh.image'
import AppButton from './widget/common/button.vue'
import KIcon from './icon'
import KGridstack from './gridstack/GridStack'
import KGridstackSection from './gridstack/GridStackSection'
import KGridstackItem from './gridstack/GridStackItem'
import KUploadInput from './common/uploadInput'
import KFoldMenu from './button/fold.menu'

export const components = {
    SearchPop,
    CommonRefreshImage,
    CommonTitle,
    BtnApi,
    BtnTip,
    BtnTime,
    SearchTable,
    SearchList,
    CommonTimeText,
    CommonUpload,
    CommonLoading,
    Ranger,
    Region,
    CommonSelect,
    CommonSearch,
    CommonTags,
    CommonRegion,
    CommonMultiUpload,
    CommonTip,
    AppButton,
    KIcon,
    KGridstack,
    KGridstackSection,
    KGridstackItem,
    KUploadInput,
    KFoldMenu
}

for (const key in components) {
    Vue.component(key, components[key])
}