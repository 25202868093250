<template>

  <div class="box">
    <div class="build-widget">
      <component :is="toWidget()" ref="widget" :data="view" class="widget" :pro="true" :size="size"/>
    </div>
  </div>

</template>

<script>
import view_method from "@/api/view.method";
import WidgetDivider from "@/components/widget/divider";


export default {
  data() {
    return {};
  },
  props: {
    size: {default: 'medium'},
    view: {},
    modal: {default: false}
  },
  computed: {},
  methods: {
    ...view_method,
    refresh() {
    },
    toWidget() {
      return WidgetDivider
    },
  },
  mounted() {
    this.refresh();
  },
  components: {}
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
}

.build-widget {
  /*padding: 10px;*/
  margin: 0;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

/*.vue-grid-item {*/
/*height: auto  !important;*/
/*overflow: scroll;*/

/*}*/
</style>
