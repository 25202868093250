import * as Api from "@/api";
import store from '@/store'
import router from '@/router'
import {
    Message
} from "element-ui";

export default {
    action(appId, actionId, data) {
        return Api.post(`/app/action/run`, {
            action_id: actionId,
            app_id: appId,
            data_params: JSON.stringify(data || {})
        }).then((r) => {
            r.action = r.action || {};
            switch (r.action.act_type) {
                case 1:
                    this.pageAction(r.action, r.page, data)
                    return true;
                default:
                    Message.success(JSON.stringify(r.list[0]))
                    return true;
            }
        });
    },

    pageAction(action, page, data) {
        switch (action.act_type_code) {
            case 101:
                router.push(`/preview/${page.app_id}/page/${page.id}`)
                break;
            case 102:
                store.commit(`showActionDialogPage`, {
                    action,
                    page,
                    data
                })
                break;
            case 103:
                window.open(`#/preview/${page.app_id}/page/${page.id}`)
                break;
        }
    },

    dataAction() {
    },
}
