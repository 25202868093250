<template>
  <div v-loading="isLoading" element-loading-text="请求数据中...">
    <slot></slot>
  </div>
</template>
<script>
import * as Api from '@/api'

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {},
  props: {
    url: {},
    method: {
      default: 'get',
    },
    params: {
      default() {
        return null;
      }
    },
  },
  watch: {
    url() {
      this.refresh();
    }
  },
  methods: {
    refresh() {
      if (!this.url) {
        return;
      }
      this.isLoading = true;
      Api[this.method](this.url, this.params)
          .then((r) => {
            this.isLoading = false;
            this.$emit('data', r);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
  components: {},
  mounted() {
    this.refresh();
  },
}
</script>
