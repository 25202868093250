<template>

  <div class="box">
    <div class="build-widget">
      <component :is="toWidget()" ref="widget" :data="view" class="widget" :pro="true" :size="size"/>
    </div>
  </div>

</template>

<script>
import view_method from "@/api/view.method";
import WidgetVideo from "@/components/widget/video";


export default {
  data() {
    return {};
  },
  props: {
    size: {default: 'medium'},
    view: {},
    modal: {default: false}
  },
  computed: {},
  methods: {
    ...view_method,
    refresh() {

    },

    toWidget() {
      return WidgetVideo
    },


  },
  mounted() {
    this.refresh();
    this.$bus.on('execView-' + this.view.id, ({method, opt}) => {
      if (this[method]) {
        this[method](opt);
      }
    });
  },
  components: {}
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
}

.build-widget {
  /*padding: 10px;*/
  margin: 0;
  background-color: #E4E7ED;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

/*.vue-grid-item {*/
/*height: auto  !important;*/
/*overflow: scroll;*/

/*}*/
</style>
