import {LIST_ACTION, REMOVE_ACTION, ADD_ACTION, UPD_ACTION, SET_ACTION} from '@/store/mutations-type'
import * as Api from "@/api";


let f = (d, i) => {
    d.sort_num = i;
    d.config = JSON.stringify(d.config);
    return d;
};

let subExec = (view) => {
    if (view.app_cols && typeof view.app_cols != "string") {
        view.app_cols = JSON.stringify(view.app_cols.map(f));
    }

    if (view.app_params && typeof view.app_params != "string") {
        view.app_params = JSON.stringify(view.app_params.map(f));
    }

    if (view.app_data && typeof view.app_data != "string") {
        if (view.app_data.ext_config && typeof view.app_data.ext_config != "string") {
            view.app_data.ext_config = JSON.stringify(view.app_data.ext_config);
        }

        view.before_config = view.app_data.before_config;
        view.call_back_config = view.app_data.call_back_config;
        view.app_data = JSON.stringify(view.app_data);
    }


    if (view.config && typeof view.config != "string") {
        view.config = JSON.stringify(view.config);
    }

    if (view.layout && typeof view.layout != "string") {
        view.layout = JSON.stringify(view.layout);
    }

    return view;
}


let loadExec = (r) => {
    if (!r.app_cols) {
        r.app_cols = [];
    }
    r.app_cols = r.app_cols.map(col => {
        col.config = JSON.parse(col.config || "{}");
        return col;
    });

    if (!r.app_params) {
        r.app_params = [];
    }
    r.app_params = r.app_params.map(param => {
        param.config = JSON.parse(param.config || "{}");
        return param;
    });

    if (r.config) {
        r.config = JSON.parse(r.config);
    } else {
        r.config = {};
    }

    if (r.app_data) {
        r.app_data.ext_config = JSON.parse(r.app_data.ext_config || "{}");
    }

    r.config = Object.assign(
        {
            tools: [],
            auto: false,
            page: true,
            download: false
        },
        r.config
    );


    if (r.layout) {
        r.layout = JSON.parse(r.layout);
    } else {
        r.layout = {};
    }

    r.layout = Object.assign(
        {"x": 0, "y": 0, "w": 24, "h": 3, "i": '' + r.id, n: r.id_name},
        r.layout
    );

    console.log("loadExec:", JSON.stringify(r.config));

    return r;
}

const state = {
    action: {},
    list: [],
}


const mutations = {
    [SET_ACTION](state, data) {
        //console.log("SET_ACTION:", JSON.stringify(data));
        state.action = data
    },
    [LIST_ACTION](state, data) {
        state.list = data
    },
    [REMOVE_ACTION](state, id) {
        console.log("REMOVE_ACTION COL count:" + state.list.length)
        if (state.list && state.list.length > 0) {
            for (let i = 0; i < state.list.length; i++) {
                let item = state.list[i];
                if (item.id === id) {
                    state.list.splice(i, 1);
                    break;
                }
            }
        }
        console.log("REMOVE_COL COL count:" + state.list.length)
    },
    [ADD_ACTION](state, col) {
        console.log("ADD_COL COL count:" + state.list.length)
        if (!state.list) {
            state.list = [];
        }
        state.list.push(col)
        console.log("ADD_COL COL count:" + state.list.length)
    },

    [UPD_ACTION](state, item) {
        console.log("UPD_ACTION COL count:" + state.list.length)
        if (state.list && state.list.length > 0) {
            for (let i = 0; i < state.list.length; i++) {
                let com = state.list[i];
                if (com.id === item.id) {
                    state.list[i] = item;
                    break;
                }
            }
        }
        console.log("UPD_ACTION COL count:" + state.view.app_cols.length)
    },
}


const actions = {

    async getAction({commit, rootState}, id) {
        let r = await Api.post(`/manager/action/get`, {app_id: rootState.app.id, id: id});
        r = loadExec(r);

        commit(SET_ACTION, r)
    },

    async delAction({commit, rootState}, id) {
        await Api.post(`/manager/action/del`, {app_id: rootState.app.id, id: id});

        commit(REMOVE_ACTION, id)
    },

    async addAction({commit}, action) {
        action = subExec(action);
        let r = await Api.post(`/manager/action/add`, action);
        commit(SET_ACTION, r)
        return "ok";
    },

    async updAction({commit}, action) {
        action = subExec(action);
        let r = await Api.post(`/manager/action/upd`, action);
        commit(SET_ACTION, r)
        return "ok";
    }
}

export default {
    state,
    mutations,
    actions
}
