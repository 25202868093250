<!--
  文件描述：uploadInput
  创建时间：2023/9/1 15:08
  创建人：zangyan
-->
<template>
  <el-button :type="type" size="mini" @click="upload">{{ text }}</el-button>
</template>

<script>
export default {
  name: 'k-upload-input',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: '上传'
    },
    accept: {
      type: String,
      default: ''
    },
    apiUrl: {
      type: String,
      default: ''
    },
    cb: {
      type: Function,
      default: () => (() => {
      })
    },
    appId: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {
      inputEl: null
    }
  },
  methods: {
    upload() {
      this.inputEl.click()
    },
    createInput() {
      const inputEl = document.createElement('input')
      inputEl.type = 'file'
      inputEl.accept = this.accept
      let upload = false
      const handleImageInputChange = () => {
        if (upload) {
          return
        }
        upload = true
        const file = inputEl.files[0];
        if (file) {
          this.$api.upload(this.apiUrl, {app_id: this.appId, f: file}).then(({data}) => {
            this.cb(data)
            this.createInput()
          })
        }
      }
      inputEl.addEventListener('change', handleImageInputChange);
      this.inputEl = inputEl
    }
  },
  mounted() {
    this.createInput()
  }
}
</script>

<style scoped lang="less">

</style>