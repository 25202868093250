const CHINA_REGION = window.CHINA_REGION
const allNode = {};
const provinceNodes = {};
const cityNodes = {};
const countyNodes = {};

class Node {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        this.children = [];
    }

    addSub(node) {
        this.children.push(node);
    }
}

//省
for (let id in CHINA_REGION) {
    let v = CHINA_REGION[id];
    if (v[1] == '086') {
        let node = new Node(id, v[0]);
        provinceNodes[id] = node;
        allNode[id] = node;
        delete CHINA_REGION[id];
    }
}

//市区
for (let id in CHINA_REGION) {
    let v = CHINA_REGION[id];
    if (provinceNodes[v[1]]) {
        let node = new Node(id, v[0]);
        provinceNodes[v[1]].addSub(node);
        cityNodes[id] = node;
        allNode[id] = node;
        delete CHINA_REGION[id];
    }
}

//区县
for (let id in CHINA_REGION) {
    let v = CHINA_REGION[id];
    if (cityNodes[v[1]]) {
        let node = new Node(id, v[0]);
        cityNodes[v[1]].addSub(node);
        countyNodes[id] = node;
        allNode[id] = node;
        delete CHINA_REGION[id];
    }
}

//
export default {
    get(id) {
        return allNode[id];
    },
    getProvinces() {
        return provinceNodes;
    },
    getCitys(provinceId) {
        return provinceNodes[provinceId].children;
    },
    getCountys(cityId) {
        return cityNodes[cityId].children;
    }
}
