<template>
  <div class="build-widget">
    <widget-search-form ref="search" :size="size" :view="view" @search="onFormSearch" :pro="true"
                        :params="view.app_params"/>
  </div>
</template>

<script>
import "@dattn/dnd-grid/dist/dnd-grid.css";
import WidgetSearchForm from "@/components/widget/form/search.form";

import view_method from "@/api/view.method";

export default {
  data() {
    return {};
  },
  props: {
    size: {default: 'medium'},
    view: {},
    modal: {default: false}
  },
  computed: {},
  methods: {
    ...view_method,


    onFormSearch(searchData) {
      this.$emit("search", searchData)
    }
  },
  mounted() {
    this.$bus.on('execView-' + this.view.id, ({method, opt}) => {
      if (this[method]) {
        this[method](opt);
      }
    });
    //this.refresh();
  },
  components: {
    WidgetSearchForm,
  }
};
</script>

<style lang="less">


</style>
