<template>

  <div class="box">
    <div class="build-widget" v-if="view.id==view.query_id" v-show="!view.is_hide_form">
      <widget-search-form ref="search" :view="view" @search="onFormSearch" :pro="true" :params="view.app_params"
                          :size="size" v-if="view.id==view.query_id"/>
    </div>
    <div class="build-widget">
      <component :is="toWidget(view)" ref="widget" :data="view" class="widget" :pro="true" :size="size"/>
    </div>
  </div>

</template>

<script>
import view_method from "@/api/view.method";
import "@dattn/dnd-grid/dist/dnd-grid.css";
import WidgetChart from "@/components/widget/chart/index.vue";
import WidgetSearchForm from "@/components/widget/form/search.form";


export default {
  data() {
    return {};
  },
  props: {
    size: {default: 'medium'},
    view: {},
    modal: {default: false}
  },
  computed: {},
  methods: {
    ...view_method,


    refresh() {

    },

    toWidget() {
      return WidgetChart;
    },


  },
  mounted() {
    this.refresh();
    this.$bus.on('execView-' + this.view.id, ({method, opt}) => {
      if (this[method]) {
        this[method](opt);
      }
    });
  },
  components: {
    WidgetSearchForm,
    WidgetChart,
  }
};
</script>

<style lang="less">

.table-box {
  padding: 0px;
  margin: 0px;
}

.build-widget {
  /*padding: 10px;*/
  margin: 10px 5px;
  background-color: #f0f2f5;
}

/*.vue-grid-item {*/
/*height: auto  !important;*/
/*overflow: scroll;*/

/*}*/
</style>
