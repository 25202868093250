<template>
  <el-row :gutter="0">
    <el-col :span="8">
      <el-select placeholder="选择省" v-model="province">
        <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.name"></el-option>
      </el-select>
    </el-col>
    <el-col :span="7" style="margin-left:4px;">
      <el-select placeholder="选择市" v-model="city">
        <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.name"></el-option>
      </el-select>
    </el-col>
    <el-col :span="7" style="margin-left:4px;">
      <el-select placeholder="选择区县" v-model="county">
        <el-option v-for="item in countyList" :key="item.id" :label="item.name" :value="item.name"></el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import Region from '../utils/region';

export default {
  data() {
    return {
      provinceList: Region.getProvinces(),
      cityList: [],
      countyList: [],
      isInit: false,
      province: '',
      city: '',
      county: '',
    };
  },
  props: {
    model: {},
  },
  watch: {
    model(nv) {
      this.province = nv.province;
      this.city = nv.city;
      this.county = nv.county;
      this.isInit = true;
    },
    province(nv) {
      this.cityList = nv ? Region.getCitys(nv) : [];
      if (!this.isInit) {
        this.city = '';
        this.county = '';
        this.change(nv);
      }
    },
    city(nv) {
      this.countyList = nv ? Region.getCountys(nv) : [];
      if (!this.isInit) {
        this.county = '';
        this.change(nv);
      }
    },
    county(nv) {
      this.change(nv);
      this.isInit = false;
    },
  },
  methods: {
    //
    change() {
      let {province, city, county} = this;
      this.model.province = province;
      this.model.city = city;
      this.model.county = county;
      console.log(this.model);
    },
  },
};
</script>


