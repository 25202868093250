<template>
  <div class="grid-stack-item" :gs-id="id" :gs-x="x" :gs-y="y" :gs-w="w" :gs-h="h" :gs-min-w="minW" :gs-min-h="minH"
       :gs-max-w="maxW" :gs-max-h="maxH" :gs-no-resize="resize" :gs-no-move="move" :gs-locked="locked"
       ref="gridStackItem">
    <div class="grid-stack-item-content">
      <!-- :font-size="styleConfig.text_size" :style="viewStyle"-->
      <div style="height: 100%;width: 100%;overflow: hidden;background-color: #fff">
        <div style="height: 100%;width: 100%;">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {dataConfigMap, findDataId, fristDataId} from "@/utils/tool";
import {setting} from '@/components/widget/viewStyle/index'
import _ from "lodash";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    w: {
      type: Number,
      required: true
    },
    h: {
      type: Number,
      required: true
    },
    minW: {
      type: Number,
      default: 0
    },
    minH: {
      type: Number,
      default: 0
    },
    maxW: {
      type: Number,
      default: 0
    },
    maxH: {
      type: Number,
      default: 0
    },
    resize: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    move: {
      type: Boolean,
      default: false
    },
    resizeHandles: {
      type: String,
      default: null
    },
    view: {
      type: Object,
      default: () => ({})
    }
  },
  inject: ['eventBus', 'layout', 'sublayout'],
  data() {
    return {
      styleConfig: {...setting}
    }
  },
  methods: {
    downElement(e) {
      console.log(e, 'mouseup')
    }
  },
  computed: {
    viewStyle() {
      let style = ''
      if (this.styleConfig) {
        style += `font-size:${this.styleConfig['text_size']}px;
        font-weight:${this.styleConfig['text_weight']};
        border:${this.styleConfig['border_width']}px ${this.styleConfig['border_type']} ${this.styleConfig['border_color']};
        border-radius:${this.styleConfig['border_radius']}px;
        padding:${this.styleConfig['padding_top']}px ${this.styleConfig['padding_right']}px ${this.styleConfig['padding_bottom']}px ${this.styleConfig['padding_left']}px;`
      }
      return style
    }
  },
  mounted() {
    const view = dataConfigMap(_.cloneDeep(this.view), 'setting', setting)
    this.styleConfig = {...setting, ...view.setting}
    if (this.sublayout) {
      this.sublayout.grid.makeWidget(this.$el)
    } else if (this.layout.grid) {
      this.layout.grid.makeWidget(this.$el)
    }
    this.$nextTick(() => {
      this.$refs.gridStackItem.addEventListener('dragover', (e) => {
        // console.log('dragover', e)
        e.preventDefault();
        const layoutViews = document.querySelectorAll('.d-layout-view')
        layoutViews.forEach(e => {
          e.style.background = '#eee'
        })
        const item = e.target
        const classNames = item.className || '';
        if (classNames.indexOf('d-layout-view') !== -1) {
          item.style.background = 'rgba(45, 163, 251, 0.3)'
        } else if (classNames === 'grid-stack-item-content' || classNames.indexOf('grid-stack-item') !== -1) {
          e.pid = 0
          e.lastPid = fristDataId(e) || 0
        } else if (classNames.indexOf('el-col') !== -1) {
          e.pid = 0
          e.lastPid = findDataId(e) || 0
        }
        this.$store.commit('upDragMapTargetEl', event)
      })
      this.$refs.gridStackItem.addEventListener('drag', (event) => {
        const id = findDataId(event) || this.id
        const pid = findDataId(event, 'data-pid') || 0
        this.$store.commit('upDragMapDragEl', {...event, id, pid})
        event.preventDefault();
      })
      this.$refs.gridStackItem.addEventListener('dragend', (event) => {
        event.preventDefault();
        this.$bus.emit('gridStackDragend', event)
      })
    })
  },
  beforeDestroy() {
    console.log('removing: ', this.$el)
    if (this.sublayout) {
      this.sublayout.grid.removeWidget(this.$el, false, false)
    } else {
      this.layout.grid.removeWidget(this.$el, false, false)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
