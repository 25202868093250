<template>
  <el-button :show-loading="isLoadding" action-type="button" :disabled="isLoadding" :type="type"
             @click.native="onClick">
    <slot></slot>
  </el-button>
</template>
<script>

export default {
  data() {
    return {
      isLoadding: false,
    }
  },
  computed: {},
  props: {
    type: {
      default: 'primary'
    },
    click: {}
  },
  watch: {},
  methods: {
    onClick() {
      if (!this.click) {
        return;
      }
      this.isLoadding = true;
      let r = this.click();
      if (r.then) {
        r.finally(() => this.isLoadding = false);
      } else {
        this.isLoadding = false;
      }
    }
  },
  components: {},
  mounted() {
  },
}
</script>
