<template>
  <el-select size='small' v-model='model' :class="{'select-open': isOpen}" :placeholder='placeholder'
             @visible-change='onOpen'
             clearable @change='onChange'>
    <el-option v-for='item in items' :key='item[valueName]' :label='item[labelName]'
               :value='item[valueName]'></el-option>
    <slot></slot>
  </el-select>
</template>

<script>
import * as Api from '@/api';
import Ranger from '../utils/ranger';

export default {
  data() {
    return {
      isOpen: false,
      model: this.value,
      items: [],
      debounceDelay: 500, // 防抖延迟时间，单位毫秒
      debounceTimeout: null
    };
  },
  watch: {
    url() {
      this.refresh();
    },
    value(nv) {
      console.log('value change  value:', nv, this.value);
      this.model = nv;
    }
  },
  props: {
    value: {
      default: ''
    },
    placeholder: {},
    url: {},
    dataList: {},
    dataType: {},
    params: {},
    labelName: {
      default: 'name'
    },
    valueName: {
      default: 'id'
    }
  },
  methods: {
    addData(data) {
      this.items.unshift(...data)
    },
    //
    refresh() {
      clearTimeout(this.debounceTimeout);
      // 设置新的定时器
      this.debounceTimeout = setTimeout(() => {
        if (this.url) {
          this.$nextTick(() => {
            Api.list(this.url, {...this.params}).then(r => {
              this.items = r;
              if (this.items) {
                let flag = false;
                for (var item of this.items) {
                  if (item[this.valueName] == this.value) {
                    flag = true;
                  }
                }

                if (flag) {
                  this.model = this.value;
                }
                this.$emit('getData')
                console.log('flag:', flag, '  model:', this.model, 'value:' + this.value);
              }
            });
          })
        } else {
          this.items = [];
          this.model = '';
          this.$emit('input', '');
        }
      }, this.debounceDelay);

    },

    //
    onOpen(open) {
      this.isOpen = open;
    },
    onChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    }
  },
  mounted() {
    // 固定数据
    if (this.dataType) {
      switch (this.dataType) {
        case 'month':
          this.items = Ranger.month();
          break;
        case 'year':
          this.items = Ranger.year();
          break;
      }
    } else if (this.url) {
      // 远程数据
      this.refresh();
    }
  }
};
</script>
