<template>

  <div class="box">
    <div class="build-widget">
      <widget-custom :view="view" ref="search"/>
    </div>
  </div>


</template>

<script>
import WidgetCustom from "@/components/widget/custom/index";
// import WidgetSearchForm from "@/components/widget/form/search.form";

import view_method from "@/api/view.method";

export default {
  data() {
    return {};
  },
  props: {
    size: {default: 'medium'},
    view: {},
  },
  watch: {},
  computed: {},
  methods: {
    ...view_method,

  },
  mounted() {
    this.$bus.on('execView-' + this.view.id, ({method, opt}) => {
      if (this[method]) {
        this[method](opt);
      }
    });
  },
  components: {
    WidgetCustom,
    // WidgetSearchForm
  }
};
</script>

<style lang="less">

</style>
