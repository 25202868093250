<template>
  <div class="view-widget view-image" :style="{padding:(view.config.padding || 0) + 'px'}" v-if="init">
    <video-player ref="videoPlayer" :options="videoOptions">
    </video-player>
    <div class="clearfix"></div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {API_HOST} from '@/api'
import 'video.js/dist/video-js.css'; // 导入样式
import 'vue-video-player/src/custom-theme.css'; // 导入自定义主题样式
import {videoPlayer} from 'vue-video-player';

export default {
  data() {
    return {
      loading: false,
      init: false,
      view: {
        app_cols: [],
        app_buttons: [],
        content: '',
        config: {
          alignment: 'left',
          preview: false,
          padding: 0,
          fit: 'scale-down'
        }
      },
      videoOptions: {
        autoplay: false,   // 是否自动播放视频
        controls: true,    // 是否显示播放器控制条
        muted: false,      // 是否静音
        preload: 'auto',   // 预加载设置 ('auto', 'metadata', 'none')
        language: 'zh-CN', // 播放器语言
        fluid: true,       // 是否启用响应式布局
        playbackRates: [0.5, 1, 1.5, 2], // 可选的播放速率
        loop: false,
        sources: [{
          type: 'video/mp4',
          src: ''
        }]
      }
    };
  },
  computed: {
    ...mapState(["menuMap"])
  },
  props: {
    size: {default: 'medium'},
    pro: {default: false},
    data: {}
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.refresh(this.data);
      })
    }
  },
  methods: {
    refresh(r) {
      this.init = false;
      const querys = process.env.NODE_ENV === 'production' ? '' : '?access_token=' + window.localStorage.getItem("token")
      this.view.content = (API_HOST || window.location.origin) + r.content + querys
      if (r.config && typeof r.config === 'string') {
        try {
          this.view.config = JSON.parse(r.config)
        } catch (e) {
          console.log(e)
        }
      } else if (r.config && typeof r.config === 'object') {
        this.view.config = Object.assign(this.view.config, r.config)
      }
      this.videoOptions.sources[0].src = this.view.content
      const len = r.content.split('.').length
      const type = r.content.split('.')[len - 1]
      this.videoOptions.sources[0].type = 'video/' + type
      this.$nextTick(() => {
        this.init = true;
      })

    }
  },
  components: {videoPlayer},
  mounted() {
    this.$nextTick(() => {
      this.refresh(this.data);
    })

  }
};
</script>

<style lang="less" scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.video-player {
  height: 100%;

  ::v-deep .video-js {
    height: 100%;
    padding-top: 0 !important;
  }
}
</style>
