<template>
  <div>
    <el-upload action="//qdlfz.oss-cn-beijing.aliyuncs.com" :show-file-list="false" :data="uploadData"
               :on-error="onError" :on-success="onSuccess" :before-upload="onBeforeUpload">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传图片、文档、压缩包</div>
    </el-upload>

    <div v-for="(file,index) in fileList" :key="index">
      <el-button type="text" @click="onOpen(file)">{{ file.name }}</el-button>
    </div>
  </div>
</template>

<script>
import * as Api from '@/api'
import * as _ from 'lodash'
import {Loading} from 'element-ui';
import Moment from 'moment'

export default {
  data() {
    return {
      fileList: [],
      uploadData: {
        key: '',
        token: '',
      }
    }
  },
  watch: {
    value(nv) {
      this.fileList = nv.map((name) => {
        return {name};
      });
    }
  },
  props: {
    value: {}
  },
  methods: {
    refresh() {
      Api
          .get(`/upload`)
          .then((r) => {
            this.uploadData = r;
            this.uploadData.OssaccessKeyId = r.ossaccessKeyId;
          })
    },

    //
    onOpen(file) {
      window.open(`//qdlfz.oss-cn-beijing.aliyuncs.com/${file.name}`)
    },
    onBeforeUpload(file) {
      this.loading = Loading.service({fullscreen: true});
      file.key = Moment().format('YYYYMMDD') + '_' + file.name;
      this.uploadData.key = file.key;
      return true;
    },
    onSuccess() {
      let u = _.cloneDeep(this.uploadData);
      this.fileList = this.fileList || [];
      this.fileList.push({name: u.key});
      this.$emit('input', this.fileList.map((item) => {
        return item.name;
      }));
      this.onEnd();
    },
    onError(evt) {
      console.log('上传输错 => ' + evt)
      this.onEnd();
    },
    onEnd() {
      if (this.loading) {
        this.loading.close();
      }
      this.refresh();
    }
  },
  mounted() {
    this.refresh()
  }
}
</script>




