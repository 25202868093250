// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
require('@/assets/css.less');
// import '@/styles/index.scss' // global css
import 'es6-promise/auto';
import Vue from 'vue';
import i18n from './i18n';
import './assets/tailwind.css';
import VueClipboard from 'vue-clipboard2'
import ElementUI, {
    Message
} from 'element-ui';
// import {
//   Notification,
//   MessageBox
// } from 'element-ui';
//
// import dark from '@/components/widget/chart/theme/dark.js';
// import vintage from '@/components/widget/chart/theme/vintage.js';
// import macarons from '@/components/widget/chart/theme/macarons.js';
// import roma from '@/components/widget/chart/theme/roma.js';
// import shine from '@/components/widget/chart/theme/shine.js';
// import infographic from '@/components/widget/chart/theme/infographic.js';


import 'element-ui/lib/theme-chalk/index.css';
import App from './App';
import router from './router';
import store from './store';
import moment from 'moment';
import '@/components/index';
import Util from './util';
import VueGridLayout from 'vue-grid-layout';
import VueCodeMirror from 'vue-codemirror-lite';
import CommonPage from './build/page';
import VueWaterfallEasy from 'vue-waterfall-easy';
import './lib/directives.js';
import * as Api from '@/api'
import VueBus from 'vue-bus'
import {hexToRgb} from './utils/tool'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

console.log('APP mode：' + process.env.VUE_APP_ENV);

document.documentElement.style.setProperty('--primary-color', '#0369a1')
document.documentElement.style.setProperty('--primary-color-rgb', hexToRgb('#0369a1'))
// ba2636 0369a1
document.documentElement.style.setProperty('--app-primary-color', '#0369a1')
document.documentElement.style.setProperty('--app-primary-color-rgb', hexToRgb('#0369a1'))
let app = sessionStorage.getItem('app')
if (app) {
    try {
        app = JSON.parse(app || '{}')
        if (app.color) {
            document.documentElement.style.setProperty('--app-primary-color', app.color)
            document.documentElement.style.setProperty('--app-primary-color-rgb', hexToRgb(app.color))
        }
        store.commit('app', app);
    } catch (e) {
        console.log(e)
    }
}
global.isDev = process.env['NODE_ENV'] == 'development';

const version = '1.0';
document.title = document.title + ' V' + version;

//
let user = window.localStorage.getItem('user');
if (user) {
    store.commit('user', JSON.parse(user));
}
//
Vue.use(ElementUI);
Vue.use(VueGridLayout);
Vue.use(VueBus);
Vue.use(VueClipboard)
Vue.use(VXETable)
Vue.prototype.compile = Vue.compile;
Vue.prototype.$util = Util;
Vue.prototype.$api = Api;
Vue.prototype.onSuccess = (message, title) => {
    Message.success({
        title,
        message
    });
};
Vue.prototype.onError = (message, title) => {
    Message.error({
        title,
        message
    });
};
Vue.prototype.isRole = role => {
    return window.localStorage.getItem('role') == role;
};
Vue.prototype.getRole = () => {
    return window.localStorage.getItem('role');
};
Vue.filter('date', function (value) {
    return moment(value).format('YYYY-MM-DD');
});
Vue.filter('dateTime', function (value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
});
Vue.filter('kb', function (v, t) {
    if (!v) {
        return ''
    }
    switch (t) {
        case 'm':
            return Math.ceil(v / 1024 / 1024) + 'M'
        default:
            return Math.ceil(v / 1024) + 'KB'
    }
})

Vue.component('codemirror', VueCodeMirror.codemirror);
Vue.component('CommonPage', CommonPage);
Vue.component('VueWaterfallEasy', VueWaterfallEasy);


//
Vue.config.productionTip = global.isDev;
// 解决v-charts 报错 Cannot read properties of undefined (reading 'map')
Vue._watchers = Vue.prototype._watchers = []
//
new Vue({
    el: '#app',
    i18n,
    store,
    router,
    template: '<App/>',
    components: {
        App
    },
    created() {
        Api.get(`/setting`, {}).then(r => {
            this.$store.commit('setSetting', r)
        })
        window.addEventListener('message', (event) => {
            if (event.data === 'show-app-login-dialog') {
                if (window.self !== window.top) {
                    window.parent.postMessage('show-app-login-dialog', '*')
                } else {
                    Vue.bus.emit('show-app-login-dialog')
                }
            }
        }, false);
    }
});
