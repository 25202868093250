<template>
  <div class="search-pop">
    <el-popover :popper-class="popClass" ref="searchPopover" trigger="manual" v-model="show" placement="bottom-start"
                :width="popWidth">
      <slot :data="data"></slot>
    </el-popover>
    <el-input v-model="keyword" v-popover:searchPopover size="small" :placeholder="placeholder"
              prefix-icon="el-icon-search"></el-input>
  </div>
</template>
<script>
import * as Api from "@/api";
import * as _ from "lodash";

export default {
  data() {
    return {
      show: false,
      data: {},
      keyword: ""
    };
  },
  computed: {},
  props: {
    popClass: {
      default: ""
    },
    popWidth: {},
    paramName: {
      default: "keyword"
    },
    search: {},
    url: {},
    placeholder: {}
  },
  watch: {
    keyword: _.debounce(function (v) {
      if (!v) {
        this.data = {};
        return;
      }
      this.onChange(v);
    }, 300)
  },
  methods: {
    //
    onClose() {
      this.show = false;
      this.keyword = "";
    },
    onChange(v) {
      Api.post(this.url, {
        [this.paramName]: v,
        ...this.search
      }).then(r => {
        this.data = r;
        this.show = true;
      });
    }
  },
  components: {},
  mounted() {
  }
};
</script>
<style lang="less">
.search-pop {
  display: inline-block;
}
</style>
