<template>
  <div class="view-widget view-custom" v-bind:style="{ height: height }" :v-loading="loading">
    <template v-if="view&&view.content">
      <dynamic :template="view.content" :page="otherData" :v="data" :p="params" :app_id="view.app_id"></dynamic>
    </template>
  </div>
</template>

<script>
import * as Api from "@/api";
import reg from "@/api/register";
import * as _ from "lodash";
import moment from "moment";
import Dynamic from "../common/dynamic";
import {mapState} from "vuex";


export default {
  data() {
//      let params = this.$route.params;
    return {
      height: '100%',
      params: {},
      colMap: {},
      content: this.$parent.$parent.$parent.content,
      loading: false,
      pageSize: 20,
      data: {},
      otherData: {}
    };
  },
  computed: {
    ...mapState(['allViewData']),
    cols() {
      return this.view.app_cols;
    },
  },
  props: {
    size: {default: 'medium'},
    pro: {default: false},
    view: {},
    dev: {default: false},
  },
  watch: {
    allViewData: {
      immediate: true,
      deep: true,
      handler(v) {
        this.otherData = {...v}
      }
    }
  },
  filters: {
    time(v, format) {
      return moment(v).format(format || "YYYY-MM-DD hh:mm:ss");
    }
  },
  methods: {
    ...reg,
    refresh(r) {
      r = r || this.view;
      r.config = _.isString(r.config)
          ? JSON.parse(r.config || "{}")
          : r.config;

      if (r.app_cols) {
        r.app_cols = r.app_cols.map(col => {
          col.config = _.isString(col.config)
              ? JSON.parse(col.config || "{}")
              : col.config;

          return this.warpCol(col);
        });

        this.colMap = _.keyBy(r.app_cols, "name");
      }


      if (r.height && r.height > 0) {
        this.height = r.height + "px";
      }

      //console.log("config:", JSON.stringify(r.config));

      if (r.config && r.config.page_size) {
        let pageSize = ~~r.config.page_size;

        if (pageSize < 0) {
          pageSize = 1;
        } else if (pageSize > 500) {
          pageSize = 500;
        }

        this.pageSize = pageSize;
      }

      if (r.style) {
        this.loadCss(r.style);
      }
      this.reLoad()
    },

    search(searchData) {
      this.searchData = searchData || this.searchData;
      this.pageIndex = 0;
      this.load(this.searchData);
    },

    reLoad() {
      console.log("table reLoad:", JSON.stringify(this.params));
      this.load(this.params);
    },
    query(params) {
      this.load(params);
    },

    // 最终调用加载
    load(params) {
      console.log("search load:", JSON.stringify(this.searchData));

      params = params || {};
      this.params = params;

      params = this.execBefore(this.view, params);


      //可能没有数据源,纯静态的
      if (this.view && this.view.data_id) {
        this.loading = true;
        Api.page(`/app/data/run`, {
          app_id: this.view.app_id,
          data_id: this.view.data_id,
          form_id: this.view.query_id,
          has_total: 0,
          data_params: JSON.stringify(params),
          page_no: 0,
          page_size: this.pageSize,
          order_params: ""
        })
            .then(r => {
              if (r && r.success && r.response && r.response.ok && r.response.data) {
                try {
                  this.execCallback2(this.view, r.response.data, params, (response) => {
                    if (Array.isArray(response)) {
                      this.data = response.map(this.wrapData);
                      this.total = Array.isArray(this.data) ? this.data.length : 1;
                    } else if (typeof response === 'object') {
                      this.data = this.wrapData(response);
                      this.total = 1;
                    } else {
                      this.data = response;
                    }
                  })
                } catch (e) {
                  console.log(e)
                }
              } else {
                this.showFail(r.msg)
              }
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },


    wrapData(data) {
      for (let key in data) {
        let col = this.colMap[key];
        if (!col) {
          continue;
        }
        if (col.transFun) {
          data[key] = col.transFun(this.getRoot(), data);
        }
      }
      return data;
    },
    warpCol(col) {

      try {
        if (col.trans) {
          col.transFun = new Function("_self", "v", col.trans);
        }

      } catch (e) {
        console.error(`报错了 => `, col, e);
      }
      return col;
    },
    //

  },
  components: {
    Dynamic
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh(this.view);
      this.$bus.on('onFormSearch-' + this.view.id, this.search)
    })

  },
  beforeDestroy() {
    this.$bus.off('onFormSearch-' + this.view.id, this.search)
  }
};
</script>
<style lang="less">
.view-custom {
  height: 100%;
  width: 100%;
}
</style>
