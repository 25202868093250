<template>
  <span>
    {{ timeText }}
  </span>
</template>

<script>
import Moment from 'moment'

export default {
  data() {
    return {}
  },
  props: {
    format: {
      default: 'YYYY-MM-DD HH:mm:ss',
    },
    value: {},
  },
  computed: {
    timeText() {
      if (this.value) {
        return Moment(this.value).format(this.format);
      }
      return ''
    }
  },
}
</script>

