<template>
  <div>
    <el-row class="common-title shadow">
      <el-col :span="16" class="title">
        <router-link :to="to" style="color: #4298ff" exact>
          {{ title }}
        </router-link>
        <!-- <span v-for="path in paths" :key="path.id">
          <img src="/static/images/icon_right.png" alt="" class="right">
          <router-link :to="to + '/' + path.id" style="color: #4298ff">{{path.name}}</router-link>
        </span> -->
        <span v-if="item">
          <img src="/static/images/icon_right.png" alt="" class="right">
          <span>{{ item.name || item }}</span>
        </span>
      </el-col>
      <el-col :span="8">
        <slot/>
      </el-col>
    </el-row>
    <div style="clear:both"></div>
  </div>
</template>
<script>

export default {
  data() {
    return {};
  },
  computed: {},
  props: {
    to: {
      default: "/"
    },
    title: {},
    paths: {
      default() {
        return [];
      }
    },
    item: {}
  },
  watch: {},
  methods: {},
  components: {},
  mounted() {
  }
};
</script>
<style lang="less">
.common-title {
  padding: 20px 0;
  padding-left: 60px;
  padding-right: 20px;
  background-color: #fff;
  line-height: 32px;
  margin-bottom: 20px;

  .title {
    padding: 0 20px;
    border-left: 4px solid #0084ff;
  }

  .right {
    margin: 0 10px;
    position: relative;
    top: 2px;
  }

  .router-link-active {
    background-color: inherit !important;
  }
}
</style>
